import { useEffect, FunctionComponent, useState } from "react";
import ReactDOM from "react-dom";

import styles from "../Calendar.module.scss";

export interface ContextMenuConfig {
  options: {
    label: string;
    action: () => void;
    disabled?: boolean;
    color?: string;
  }[];
  location: {
    x: number;
    y: number;
  };
  direction?: "down" | "up";
}

interface ContextMenuProps extends ContextMenuConfig {
  onClose: () => void;
}

const ContextMenu: FunctionComponent<ContextMenuProps> = ({
  options,
  location,
  direction = "down",
  onClose,
}) => {
  useEffect(() => {
    const handleClickOutside = () => onClose();
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [onClose]);

  const { x, y } = location;

  return ReactDOM.createPortal(
    <div
      style={{
        top: direction === "down" ? y : y - options.length * 35,
        left: x,
      }}
      className={styles["context-menu"]}
    >
      {options.map((option, index) => (
        <button
          key={index}
          style={{ color: option.color }}
          className={[
            styles["context-menu-button"],
            option.disabled ? styles.disabled : "",
          ].join(" ")}
          disabled={option.disabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            option.action();
            onClose();
          }}
        >
          {option.label}
        </button>
      ))}
    </div>,
    document.body
  );
};

export default ContextMenu;
