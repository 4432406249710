import { convertHourMinutesToDecimal } from "../../../../utils/dates";
import { CALENDAR_TYPES, WEEKDAYS } from "../constants";

import type { CalendarType, DragProgress, RangeType } from "../types";

export const getYear = (date: Date): number => date.getFullYear();

const SUNDAY = WEEKDAYS[0];
const FRIDAY = WEEKDAYS[5];
const SATURDAY = WEEKDAYS[6];

/* Simple getters - getting a property of a given point in time */

/**
 * Gets day of the week of a given date.
 * @param {Date} date Date.
 * @param {CalendarType} [calendarType="iso8601"] Calendar type.
 * @returns {number} Day of the week.
 */
export function getDayOfWeek(date: Date, calendarType: CalendarType = CALENDAR_TYPES.ISO_8601): number {
  const weekday = date.getDay();

  switch (calendarType) {
    case CALENDAR_TYPES.ISO_8601:
      // Shifts days of the week so that Monday is 0, Sunday is 6
      return (weekday + 6) % 7;
    case CALENDAR_TYPES.ISLAMIC:
      return (weekday + 1) % 7;
    case CALENDAR_TYPES.HEBREW:
    case CALENDAR_TYPES.GREGORY:
      return weekday;
    default:
      throw new Error("Unsupported calendar type.");
  }
}

/**
 * Week
 */

/**
 * Returns the beginning of a given week.
 *
 * @param {Date} date Date.
 * @param {CalendarType} [calendarType="iso8601"] Calendar type.
 * @returns {Date} Beginning of a given week.
 */
export function getBeginOfWeek(date: Date, calendarType: CalendarType = CALENDAR_TYPES.ISO_8601): Date {
  const year = getYear(date);
  const monthIndex = date.getMonth();
  const day = date.getDate() - getDayOfWeek(date, calendarType);
  return new Date(year, monthIndex, day);
}

/**
 * Gets week number according to ISO 8601 or US standard.
 * In ISO 8601, Arabic and Hebrew week 1 is the one with January 4.
 * In US calendar week 1 is the one with January 1.
 *
 * @param {Date} date Date.
 * @param {CalendarType} [calendarType="iso8601"] Calendar type.
 * @returns {number} Week number.
 */
export function getWeekNumber(date: Date, calendarType: CalendarType = CALENDAR_TYPES.ISO_8601): number {
  const calendarTypeForWeekNumber =
    calendarType === CALENDAR_TYPES.GREGORY ? CALENDAR_TYPES.GREGORY : CALENDAR_TYPES.ISO_8601;
  const beginOfWeek = getBeginOfWeek(date, calendarType);
  let year = getYear(date) + 1;
  let dayInWeekOne: Date;
  let beginOfFirstWeek: Date;

  // Look for the first week one that does not come after a given date
  do {
    dayInWeekOne = new Date(year, 0, calendarTypeForWeekNumber === CALENDAR_TYPES.ISO_8601 ? 4 : 1);
    beginOfFirstWeek = getBeginOfWeek(dayInWeekOne, calendarType);
    year -= 1;
  } while (date < beginOfFirstWeek);

  return Math.round((beginOfWeek.getTime() - beginOfFirstWeek.getTime()) / (8.64e7 * 7)) + 1;
}

export const getDifferenceInDays = (date1: Date, date2: Date): number => {
  // Convert both dates to milliseconds
  const date1Ms = new Date(date1).getTime();
  const date2Ms = new Date(date2).getTime();

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date2Ms - date1Ms);

  // Convert milliseconds to days
  const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));

  return differenceDays;
};

/**
 * Others
 */

/**
 * Returns the beginning of a given range.
 *
 * @param {RangeType} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 * @returns {Date} Beginning of a given range.
 */
export function getBegin(rangeType: RangeType, date: Date): Date {
  switch (rangeType) {
    case "month":
      return getMonthStart(date);
    case "day":
      return getDayStart(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

export const getMonthStart = (date: Date): Date => {
  const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
  monthStart.setHours(0, 0, 0, 0);
  return monthStart;
};

export const getDayStart = (date: Date): Date => {
  const dayStart = new Date(date);
  dayStart.setHours(0, 0, 0, 0);
  return dayStart;
};

export const getDayEnd = (date: Date): Date => {
  const dayEnd = new Date(date);
  dayEnd.setHours(23, 59, 59, 999);
  return dayEnd;
};

export const getDaysInMonth = (date: Date): number => {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  return new Date(year, monthIndex + 1, 0).getDate();
};

/**
 * Returns an array with the beginning and the end of a given range.
 *
 * @param {RangeType} rangeType Range type (e.g. 'day')
 * @param {Date} date Date.
 * @returns {Date[]} Beginning and end of a given range.
 */
export function getRange(rangeType: RangeType, date: Date): [Date, Date] {
  switch (rangeType) {
    case "month":
      const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
      monthStart.setHours(0, 0, 0, 0);
      const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      monthEnd.setHours(23, 59, 59, 999);

      return [monthStart, monthEnd];
    case "day":
      const dayStart = new Date(date);
      dayStart.setHours(0, 0, 0, 0);
      const dayEnd = new Date(date);
      dayEnd.setHours(23, 59, 59, 999);

      return [dayStart, dayEnd];
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * @callback FormatYear
 * @param {string} locale Locale.
 * @param {Date} date Date.
 * @returns {string} Formatted year.
 */

/**
 * Returns a boolean determining whether a given date is the current day of the week.
 *
 * @param {Date} date Date.
 * @returns {boolean} Whether a given date is the current day of the week.
 */
export function isCurrentDayOfWeek(date: Date): boolean {
  return date.getDay() === new Date().getDay();
}

/**
 * Returns a boolean determining whether a given date is a weekend day.
 *
 * @param {Date} date Date.
 * @param {CalendarType} [calendarType="iso8601"] Calendar type.
 * @returns {boolean} Whether a given date is a weekend day.
 */
export function isWeekend(date: Date, calendarType: CalendarType = CALENDAR_TYPES.ISO_8601): boolean {
  const weekday = date.getDay();

  switch (calendarType) {
    case CALENDAR_TYPES.ISLAMIC:
    case CALENDAR_TYPES.HEBREW:
      return weekday === FRIDAY || weekday === SATURDAY;
    case CALENDAR_TYPES.ISO_8601:
    case CALENDAR_TYPES.GREGORY:
      return weekday === SATURDAY || weekday === SUNDAY;
    default:
      throw new Error("Unsupported calendar type.");
  }
}

/**
 * Start and end of a time period eg `start: 10:00` and `end: 11:00`
 */
export type Period = { start: string; end: string };

export const periodIncludesPeriod = ({
  period1,
  period2,
  gridStart,
}: {
  period1: Period;
  period2: Period;
  gridStart: number;
}) => {
  const period1StartDecimal = convertHourMinutesToDecimal(period1.start);
  const period1EndDecimal = convertHourMinutesToDecimal(period1.end);
  const period2StartDecimal = convertHourMinutesToDecimal(period2.start);
  const period2EndDecimal = convertHourMinutesToDecimal(period2.end);
  if (period1StartDecimal === 0 && period1EndDecimal % 12 === 0) {
    return period1StartDecimal <= period2StartDecimal && period1EndDecimal >= period2EndDecimal;
  }
  const adjustedPeriod1StartDecimal = (period1StartDecimal - gridStart + 24) % 24;
  const adjustedPeriod1EndDecimal = (period1EndDecimal - gridStart + 24) % 24;
  const adjustedPeriod2StartDecimal = (period2StartDecimal - gridStart + 24) % 24;
  const adjustedPeriod2EndDecimal = (period2EndDecimal - gridStart + 24) % 24;

  return adjustedPeriod1StartDecimal <= adjustedPeriod2StartDecimal && adjustedPeriod1EndDecimal >= adjustedPeriod2EndDecimal;
};

export const render12HourPeriod = (time: string) => {
  if (!time || typeof time !== "string") {
    return time;
  }
  const [hour, minutes] = time.split(":");
  const numHour = Number(hour);
  const hour12 = numHour % 12 || 12;
  return `${hour12}:${minutes} ${numHour >= 12 ? "pm" : "am"}`;
};

export const mergeDragProgress = ({
  dragProgress,
  newPeriod,
  gridStart,
}: {
  dragProgress: DragProgress;
  newPeriod: Period;
  gridStart: number;
}): DragProgress | null => {
  const lastPeriod = dragProgress.periods[dragProgress.periods.length - 1];
  const lastPeriodDecimal = convertHourMinutesToDecimal(lastPeriod.start);
  const newPeriodDecimal = convertHourMinutesToDecimal(newPeriod.start);

  const adjustedLastPeriodDecimal = (lastPeriodDecimal - gridStart + 24) % 24;
  const adjustedNewPeriodDecimal = (newPeriodDecimal - gridStart + 24) % 24;
  const lastMoveIsDownwards = adjustedNewPeriodDecimal > adjustedLastPeriodDecimal;
  const newSpan: number = lastMoveIsDownwards ? dragProgress.span + 1 : dragProgress.span - 1;
  const lastMoveRetracts = Math.abs(newSpan) > Math.abs(dragProgress.span);

  const periods =
    lastMoveRetracts
      ? dragProgress.periods.concat(newPeriod)
      : dragProgress.periods.slice(0, dragProgress.periods.length - 1);

  return {
    span: newSpan,
    day: dragProgress.day,
    periods,
  };
};
