import { Tooltip, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Box } from "@mui/material";
import CustomDatePicker from "../components/date-picker/DatePicker";
import { AvailableDate } from "../types";
import { useMemo } from "react";
import { Colors } from "../Colors";

const DatePickerField = ({
  dates,
  onDatesChange,
  placeholder = "Select dates",
  required = false,
}: {
  dates: AvailableDate[];
  onDatesChange: (dates: AvailableDate[]) => void;
  placeholder?: string;
  required?: boolean;
}) => {
  const handleAlwaysAvailableClick = () => {
    const newAvailableDates: AvailableDate[] = [];
    Array(90)
      .fill(0)
      .forEach((_, i) => {
        const date = new Date();
        date.setDate(date.getDate() + i);
        newAvailableDates.push(date.toISOString());
      });
    onDatesChange(newAvailableDates);
  };

  const daysSelected = useMemo(() => {
    const daysSet = new Set(
      dates.map((date) => {
        const dateObj = typeof date === "string" ? new Date(date) : new Date(date.start);
        return dateObj.toDateString();
      })
    );
    return daysSet.size;
  }, [dates]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
        <Tooltip title={required ? "This field is required" : ""}>
          <Typography variant="body1">
            Available Days ({daysSelected} selected){" "}
            {required && (
              <span style={{ color: Colors.error }}>
                *
              </span>
            )}
          </Typography>
        </Tooltip>
        <Button sx={{ textTransform: "none" }} onClick={handleAlwaysAvailableClick}>
          Patient is always available
        </Button>
      </Box>
      <CustomDatePicker dates={dates} onDatesChange={onDatesChange} placeholder={placeholder} inline />
    </>
  );
};

export default DatePickerField;
