import Modal from "../../components/modal/Modal";
import { PatientSlot } from "../../utils/types";
import styles from "./FillOpenSlots.module.scss";
import {
  calculatePeriodDifferenceInHours,
  convert24HourMinutesTo12HourMinutes,
} from "../../utils/dates";
import {
  getNumberOrdinal,
  renderFriendlyDuration,
} from "../../utils/formatters";

interface SlotViewModalProps {
  slot: PatientSlot | null;
  visible: boolean;
  onClose: () => void;
  onMoreDetails: () => void;
}

const SlotViewModal = ({
  slot,
  visible,
  onClose,
  onMoreDetails,
}: SlotViewModalProps) => {
  const date = slot?.date ? new Date(slot.date) : new Date();

  const duration =
    slot?.end && slot?.start ? calculatePeriodDifferenceInHours(slot) : 0;

  return (
    <Modal visible={visible} onClose={onClose}>
      {slot && (
        <div className={styles["slot-details-modal"]}>
          <h2>Details</h2>
          <span className="flex align-center gap-sm margin-top-sm">
            <img
              src={slot.agent?.avatarUrl || "/admin.svg"}
              className="app-icon rounded"
              alt="agent"
            />
            <span className={styles.dot} />
            <span
              className={[styles["slot-status"], styles[slot.status]].join(" ")}
            >
              {slot.status}
            </span>
          </span>

          <div className={styles["slot-summary"]}>
            <span className={styles["slot-summary-left"]}>
              <span className={styles["slot-date"]}>
                <span className={styles["slot-date-month"]}>
                  {date.toLocaleDateString("en-US", { month: "short" })}
                </span>
                <span className={styles["slot-date-day"]}>
                  {getNumberOrdinal(date.getDate())}
                </span>
              </span>
              <span className={styles["slot-summary-mid"]}>
                <span className={styles["slot-summary-types"]}>
                  {slot.proposedAppointmentType?.id === "*"
                    ? "Any appointment"
                    : slot.proposedAppointmentType?.name}
                </span>
                <span className={styles["slot-summary-time"]}>
                  <span className={styles["slot-time"]}>
                    {convert24HourMinutesTo12HourMinutes(slot.start)} -{" "}
                    {convert24HourMinutesTo12HourMinutes(slot.end)}
                  </span>
                  <span className={styles.dot} />
                  <span className={styles["slot-summary-duration"]}>
                    {renderFriendlyDuration(duration)}
                  </span>
                </span>
              </span>
            </span>
            <span
              className={[
                styles["slot-summary-appointment-mode"],
                styles[slot.proposedAppointmentMode || ""],
              ].join(" ")}
            >
              {slot.proposedAppointmentMode}
            </span>
          </div>

          <button
            className={styles["slot-details-modal-button"]}
            onClick={onMoreDetails}
          >
            View
          </button>
        </div>
      )}
    </Modal>
  );
};

export default SlotViewModal;
