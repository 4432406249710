import React from "react";
import { Modal, Box, Typography, FormControl, InputLabel, Select, MenuItem, Button, TextField } from "@mui/material";
import { EHR } from "../../types";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { createRun } from "../../slices/WaitlistRunsSlice";
import { ModalBoxVariant1 } from "../../styles/GeneralStyles";
import LoadingWithMessage from "../../subcomponents/LoadingWithMessage";

type AddPtpracticeproRunModalProps = {
  open: boolean;
  onClose: () => void;
};

const AddPtpracticeproRunModal: React.FC<AddPtpracticeproRunModalProps> = ({ open, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const { agentId } = useParams<{ agentId: string }>();
  const [localErrorMessage, setLocalErrorMessage] = React.useState<string>("");
  const [appointmentDate, setAppointmentDate] = React.useState<DateTime | null>(null);
  const [providerId, setProviderId] = React.useState<string>("");
  const [duration, setDuration] = React.useState<number>(15);
  const [appointmentTypeId, setAppointmentTypeId] = React.useState<string>("");
  const [contactType, setContactType] = React.useState<string>("");
  const dispatch = useAppDispatch();
  const loadingCreatingRun = useSelector((state: RootState) => state.waitlistRuns.loadingCreatingRun);
  const providers = useSelector((state: RootState) => state.ptpracticeproIntegration.providers);
  const appointmentTypes = useSelector((state: RootState) => state.ptpracticeproIntegration.appointments);
  const contactTypes =
    (appointmentTypeId && appointmentTypes.find((appointment) => appointment.id === appointmentTypeId)?.available_contact_types) || [];

  const handleCreateRun = async () => {
    if (!user?.token) {
      return;
    }
    const provider = providers.find((provider) => provider.id === providerId);
    const appointmentType = appointmentTypes.find((appointment) => appointment.id === appointmentTypeId);
    if (!provider || !appointmentType) {
      setLocalErrorMessage("Please select a provider and appointment type.");
      return;
    }
    await dispatch(
      createRun({
        token: user.token,
        params: {
          appointmentDate: appointmentDate?.toISO() ?? "",
          ehrParams: {
            providerId: providerId,
            pcpName: provider.name,
            ehrApptTypeId: appointmentTypeId,
            appointmentTypeName: appointmentType.name,
            appointmentDuration: duration,
            ehr: EHR.ptpracticepro,
          },
        },
      })
    );
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalBoxVariant1>
        <Typography variant="h4" gutterBottom>
          Create Run
        </Typography>

        <Typography variant="body1" gutterBottom>
          Select a time
        </Typography>
        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", mb: 2 }}>
          <DateTimePicker label="Appointment date" value={appointmentDate} onChange={setAppointmentDate} />
        </Box>

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="provider-select-label">Select a provider</InputLabel>
          <Select labelId="provider-select-label" value={providerId} onChange={(e) => setProviderId(e.target.value)} label="Select a provider">
            <MenuItem value="">
              <em>Select a provider</em>
            </MenuItem>
            {providers.map((provider) => (
              <MenuItem key={provider.id} value={provider.id}>
                {provider.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel id="appointment-type-select-label">Select an appointment type</InputLabel>
          <Select
            labelId="appointment-type-select-label"
            value={appointmentTypeId}
            onChange={(e) => setAppointmentTypeId(e.target.value)}
            label="Select an appointment type"
          >
            <MenuItem value="">
              <em>Select an appointment type</em>
            </MenuItem>
            {appointmentTypes.map((appointment) => (
              <MenuItem key={appointment.id} value={appointment.id}>
                {appointment.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography variant="body1" gutterBottom>
          Appointment Duration
        </Typography>
        <TextField
          type="number"
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          fullWidth
          margin="normal"
          variant="outlined"
        />

        {loadingCreatingRun && <LoadingWithMessage message="Creating run..." size={50} customStyles={{ color: "black" }} />}

        <Button variant="contained" color="primary" onClick={handleCreateRun}>
          Add Run
        </Button>
      </ModalBoxVariant1>
    </Modal>
  );
};

export default AddPtpracticeproRunModal;
