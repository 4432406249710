import {
  calculatePeriodDifferenceInHours,
  Period,
  periodIntersectsWithPeriod,
} from "./dates";
import { appointmentTypes } from "./constants";
import { AppointmentType, PatientSlot } from "./types";

export const getNewId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
};

export const waitOut = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const getRandomAppointmentType = ({
  min,
  max,
  maxDuration,
}: {
  min: number;
  max: number;
  maxDuration: number;
}) => {
  const appointmentTypesIds = appointmentTypes
    .filter((type) => type.id !== "*" && type.duration <= maxDuration * 60)
    .map((type) => type.id);
  const randomAppointmentTypes = Array(
    Math.floor(Math.random() * (max - min) + min)
  )
    .fill(null)
    .map(
      () =>
        appointmentTypesIds[
          Math.floor(Math.random() * appointmentTypesIds.length)
        ]
    );
  return Array.from(new Set(randomAppointmentTypes)).map(
    (id) => appointmentTypes.find((type) => type.id === id) as AppointmentType
  );
};

const getRandomPeriod = ({
  currentDaySlots,
  currentDate,
}: {
  currentDaySlots: Period[];
  currentDate: Date;
}): Period | null => {
  let retries = 0;
  let period: Period | null = null;
  while (retries < 10) {
    // If day is today, start from current hour till end of day
    const dayIsToday = new Date().toDateString() === currentDate.toDateString();
    const currentHour = dayIsToday ? currentDate.getHours() + 1 : 0;
    const randomHour =
      Math.floor(Math.random() * (24 - currentHour)) + currentHour;
    const start = `${randomHour}:00`;
    const end = `${randomHour + 1}:00`;
    retries++;
    if (
      currentDaySlots.some((slot) =>
        periodIntersectsWithPeriod(slot, { start, end })
      )
    ) {
      continue;
    }
    period = { start, end };
  }
  return period;
};

/**
 * Generates a random number of slots for each day in the current week (starting from the startDate)
 * ensuring that each slot does not overlap with the existing slots
 * and ensuring that each day gets 2 slots
 * @param currentSelectionMap - The map of existing slots
 * @param startDate - The start date of the current week
 * @returns The generated slots
 */
export const generateFakeSlots = ({
  currentSelectionMap,
  startDate,
}: {
  currentSelectionMap: Map<string, PatientSlot[]>;
  startDate: Date;
}): PatientSlot[] => {
  // Remaining days in week including today
  const daysLeft = 7 - startDate.getDay();

  return Array(daysLeft)
    .fill(null)
    .map((_, index) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + index);
      const invalidPeriods: Period[] =
        currentSelectionMap.get(date.toDateString())?.slice() || [];
      const randomPeriods = Array(2)
        .fill(null)
        .map(() => {
          const period = getRandomPeriod({
            currentDaySlots: invalidPeriods,
            currentDate: date,
          });
          if (period) {
            invalidPeriods.push(period);
          }
          return period;
        })
        .filter(Boolean) as Period[];

      return randomPeriods.map((period) => {
        return {
          id: getNewId(),
          start: period.start,
          end: period.end,
          date: date.toDateString(),
          patientName: "",
          appointmentType: null,
          status: "proposed",
          proposedAppointmentType: getRandomAppointmentType({
            min: 1,
            max: 1,
            maxDuration: calculatePeriodDifferenceInHours(period),
          })[0],
          proposedAppointmentMode:
            Math.random() > 0.5 ? "virtual" : "in-person",
          agent: {
            name: "Rando Agent",
            avatarUrl: "/admin.svg",
          },
        } as PatientSlot;
      });
    })
    .flat();
};
