import { Period } from "./dates";
import { PatientSlot } from "./types";

export const getNumberOrdinal = (number: number) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const value = number % 100;
  return (
    number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
  );
};

export const getBlankPatientSlot = (
  period: Period,
  date: string
): PatientSlot => {
  return {
    id: "",
    start: period.start,
    end: period.end,
    patientName: "",
    appointmentType: null,
    status: "proposed",
    date,
    proposedAppointmentType: {
      id: "*",
      name: "Any",
      duration: Infinity,
    },

    agent: undefined,
    proposedAppointmentMode: "in-person",
    source: "ai",
  };
};

export const renderFriendlyDuration = (duration: number) => {
  return duration < 1
    ? `${duration * 60} minutes`
    : `${duration} hr${duration === 1 ? "" : "s"}`;
};
