import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import posthog from "posthog-js";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { PostHogProvider } from "posthog-js/react";
import { SnackbarProvider } from "./providers/SnackbarProvider";
import { BrowserRouter as Router } from "react-router-dom";
import SessionCheck from "./sessionCheck";
import { AuthProvider } from "./AuthUtils/AuthContext";
import ThemeProviderWrapper from "./providers/themeProvider";
import { LicenseInfo } from "@mui/x-license-pro";
import "./index.scss";
import "./fonts/fonts.css";

if (!process.env.REACT_APP_PUBLIC_POSTHOG_KEY) {
  throw new Error("REACT_APP_PUBLIC_POSTHOG_KEY not set");
}

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY);

if (!process.env.REACT_APP_MUI_PRO_LICENSE) {
  throw new Error("REACT_APP_MUI_PRO_LICENSE not set");
}

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENSE);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PostHogProvider client={posthog}>
          <SnackbarProvider>
            <Router>
              <SessionCheck>
                <AuthProvider>
                  <LocalizationProvider dateAdapter={AdapterLuxon}>
                      <ThemeProviderWrapper>
                        <App />
                      </ThemeProviderWrapper>
                  </LocalizationProvider>
                </AuthProvider>
              </SessionCheck>
            </Router>
          </SnackbarProvider>
        </PostHogProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
