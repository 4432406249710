import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Button,
  Paper,
  Tooltip,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { DateTime } from "luxon";
import { convertToCallablePhoneNumber, formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import {
  AvailableDate,
  EHR,
  EHRPatient,
  HealthieAppointment,
  HealthiePatient,
  OmniPatient,
  Patient,
  RawHealthiePatient,
  RawWebptPatient,
  WaitlistPatient,
  WebptPatient,
} from "../types";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import CircleIcon from "@mui/icons-material/Circle";
import Healthie from "../assets/HealthieWhiteLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../Colors";
import { createPatient as createPatientThunk } from "../slices/PatientSlice";
import { dropdownStyles, menuPropsStyles } from "../styles/GeneralStyles";
import { HeaderBox, FooterBox } from "../subcomponents/SidebarComponents";
import CustomDatePicker from "../components/date-picker/DatePicker";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { first } from "lodash";
import { useSnackbar } from "../providers/SnackbarProvider";
import CustomAutocomplete from "../components/CustomAutocomplete";
import { fetchHealthiePatients } from "../slices/HealthieIntegrationSlice";
import WarningIcon from "@mui/icons-material/Warning";
import { Warning } from "postcss";
import { fetchPteverywherePatients } from "../slices/PteverywhereIntegrationSlice";
import { fetchRaintreePatients, RawRaintreePatient } from "../slices/RaintreeIntegrationSlice";
import DatePickerField from "../subcomponents/DatePickerField";
import { fetchPtpracticeproPatients } from "../slices/PtpracticeproIntegrationSlice";
import { fetchHenoPatients } from "../slices/HenoIntegrationSlice";

//const timezones = moment.tz.names();
const timezones = ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"];

const timezoneOptions = timezones.map((tz) => {
  const dt = DateTime.now().setZone(tz);
  const offsetString = dt.toFormat("ZZ");
  const text = `${tz} (UTC${offsetString})`;

  return { value: tz, label: text, offset: dt.offset };
});

timezoneOptions.sort((a, b) => a.offset - b.offset);

const contactOptions = [
  { value: "call", label: "Call" },
  { value: "text", label: "Text" },
];

const languageOptions = [
  { label: "English", value: "en" },
  /*
  { label: "Spanish", value: "es" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Chinese", value: "zh" },
   */
  // TODO (Hizami): Figure out what languages we want
];

// type AddPatientModalProps = {
//   open: boolean;
//   onClose: () => void;
// };

interface HealthieAddPatientModalProps {
  onClick: () => void;
  onClose: () => void;
}

const HealthieAddPatientModal: React.FC<HealthieAddPatientModalProps> = ({ onClick, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const healthiePatients = Object.values(useSelector((state: RootState) => state.healthieIntegration.patientMap));
  const patientSearches = useSelector((state: RootState) => state.healthieIntegration.patientSearches);
  const validPatientSearches = useSelector((state: RootState) => state.healthieIntegration.validPatientSearches);
  const invalidPatientSearches = useSelector((state: RootState) => state.healthieIntegration.invalidPatientSearches);
  const { agentId } = useParams<{ agentId: string }>();
  const [timezone, setTimezone] = React.useState<string>(DateTime.local().zoneName);
  const [language, setLanguage] = React.useState<string>("en"); // TODO (Hizami): This is hard coded because we aren't supporting multiple languages right now
  const [availableDates, setAvailableDates] = React.useState<AvailableDate[]>([]);
  const [selectedHealthiePatient, setSelectedHealthiePatient] = React.useState<RawHealthiePatient | undefined>(undefined);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>([]);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>([]);
  const [textEnabled, setTextEnabled] = React.useState<boolean>(true);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(false);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openContactTypes, setOpenContactTypes] = useState(false);

  const loadingHealthiePatients: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);

  let providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const healthieProviderIds = organization?.healthieProviderIds || [];
  if (healthieProviderIds && healthieProviderIds.length > 0) {
    providers = providers.filter((provider) => healthieProviderIds.includes(provider.id));
  }
  let appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const healthieAppointmentTypeIds = organization?.healthieAppointmentTypeIds || [];
  if (healthieAppointmentTypeIds && healthieAppointmentTypeIds.length > 0) {
    appointments = appointments.filter((appointment) => healthieAppointmentTypeIds.includes(appointment.id));
  }
  const contactTypes = organization?.healthieContactTypes || ["In Person", "Phone Call", "Secure Videochat"]; // TODO (Hizami): This is hard-coded from healthie

  const loadingProviders: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingAppointments: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">("text");

  const { showMessage } = useSnackbar();

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(undefined);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  const dispatch = useAppDispatch();

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const createPatient = async () => {
    if (!user?.token || !organization?.organizationId) {
      return;
    }
    setLoading(true);
    try {
      if (!selectedHealthiePatient) {
        return;
      }
      const patientId = uuidv4();
      const organizationId = organization.organizationId;

      const patient: Patient = {
        patientId,
        organizationId,
        firstName: selectedHealthiePatient?.firstName,
        lastName: selectedHealthiePatient?.lastName,
        phoneNumber: convertToCallablePhoneNumber(selectedHealthiePatient?.phoneNumber),
        email: selectedHealthiePatient?.email,
        timezone: timezone,
        language: language,
        callEnabled: callEnabled,
        textEnabled: textEnabled,
        dob: selectedHealthiePatient?.dob,
        consentHistory: [],
      };

      const waitlistPatient: WaitlistPatient = {
        patientId,
        organizationId,
        priority: 1,
        availableDates: availableDates,
        patientGroupId,
      };

      const healthiePatient: HealthiePatient = {
        patientId,
        organizationId,
        healthiePatientId: selectedHealthiePatient?.healthiePatientId,
        providerIds: selectedProviders,
        appointmentTypeIds: selectedAppointmentTypes,
        contactTypes: selectedContactTypes,
      };

      const omniPatient: OmniPatient = {
        patientId: patientId,
        patient: patient,
        waitlist: waitlistPatient,
        [EHR.healthie]: healthiePatient,
      };

      const resultAction = await dispatch(
        createPatientThunk({
          token: user.token,
          omniPatient,
        })
      );

      if (createPatientThunk.rejected.match(resultAction)) {
        throw new Error(resultAction.payload?.message || "Failed to create patient");
      }

      onClick();
      onClose();
      setSelectedHealthiePatient(undefined);
      setAvailableDates([]);
    } catch (error: any) {
      // Handle any errors from the thunk if needed
      console.error("Error creating patient:", error);
      showMessage("Error creating patient: " + error.message, "error");
    }
    setLoading(false);
  };

  const providerOptions = providers
    .map((provider) => ({ value: provider.id, label: provider.firstName + " " + provider.lastName }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const appointmentOptions = appointments
    .map((appointment) => ({ value: appointment.id, label: appointment.name }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const contactTypeOptions = contactTypes.map((contactType) => ({ value: contactType, label: contactType }));

  const healthiePatientOptions = healthiePatients
    .map((patient: RawHealthiePatient) => ({
      value: patient.healthiePatientId,
      label: `${patient.firstName} ${patient.lastName} (ID: ${patient.healthiePatientId})`,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const handleHealthiePatientSelection = (healthieId: string) => {
    if (healthieId) {
      const healthiePatient = healthiePatients.find((patient) => patient.healthiePatientId === healthieId);
      setSelectedHealthiePatient(healthiePatient);
      setShowPatientDetails(true);
      if (healthiePatient?.timezone) {
        setTimezone(healthiePatient?.timezone);
      } else {
        setTimezone(DateTime.local().zoneName);
      }
      /*
      TODO (Hizami): Get language code from Healthie
      if (healthiePatient?.preferredLanguageCode) {
        setLanguage(healthiePatient.preferredLanguageCode);
      }
      */
    } else {
      setSelectedHealthiePatient(undefined);
      setShowPatientDetails(false);
    }
  };

  const handleCloseDetails = () => {
    setSelectedHealthiePatient(undefined);
    setShowPatientDetails(false);
  };

  const handleSelectAllProviders = () => setSelectedProviders(providers.map((provider) => provider.id));
  const handleDeselectAllProviders = () => setSelectedProviders([]);
  const handleSelectAllAppointmentTypes = () => setSelectedAppointmentTypes(appointments.map((appointment) => appointment.id));
  const handleDeselectAllAppointmentTypes = () => setSelectedAppointmentTypes([]);
  const handleSelectAllContactTypes = () => setSelectedContactTypes(contactTypes);
  const handleDeselectAllContactTypes = () => setSelectedContactTypes([]);

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent Backspace from deselecting the selected options
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const handleInputChange = (value: string) => {
    setCurrentQuery(value);
    const notSearched = !patientSearches.includes(value);
    const notSubsearched = !validPatientSearches.some((search) => value.includes(search));
    if (user?.token && notSearched && notSubsearched) {
      dispatch(fetchHealthiePatients({ token: user.token, keywords: value }));
    }
  };

  const currentQueryIsInvalid = invalidPatientSearches.includes(currentQuery);

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title="Add Patient" onClose={onClose} />

      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"50px"}>
        <Typography margin={"10px 0 10px 0"} fontWeight={"bold"} fontSize={"1.2rem"}>
          Import from EHR
        </Typography>
        <img src={Healthie} alt="Healthie" style={{ width: "85px", height: "auto", marginTop: "15px", borderRadius: "0" }} />
      </Box>

      <Box display="flex" alignItems="center" gap="10px" mt={2}>
        <Typography variant="body1">Patient</Typography>
        {currentQueryIsInvalid && (
          <Box display="flex" alignItems="center" gap="5px">
            <WarningIcon color="warning" sx={{ fontSize: "1rem" }} />
            <Typography variant="body2" color="#FCD554">
              Please enter a more specific query
            </Typography>
          </Box>
        )}
      </Box>

      {loadingHealthiePatients && <LoadingWithMessage message="Loading patients..." size={10} />}
      {showPatientDetails ? (
        <>
          {selectedHealthiePatient && (
            <Box
              sx={{
                padding: "10px",
                background: Colors.textfield,
                borderRadius: "10px",
                color: "#FFFFFF",
                marginTop: "5px",
                position: "relative",
                "&:hover .close-icon": {
                  visibility: "visible",
                },
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
                {`${selectedHealthiePatient?.firstName ?? ""} ${selectedHealthiePatient?.lastName ?? ""}`}
              </Typography>
              <Box
                sx={{
                  color: Colors.info,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "left", sm: "center" },
                  gap: "10px",
                }}
                mt={0.5}
              >
                <Typography variant="body2">{`${selectedHealthiePatient?.phoneNumber ?? ""}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`ID: ${selectedHealthiePatient?.healthiePatientId ?? ""}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`${timezone}`}</Typography>
              </Box>

              <IconButton
                className="close-icon"
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: "#FFF",
                  visibility: "hidden",
                  padding: "5px",
                  zIndex: 1,
                }}
                onClick={handleCloseDetails}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Autocomplete
          options={healthiePatientOptions}
          getOptionLabel={(option) => `${option.label.split(" (ID:")[0]}   (ID: ${option.value})`}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.value}
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: Colors.bg3,
                color: "#FFF",
                padding: "8px 16px",
              }}
            >
              <span>{option.label.split(" (ID:")[0]}</span>
              <span style={{ color: Colors.info }}>{`ID: ${option.value}`}</span>
            </li>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Type patient name to load patients" />}
          value={healthiePatientOptions.find((option) => option.value === selectedHealthiePatient?.healthiePatientId) || null}
          onChange={(e, value) => handleHealthiePatientSelection(value?.value || "")}
          onInputChange={(e, value) => handleInputChange(value)}
          fullWidth
          ListboxComponent={(props) => (
            <ul
              {...props}
              style={{
                padding: 0,
                margin: 0,
                background: Colors.bg3,
              }}
            />
          )}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
            color: Colors.coolWhite,
            "& .MuiInputBase-input": {
              padding: "0px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFFFFF", // Set text color to white
            },
            "& .MuiAutocomplete-paper": {
              backgroundColor: Colors.bg3,
              margin: 0,
              color: "#FFFFFF", // Set text color to white
            },
            "& .MuiAutocomplete-listbox": {
              backgroundColor: Colors.bg3,
              color: "#FFFFFF", // Set text color to white
            },
          }}
        />
      )}

      <Typography variant="body1" mt={3} mb={1}>
        AI agent mode of contact
      </Typography>

      <FormControl variant="outlined" fullWidth>
        <Box display="flex" gap="10px">
          <Button
            onClick={() => handleContactModeChange("call")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "call" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "call" ? "#fff" : Colors.info,
            }}
          >
            Call only
          </Button>

          <Button
            onClick={() => handleContactModeChange("text")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "text" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "text" ? "#fff" : Colors.info,
            }}
          >
            Text only
          </Button>

          <Button
            onClick={() => handleContactModeChange("both")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "both" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "both" ? "#fff" : Colors.info,
            }}
          >
            Both
          </Button>
        </Box>
      </FormControl>

      <Typography variant="body1" fontWeight={"bold"} mt={3}>
        Waitlist Parameters <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select
            value={patientGroupId}
            onChange={(e) => setPatientGroupId(e.target.value)}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {!loadingProviders && (
        <CustomAutocomplete label="Providers" options={providerOptions} selectedItems={selectedProviders} setSelectedItems={setSelectedProviders} />
      )}
      {loadingProviders && <LoadingWithMessage message="Loading providers..." size={10} />}

      {!loadingAppointments && (
        <CustomAutocomplete
          label="Appointment Types"
          options={appointmentOptions}
          selectedItems={selectedAppointmentTypes}
          setSelectedItems={setSelectedAppointmentTypes}
        />
      )}
      {loadingAppointments && <LoadingWithMessage message="Loading appointment types..." size={10} />}

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Contact Types</Typography>
          {selectedContactTypes.length === contactTypeOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllContactTypes}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllContactTypes}>
              Select All
            </Button>
          )}
        </Box>

        <Select
          multiple
          displayEmpty
          open={openContactTypes}
          onClose={() => setOpenContactTypes(false)}
          onOpen={() => setOpenContactTypes(true)}
          onChange={(e) => {
            setSelectedContactTypes(e.target.value as string[]);
            setOpenContactTypes(false); // Close the menu
          }}
          value={selectedContactTypes}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return (selected as string[]).map((value) => contactTypeOptions.find((option) => option.value === value)?.label).join(", ");
          }}
          sx={dropdownStyles}
          MenuProps={menuPropsStyles}
        >
          {contactTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedContactTypes.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Box
        sx={{
          paddingBottom: "20px", // Increased padding at the bottom to prevent cutoff
          paddingTop: "20px", // Added top padding for balance (optional)
        }}
      >
        <DatePickerField dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />
      </Box>

      <FooterBox
        onClick={createPatient}
        disabled={!selectedHealthiePatient || availableDates.length === 0}
        buttonText="Add Patient"
        loading={loading}
      />
    </Box>
  );
};

interface WebptAddPatientModalProps {
  onClick: () => void;
  onClose: () => void;
}

export const WebptAddPatientModal: React.FC<WebptAddPatientModalProps> = ({ onClick, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const webptPatients = useSelector((state: RootState) => state.webptIntegration.patients);
  const { agentId } = useParams<{ agentId: string }>();
  const [timezone, setTimezone] = React.useState<string>(DateTime.local().zoneName);
  const [availableDates, setAvailableDates] = React.useState<AvailableDate[]>([]);
  const [selectedWebptPatient, setSelectedWebptPatient] = React.useState<RawWebptPatient | undefined>(undefined);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [textEnabled, setTextEnabled] = React.useState<boolean>(true);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(false);
  const [language, setLanguage] = React.useState<string>("en");
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState<number>(30); // Appointment duration in minutes
  const [caseStudies, setCaseStudies] = useState<any[]>([]); // Store case studies for the selected patient
  const [selectedCaseStudy, setSelectedCaseStudy] = React.useState<number | undefined>(undefined);

  const providers = useSelector((state: RootState) => state.webptIntegration.providers);
  const providerOptions = providers.map((provider) => ({ value: provider.id, label: provider.name }));

  //const loadingHealthiePatients: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">("text");

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(undefined);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  const { showMessage } = useSnackbar();

  const dispatch = useAppDispatch();

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const createPatient = async () => {
    if (!user?.token || !organization?.organizationId) {
      return;
    }
    if (!selectedWebptPatient || !selectedCaseStudy) {
      return;
    }
    setLoading(true);

    try {
      const patientId = uuidv4();
      const organizationId = organization.organizationId;

      const patient: Patient = {
        patientId,
        organizationId,
        firstName: selectedWebptPatient.firstName,
        lastName: selectedWebptPatient.lastName,
        phoneNumber: convertToCallablePhoneNumber(phoneNumber),
        timezone: timezone,
        language: language,
        callEnabled: callEnabled,
        textEnabled: textEnabled,
        dob: selectedWebptPatient.dob,
        consentHistory: [],
      };

      const waitlistPatient: WaitlistPatient = {
        patientId,
        organizationId,
        priority: 1,
        availableDates: availableDates,
        patientGroupId,
      };

      const webptPatient: WebptPatient = {
        patientId,
        organizationId,
        webptPatientId: selectedWebptPatient.webptPatientId,
        webptProviderIds: selectedProviders,
        webptAppointmentDuration: duration,
        webptCaseStudyId: selectedCaseStudy,
      };

      const omniPatient: OmniPatient = {
        patientId: patientId,
        patient: patient,
        waitlist: waitlistPatient,
        [EHR.webpt]: webptPatient,
      };

      const resultAction = await dispatch(
        createPatientThunk({
          token: user.token,
          omniPatient,
        })
      );
      if (createPatientThunk.rejected.match(resultAction)) {
        throw new Error(resultAction.payload?.message || "Failed to create patient");
      }
      onClick();
      onClose();
      setSelectedWebptPatient(undefined);
      setAvailableDates([]);
    } catch (error: any) {
      // Handle any errors from the thunk if needed
      console.error("Error creating patient:", error);
      showMessage("Error creating patient: " + error.message, "error");
    }
    setLoading(false);
  };

  const webptPatientOptions = webptPatients.map((patient: RawWebptPatient) => ({
    value: patient.webptPatientId,
    label: `${patient.firstName} ${patient.lastName} (ID: ${patient.webptPatientId})`,
  }));

  const handleWebptPatientSelection = async (patientId: string) => {
    if (patientId) {
      const patient = webptPatients.find((patient) => patient.webptPatientId === patientId);
      setSelectedWebptPatient(patient);
      setShowPatientDetails(true);
      setPhoneNumber("");
      if (user?.token) {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/webpt/patients/${patientId}?organizationId=${user.organizationId}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
            params: {
              firstName: patient?.firstName,
              lastName: patient?.lastName,
            },
          }
        );
        const studies = response.data as any[];
        setCaseStudies(studies);
        if (studies.length > 0) {
          setPhoneNumber(first(studies).phone ? convertToCallablePhoneNumber(first(studies).phone) : "Unknown Phone Number");
        } else {
          setPhoneNumber("Unknown Phone Number");
        }
        if (studies.length === 1) {
          setSelectedCaseStudy(studies[0].CaseID);
        } else {
          setSelectedCaseStudy(undefined);
        }
      }
    } else {
      setSelectedWebptPatient(undefined);
      setSelectedCaseStudy(undefined);
      setCaseStudies([]);
      setPhoneNumber("");
      setShowPatientDetails(false);
    }
  };

  const handleCloseDetails = () => {
    setSelectedWebptPatient(undefined);
    setSelectedCaseStudy(undefined);
    setCaseStudies([]);
    setPhoneNumber("");
    setShowPatientDetails(false);
  };

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const caseStudyOptions = caseStudies.map((study) => {
    const value = study.CaseID;
    const title = study.Title;

    const reason = title.split(" - ")[2]; // Title is of the form {Name} - {Birthday} - ({Reason})
    const label = reason.replace(/[()]/g, "").trim();
    return {
      value: value as number,
      label: label as string,
    };
  });

  const formIsDisabled =
    !selectedWebptPatient || !availableDates.length || !duration || !contactMode || !selectedProviders.length || !selectedCaseStudy;

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title="Add Patient" onClose={onClose} />

      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"50px"}>
        <Typography margin={"10px 0 10px 0"} fontWeight={"bold"} fontSize={"1.2rem"}>
          Import from WebPT
        </Typography>
      </Box>

      <Tooltip title="Patient is required">
        <Typography variant="body1">
          Patient <span style={{ color: Colors.error }}>*</span>
        </Typography>
      </Tooltip>
      {/*loadingHealthiePatients && <LoadingWithMessage message="Loading patients..." size={10} />*/}
      {showPatientDetails ? (
        <>
          {selectedWebptPatient && (
            <Box
              sx={{
                padding: "10px",
                background: Colors.textfield,
                borderRadius: "10px",
                color: "#FFFFFF",
                marginTop: "5px",
                position: "relative",
                "&:hover .close-icon": {
                  visibility: "visible",
                },
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
                {`${selectedWebptPatient.firstName ?? ""} ${selectedWebptPatient.lastName ?? ""}`}
              </Typography>
              <Box
                sx={{
                  color: Colors.info,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "left", sm: "center" },
                  gap: "10px",
                }}
                mt={0.5}
              >
                <Typography variant="body2">{`${phoneNumber || "Loading Phone Number..."}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`ID: ${selectedWebptPatient.webptPatientId ?? ""}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`${timezone}`}</Typography>
              </Box>

              <IconButton
                className="close-icon"
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: "#FFF",
                  visibility: "hidden",
                  padding: "5px",
                  zIndex: 1,
                }}
                onClick={handleCloseDetails}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Autocomplete
          options={webptPatientOptions}
          getOptionLabel={(option) => `${option.label.split(" (ID:")[0]}   (ID: ${option.value})`}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.value}
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: Colors.bg3,
                color: "#FFF",
                padding: "8px 16px",
              }}
            >
              <span>{option.label.split(" (ID:")[0]}</span>
              <span style={{ color: Colors.info }}>{`ID: ${option.value}`}</span>
            </li>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select patient" />}
          value={webptPatientOptions.find((option) => option.value === selectedWebptPatient?.webptPatientId) || null}
          onChange={(e, value) => handleWebptPatientSelection(value?.value || "0")}
          fullWidth
          ListboxComponent={(props) => (
            <ul
              {...props}
              style={{
                padding: 0,
                margin: 0,
                background: Colors.bg3,
              }}
            />
          )}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "0px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
            "& .MuiAutocomplete-paper": {
              backgroundColor: Colors.bg3,
              margin: 0,
            },
            "& .MuiAutocomplete-listbox": {
              backgroundColor: Colors.bg3,
            },
          }}
        />
      )}

      {/*<Typography variant="body1" mt={3} mb={1}>
        Phone Number
      </Typography>
      <TextField
        variant="outlined"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter phone number"
        fullWidth
        sx={{
          borderRadius: "10px",
          background: Colors.textfield,
          "& .MuiInputBase-input": {
            padding: "15px",
            fontSize: "0.9rem",
            color: "#FFFFFF",
          },
        }}
      />*/}

      <Tooltip title="This field is required">
        <Typography variant="body1" mt={3} mb={1}>
          AI agent mode of contact <span style={{ color: Colors.error }}>*</span>
        </Typography>
      </Tooltip>

      <FormControl variant="outlined" fullWidth>
        <Box display="flex" gap="10px">
          <Button
            onClick={() => handleContactModeChange("call")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "call" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "call" ? "#fff" : Colors.info,
            }}
          >
            Call only
          </Button>

          <Button
            onClick={() => handleContactModeChange("text")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "text" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "text" ? "#fff" : Colors.info,
            }}
          >
            Text only
          </Button>

          <Button
            onClick={() => handleContactModeChange("both")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "both" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "both" ? "#fff" : Colors.info,
            }}
          >
            Both
          </Button>
        </Box>
      </FormControl>

      {caseStudies.length > 0 && (
        <FormControl fullWidth>
          <Tooltip title="This field is required">
            <Typography variant="body1" mt={3} mb={1}>
              Case Study <span style={{ color: Colors.error }}>*</span>
            </Typography>
          </Tooltip>
          <Select
            value={selectedCaseStudy}
            onChange={(e) => setSelectedCaseStudy(Number(e.target.value))}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {caseStudyOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Typography variant="body1" fontWeight={"bold"} mt={3}>
        Waitlist Parameters <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select
            value={patientGroupId}
            onChange={(e) => setPatientGroupId(e.target.value)}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
          <Tooltip title="This field is required">
            <Typography variant="body1">
              Providers <span style={{ color: Colors.error }}>*</span>
            </Typography>
          </Tooltip>
          {selectedProviders.length === providerOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={() => setSelectedProviders([])}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={() => setSelectedProviders(providerOptions.map((option) => option.value))}>
              Select All
            </Button>
          )}
        </Box>

        <Select
          multiple
          displayEmpty
          value={selectedProviders}
          onChange={(e) => setSelectedProviders(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return (selected as string[]).map((value) => providerOptions.find((option) => option.value === value)?.label).join(", ");
          }}
          sx={dropdownStyles}
          MenuProps={menuPropsStyles}
        >
          {providerOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedProviders.indexOf(option.value) > -1} sx={{ color: "#FFF" }} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Tooltip title="This field is required">
          <Typography variant="body1" mt={3} mb={1}>
            Appointment Duration (minutes) <span style={{ color: Colors.error }}>*</span>
          </Typography>
        </Tooltip>
        <TextField
          variant="outlined"
          type="number"
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          placeholder="Enter duration"
          fullWidth
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
          }}
        />
      </FormControl>

      <Box
        sx={{
          paddingBottom: "20px", // Increased padding at the bottom to prevent cutoff
          paddingTop: "20px", // Added top padding for balance (optional)
        }}
      >
        <DatePickerField dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select available dates" required />
      </Box>

      <FooterBox onClick={createPatient} disabled={formIsDisabled} buttonText="Add Patient" loading={loading} sx={{ marginTop: "20px" }} />
    </Box>
  );
};

interface ButtonRowProps {
  selectedValue: string;
  onChange: (value: any) => void;
  options: { label: string; value: string }[];
}

const ButtonRow: React.FC<ButtonRowProps> = ({ selectedValue, onChange, options }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Box display="flex" gap="10px">
        {options.map((option) => (
          <Button
            key={option.value}
            onClick={() => onChange(option.value)}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: selectedValue === option.value ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: selectedValue === option.value ? "#fff" : Colors.info,
            }}
          >
            {option.label}
          </Button>
        ))}
      </Box>
    </FormControl>
  );
};

interface CustomAutocompleteWithLoadingProps {
  label: string;
  options: { label: string; value: string }[];
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  loading: boolean;
}

const CustomAutocompleteWithLoading: React.FC<CustomAutocompleteWithLoadingProps> = ({
  label,
  options,
  selectedItems,
  setSelectedItems,
  loading,
}) => {
  return (
    <React.Fragment>
      {loading && <LoadingWithMessage message={`Loading ${label.toLowerCase()}...`} size={10} />}
      {!loading && <CustomAutocomplete label={label} options={options} selectedItems={selectedItems} setSelectedItems={setSelectedItems} />}
    </React.Fragment>
  );
};

interface AddPatientModalProps {
  integration: EHR;
  onClick: () => void;
  onClose: () => void;
}

// TODO (Hizami): Finish implementing
export const AddPatientModal: React.FC<AddPatientModalProps> = ({ integration, onClick, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const { agentId } = useParams<{ agentId: string }>();
  const [timezone, setTimezone] = React.useState<string>(DateTime.local().zoneName);
  const [language, setLanguage] = React.useState<string>("en"); // TODO (Hizami): This is hard coded because we aren't supporting multiple languages right now
  const [availableDates, setAvailableDates] = React.useState<AvailableDate[]>([]);
  const [selectedPatient, setSelectedPatient] = React.useState<RawRaintreePatient | undefined>(undefined);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>([]);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>([]);
  const [textEnabled, setTextEnabled] = React.useState<boolean>(true);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(false);
  const [showPatientDetails, setShowPatientDetails] = useState(false);
  const [loading, setLoading] = useState(false);

  const getIntegration = (state: RootState) => {
    switch (integration) {
      case EHR.pteverywhere:
        return state.pteverywhereIntegration;
      case EHR.raintree:
        return state.raintreeIntegration;
      case EHR.heno:
        return state.henoIntegration;
      case EHR.ptpracticepro:
        return state.ptpracticeproIntegration;
      default:
        throw new Error("Invalid integration");
    }
  };

  const integrationVariables = (state: RootState) => {
    const integrationState = getIntegration(state);
    return {
      patientMap: integrationState.patientMap,
      providers: integrationState.providers,
      appointments: integrationState.appointments,
      patientSearches: integrationState.patientSearches,
      validPatientSearches: integrationState.validPatientSearches,
      invalidPatientSearches: integrationState.invalidPatientSearches,
      loadingPatients: integrationState.loadingPatients,
      loadingProviders: integrationState.loadingProviders,
      loadingAppointments: integrationState.loadingAppointments,
    };
  };

  let {
    patientMap,
    providers,
    appointments,
    patientSearches,
    validPatientSearches,
    invalidPatientSearches,
    loadingPatients,
    loadingProviders,
    loadingAppointments,
  } = useSelector(integrationVariables);

  const patients = Object.values(patientMap);

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">("text");

  const { showMessage } = useSnackbar();

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(undefined);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  const dispatch = useAppDispatch();

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const createPatient = async () => {
    if (!user?.token || !organization?.organizationId) {
      return;
    }
    setLoading(true);
    try {
      if (!selectedPatient) {
        return;
      }
      const patientId = uuidv4();
      const organizationId = organization.organizationId;

      const { firstName, lastName, dob } = selectedPatient;
      const phoneNumber = convertToCallablePhoneNumber(selectedPatient.phoneNumber);
      const patient: Patient = {
        patientId,
        organizationId,
        firstName,
        lastName,
        phoneNumber,
        dob,
        timezone,
        language,
        callEnabled,
        textEnabled,
        consentHistory: [],
      };

      const ehrPatientId = selectedPatient?.id;
      const ehrProviderIds = selectedProviders;
      const ehrAppointmentTypeIds = selectedAppointmentTypes;

      const waitlistPatient: WaitlistPatient = { patientId, organizationId, availableDates, patientGroupId, priority: 1 };
      const ehrPatient: EHRPatient = { patientId, organizationId, ehrPatientId, ehrProviderIds, ehrAppointmentTypeIds };
      const omniPatient: OmniPatient = { patientId: patientId, patient: patient, waitlist: waitlistPatient, [integration]: ehrPatient };

      const resultAction = await dispatch(createPatientThunk({ token: user.token, omniPatient }));

      if (createPatientThunk.rejected.match(resultAction)) {
        throw new Error(resultAction.payload?.message || "Failed to create patient");
      }

      onClick();
      onClose();
      setSelectedPatient(undefined);
      setAvailableDates([]);
    } catch (error: any) {
      // Handle any errors from the thunk if needed
      console.error("Error creating patient:", error);
      showMessage("Error creating patient: " + error.message, "error");
    }
    setLoading(false);
  };

  const contactModeOptions = [
    { label: "Call only", value: "call" },
    { label: "Text only", value: "text" },
    { label: "Both", value: "both" },
  ];

  const labelSortAlpha = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label);
  const getPatientLabel = (patient: RawRaintreePatient) => `${patient.firstName} ${patient.lastName} (ID: ${patient.id})`;

  const providerOptions = providers.map((provider) => ({ value: provider.id, label: provider.name })).sort(labelSortAlpha);
  const appointmentOptions = appointments.map((appointment) => ({ value: appointment.id, label: appointment.name })).sort(labelSortAlpha);
  const patientOptions = patients.map((patient) => ({ value: patient.id, label: getPatientLabel(patient) })).sort(labelSortAlpha);

  const handlePatientSelection = (patientId: string) => {
    if (patientId) {
      const patient = patients.find((patient) => patient.id === patientId);
      setSelectedPatient(patient);
      setShowPatientDetails(true);
      setTimezone(organization?.timezone || DateTime.local().zoneName);
    } else {
      setSelectedPatient(undefined);
      setShowPatientDetails(false);
    }
  };

  const handleCloseDetails = () => {
    setSelectedPatient(undefined);
    setShowPatientDetails(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent Backspace from deselecting the selected options
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  const [currentQuery, setCurrentQuery] = useState<string>("");
  const handleInputChange = (value: string) => {
    setCurrentQuery(value);
    const notSearched = !patientSearches.includes(value);
    const notSubsearched = !validPatientSearches.some((search) => value.includes(search));
    if (user?.token && notSearched && notSubsearched) {
      if (integration === EHR.raintree) {
        dispatch(fetchRaintreePatients({ token: user.token, keywords: value }));
      } else if (integration === EHR.heno) {
        dispatch(fetchHenoPatients({ token: user.token, keywords: value }));
      } else if (integration === EHR.ptpracticepro) {
        dispatch(fetchPtpracticeproPatients({ token: user.token, keywords: value }));
      } else if (integration === EHR.pteverywhere) {
        dispatch(fetchPteverywherePatients({ token: user.token, keywords: value }));
      }
    }
  };

  const currentQueryIsInvalid = invalidPatientSearches.includes(currentQuery);

  return (
    <Box sx={{ overflowY: "auto", height: "92%", paddingBottom: "40px", paddingRight: "20px" }}>
      <HeaderBox title="Add Patient" onClose={onClose} />
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"50px"}>
        <Typography margin={"10px 0 10px 0"} fontWeight={"bold"} fontSize={"1.2rem"}>
          Import patient
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" gap="10px" mt={2}>
        <Typography variant="body1">Patient</Typography>
        {currentQueryIsInvalid && (
          <Box display="flex" alignItems="center" gap="5px">
            <WarningIcon color="warning" sx={{ fontSize: "1rem" }} />
            <Typography variant="body2" color="#FCD554">
              Please enter a more specific query
            </Typography>
          </Box>
        )}
      </Box>

      {loadingPatients && <LoadingWithMessage message="Loading patients..." size={10} />}
      {showPatientDetails ? (
        <>
          {selectedPatient && (
            <Box
              sx={{
                padding: "10px",
                background: Colors.textfield,
                borderRadius: "10px",
                color: "#FFFFFF",
                marginTop: "5px",
                position: "relative",
                "&:hover .close-icon": {
                  visibility: "visible",
                },
              }}
            >
              <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
                {`${selectedPatient?.firstName ?? ""} ${selectedPatient?.lastName ?? ""}`}
              </Typography>
              <Box
                sx={{
                  color: Colors.info,
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  alignItems: { xs: "left", sm: "center" },
                  gap: "10px",
                }}
                mt={0.5}
              >
                <Typography variant="body2">{`${selectedPatient?.phoneNumber ?? ""}`}</Typography>
                <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
                <Typography variant="body2">{`ID: ${selectedPatient?.id ?? ""}`}</Typography>
              </Box>

              <IconButton
                className="close-icon"
                sx={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  color: "#FFF",
                  visibility: "hidden",
                  padding: "5px",
                  zIndex: 1,
                }}
                onClick={handleCloseDetails}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </>
      ) : (
        <Autocomplete
          options={patientOptions}
          getOptionLabel={(option) => `${option.label.split(" (ID:")[0]}   (ID: ${option.value})`}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.value}
              style={{ display: "flex", justifyContent: "space-between", background: Colors.bg3, color: "#FFF", padding: "8px 16px" }}
            >
              <span>{option.label.split(" (ID:")[0]}</span>
              <span style={{ color: Colors.info }}>{`ID: ${option.value}`}</span>
            </li>
          )}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Type patient name to load patients" />}
          value={patientOptions.find((option) => option.value === selectedPatient?.id) || null}
          onChange={(e, value) => handlePatientSelection(value?.value || "")}
          onInputChange={(e, value) => handleInputChange(value)}
          fullWidth
          sx={{ borderRadius: "10px", background: Colors.textfield, marginTop: "5px", color: Colors.coolWhite }}
        />
      )}

      <Typography variant="body1" mt={3} mb={1}>
        AI agent mode of contact
      </Typography>

      <ButtonRow selectedValue={contactMode} onChange={handleContactModeChange} options={contactModeOptions} />

      <Typography variant="body1" fontWeight={"bold"} mt={3}>
        Waitlist Parameters <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select value={patientGroupId} onChange={(e) => setPatientGroupId(e.target.value)}>
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <CustomAutocompleteWithLoading
        label="Providers"
        options={providerOptions}
        selectedItems={selectedProviders}
        setSelectedItems={setSelectedProviders}
        loading={loadingProviders}
      />

      <CustomAutocompleteWithLoading
        label="Appointment Types"
        options={appointmentOptions}
        selectedItems={selectedAppointmentTypes}
        setSelectedItems={setSelectedAppointmentTypes}
        loading={loadingAppointments}
      />

      <Box pb="20px" pt="20px">
        <DatePickerField dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select available dates" />
      </Box>

      <FooterBox onClick={createPatient} disabled={!selectedPatient || availableDates.length === 0} buttonText="Add Patient" loading={loading} />
    </Box>
  );
};

export default HealthieAddPatientModal;
