import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { HealthieAppointment, RawHealthiePatient, HealthieProvider } from "../types";
import { sessionExpired } from "./SessionSlice";

export type HenoProvider = {
  id: string;
  name: string;
};

export interface HenoIntegrationState {
  providers: HenoProvider[];
  appointments: HealthieAppointment[];
  contactTypes: string[];
  patientMap: { [henoPatientId: string]: RawHenoPatient };
  patientSearches: string[];
  validPatientSearches: string[];
  invalidPatientSearches: string[];
  error: string | null;
  loadingUploadingToken: boolean;
  loadingDeletingIntegration: boolean;
  loadingProviders: boolean;
  loadingAppointments: boolean;
  loadingPatients: boolean;
}

const initialState: HenoIntegrationState = {
  providers: [],
  appointments: [],
  contactTypes: ["In Person", "Healthie Video Call", "Secure Videochat", "Phone Call"],
  patientMap: {},
  patientSearches: [],
  validPatientSearches: [],
  invalidPatientSearches: [],
  error: null,
  loadingUploadingToken: false,
  loadingDeletingIntegration: false,
  loadingProviders: false,
  loadingAppointments: false,
  loadingPatients: false,
};

export const fetchHenoProviders = createAsyncThunk<{ providers: HenoProvider[] }, { token: string }, { rejectValue: Error }>(
  "/integrations/fetchProviders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/heno/providers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data) {
        throw new Error("No data received");
      }

      return { providers: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch providers: " + (error as Error).message));
    }
  }
);

export const fetchHenoAppointments = createAsyncThunk<{ appointments: HealthieAppointment[] }, { token: string }, { rejectValue: Error }>(
  "integrations/fetchAppointments",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/heno/appointmentTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { appointments: response.data as HealthieAppointment[] };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch appointments: " + (error as Error).message));
    }
  }
);

export type RawHenoPatient = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dob: string;
};

export const fetchHenoPatients = createAsyncThunk<{ patients: RawHenoPatient[] }, { token: string; keywords: string }, { rejectValue: Error }>(
  "/integrations/fetchPatients",
  async ({ token, keywords }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/heno/patients`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          keywords,
        },
      });

      if (!response.data) {
        throw new Error("No data received");
      }

      return { patients: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch patients: " + (error as Error).message));
    }
  }
);

const henoIntegrationSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    clearIntegration: (state) => {
      state.providers = [];
      state.appointments = [];
      state.error = null;
      state.loadingProviders = false;
      state.loadingAppointments = false;
      state.loadingPatients = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHenoProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchHenoProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchHenoProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      })
      .addCase(fetchHenoAppointments.pending, (state) => {
        state.loadingAppointments = true;
      })
      .addCase(fetchHenoAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
        state.loadingAppointments = false;
      })
      .addCase(fetchHenoAppointments.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointments";
        state.loadingAppointments = false;
      })
      .addCase(fetchHenoPatients.pending, (state, action) => {
        state.patientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = true;
      })
      .addCase(fetchHenoPatients.fulfilled, (state, action) => {
        action.payload.patients.forEach((patient) => {
          state.patientMap[patient.id] = patient;
        });
        state.validPatientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = false;
      })
      .addCase(fetchHenoPatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
        state.invalidPatientSearches.push(action.meta.arg.keywords);
      });
  },
});

export default henoIntegrationSlice.reducer;
