import { FunctionComponent, MouseEvent, MouseEventHandler, useMemo, useState } from "react";
import { CalendarProps } from "../Calendar";
import styles from "../Calendar.module.scss";
import leftArrowIcon from "../assets/left-arrow.svg";
import rightArrowIcon from "../assets/right-arrow.svg";
import { isWeekend } from "./helpers/dates";
import { useCalendarContext } from "./hooks/useCalendarContext";
import { CalendarType, TileDisabledFunc } from "./types";
import Flex from "./Flex";
import HoursViewGrid from "./HoursViewGrid";
import { formatOutputPeriod } from "./helpers/dateFormatters";
import useDeviceType from "./hooks/useDeviceType";
import { ContextMenuConfig } from "./ContextMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const getStartOfWeek = (calendarType: CalendarType) => {
  if (calendarType === "islamic") {
    return new Date().getDate() - new Date().getDay() - 1;
  }
  if (calendarType === "iso8601") {
    return new Date().getDate() - new Date().getDay() + 1;
  }

  return new Date().getDate() - new Date().getDay();
};

const renderWeekTitle = (startDay: Date, locale: string) => {
  const endDay = new Date(startDay);
  endDay.setDate(endDay.getDate() + 6);
  return `${startDay.toLocaleDateString(locale, {
    month: "short",
  })} ${startDay.toLocaleDateString(locale, {
    day: "numeric",
  })} - ${endDay.toLocaleDateString(locale, {
    month: "short",
  })} ${endDay.toLocaleDateString(locale, { day: "numeric" })}`;
};

const HoursView: FunctionComponent<CalendarProps & { updateExternalDates: () => void }> = (props) => {
  const {
    locale,
    activeStartDate: _activeStartDate,
    calendarType,
    onCancel,
    onDateTimesChange,
    disableGestureSelection,
    onSubmit,
    inline,
    updateExternalDates,
  } = props;

  const { organization } = useSelector((state: RootState) => state.auth);
  const gridStart = useMemo(() => {
    if (props.gridStart) {
      return props.gridStart;
    }
    const workingHoursStart = organization?.workingHoursStart ? new Date(organization.workingHoursStart) : null;
    return workingHoursStart?.getHours() || 6;
  }, [organization?.workingHoursStart, props.gridStart]);

  const {
    selectedDatesMap,
    setSelectedDatesMap,
    dayClipboardConfig,
    setDayClipboardConfig,
    weekClipboardConfig,
    setWeekClipboardConfig,
    timezone,
    showToast,
    setContextMenuConfig,
  } = useCalendarContext();

  const deviceType = useDeviceType();

  // Default active start date is the week of the current date, factoring in the locale's first day of the week
  const [activeStartDate, setActiveStartDate] = useState<Date>(
    _activeStartDate ||
      new Date(new Date().getFullYear(), new Date().getMonth(), getStartOfWeek(calendarType || "gregory"))
  );

  const handleWeekdayContextMenu = ({ event, date }: { event: MouseEvent; date: Date }) => {
    event.preventDefault();

    let location = {
      x: event.pageX,
      y: event.pageY,
    };

    if (deviceType === "mobile") {
      location = {
        x: (event as any).pageX - 10,
        y: (event as any).pageY - 120,
      };
    }

    const dayPeriods = selectedDatesMap.get(date.toDateString()) || [];

    setContextMenuConfig({
      options: [
        {
          label: "Copy times",
          action: () => {
            setDayClipboardConfig({
              day: date.toDateString(),
              times: dayPeriods,
            });
            showToast(
              `Times copied for ${date.toLocaleDateString(locale, {
                weekday: "long",
              })}`
            );
          },
          disabled: Boolean(!dayPeriods.length),
        },
        {
          label: "Paste times",
          action: () => {
            const newSelectedDatesMap = new Map(selectedDatesMap);
            newSelectedDatesMap.set(date.toDateString(), dayClipboardConfig?.times || []);
            setSelectedDatesMap(newSelectedDatesMap);
          },
          disabled: Boolean(!dayClipboardConfig),
        },
        {
          label: "Clear times",
          action: () => {
            const newSelectedDatesMap = new Map(selectedDatesMap);
            newSelectedDatesMap.delete(date.toDateString());
            setSelectedDatesMap(newSelectedDatesMap);
          },
          color: "#ff6955",
          disabled: Boolean(!dayPeriods.length),
        },
      ].filter(Boolean) as ContextMenuConfig["options"],
      location,
    });
  };

  const handleGeneralContextMenu = ({ event }: { event: MouseEvent }) => {
    event.preventDefault();
    event.stopPropagation();

    let location = {
      x: event.clientX,
      y: event.clientY,
    };

    if (deviceType === "mobile") {
      location = {
        x: (event as any).pageX - 10,
        y: (event as any).pageY - 120,
      };
    }

    const weekPeriodsArr = Array(7)
      .fill(null)
      .map((_, i) => {
        const date = new Date(activeStartDate);
        date.setDate(date.getDate() + i);

        const dayPeriods = selectedDatesMap.get(date.toDateString());
        return dayPeriods || [];
      });

    setContextMenuConfig({
      options: [
        {
          label: "Copy week times",
          action: () => {
            setWeekClipboardConfig(weekPeriodsArr as Exclude<typeof weekClipboardConfig, null>);
            showToast("Times copied for the week");
          },
          disabled: Boolean(!weekPeriodsArr.flat().length),
        },
        {
          label: "Paste week times",
          action: () => {
            const newSelectedDatesMap = new Map(selectedDatesMap);
            weekClipboardConfig?.forEach((dayPeriods, index) => {
              const date = new Date(activeStartDate);
              date.setDate(date.getDate() + index);
              if (dayPeriods.length) {
                newSelectedDatesMap.set(date.toDateString(), dayPeriods);
              } else {
                newSelectedDatesMap.delete(date.toDateString());
              }
            });
            setSelectedDatesMap(newSelectedDatesMap);
          },
          disabled: Boolean(!weekClipboardConfig),
        },
        {
          label: "Clear week times",
          action: () => {
            const newSelectedDatesMap = new Map(selectedDatesMap);
            Array(7)
              .fill(null)
              .forEach((_, index) => {
                const date = new Date(activeStartDate);
                date.setDate(date.getDate() + index);
                newSelectedDatesMap.delete(date.toDateString());
              });
            setSelectedDatesMap(newSelectedDatesMap);
          },
          color: "#ff6955",
          disabled: Boolean(!weekPeriodsArr.flat().length),
        },
      ].filter(Boolean) as ContextMenuConfig["options"],
      location,
      direction: "up",
    });
  };

  return (
    <div>
      <div className={styles.navigation}>
        <button
          onClick={() =>
            setActiveStartDate(
              new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), activeStartDate.getDate() - 7)
            )
          }
          className={styles["navigation-button"]}
        >
          <img src={leftArrowIcon} alt="Previous" />
        </button>
        {renderWeekTitle(activeStartDate, locale || "en-US")}
        <button
          onClick={() =>
            setActiveStartDate(
              new Date(activeStartDate.getFullYear(), activeStartDate.getMonth(), activeStartDate.getDate() + 7)
            )
          }
          className={styles["navigation-button"]}
        >
          <img src={rightArrowIcon} alt="Next" />
        </button>
      </div>

      <div className={styles["calendar-body"]}>
        <Flex className={styles.weekdays} count={7} data-testid="calendar-weekdays">
          {Array(7)
            .fill(null)
            .map((_, i) => {
              const date = new Date(activeStartDate);
              date.setDate(date.getDate() + i);
              return (
                <HoursViewDay
                  date={date}
                  locale={locale || "en-US"}
                  calendarType={calendarType || "gregory"}
                  onContextMenu={(event) => handleWeekdayContextMenu({ event, date })}
                  activeStartDate={activeStartDate}
                  shouldDisableDate={props.shouldDisableDate}
                  key={i}
                />
              );
            })}
        </Flex>
      </div>

      <HoursViewGrid
        timeMode={props.timeMode || "12"}
        gridStart={gridStart}
        activeStartDate={activeStartDate}
        disableGestureSelection={disableGestureSelection}
        shouldDisableDate={props.shouldDisableDate}
      />

      <div className={styles.buttons}>
        <button
          className={styles.dots}
          type="button"
          onClick={(event) => handleGeneralContextMenu({ event })}
          aria-label="Open context menu"
        >
          <span className={styles.dot} />
          <span className={styles.dot} />
          <span className={styles.dot} />
        </button>
        {!inline && (
          <div className={styles["ok-cancel"]}>
            <button className={styles.cancel} type="button" onClick={onCancel}>
              Cancel
            </button>
          <button
            className={[styles.ok, !selectedDatesMap.size && styles.disabled].join(" ")}
            disabled={!selectedDatesMap.size}
            type="button"
            onClick={updateExternalDates}
          >
            Update Dates
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

const HoursViewDay = ({
  date,
  locale,
  calendarType,
  onContextMenu,
  shouldDisableDate,
  activeStartDate,
}: {
  date: Date;
  locale: string;
  calendarType: CalendarType;
  onContextMenu: MouseEventHandler;
  shouldDisableDate?: TileDisabledFunc;
  activeStartDate: Date;
}) => {
  const { selectedDatesMap, setSelectedDatesMap } = useCalendarContext();

  return (
    <button
      className={[styles.weekday, isWeekend(date, calendarType) ? styles.weekend : ""].join(" ")}
      onClick={() => {
        const newSelectedDatesMap = new Map(selectedDatesMap);
        if (selectedDatesMap.has(date.toDateString())) {
          newSelectedDatesMap.delete(date.toDateString());
        } else {
          newSelectedDatesMap.set(date.toDateString(), [{ start: "00:00", end: "24:00" }]);
        }
        setSelectedDatesMap(newSelectedDatesMap);
      }}
      disabled={shouldDisableDate?.({ date, activeStartDate })}
      onContextMenu={onContextMenu}
    >
      <abbr
        className={[
          styles["hours-view-weekday"],
          selectedDatesMap.has(date.toDateString()) ? styles.selected : "",
          shouldDisableDate?.({ date, activeStartDate }) ? styles.disabled : "",
          date.toDateString() === new Date().toDateString() ? styles.today : "",
        ].join(" ")}
      >
        <span
          className={styles["hours-view-weekday-title"]}
          title={date.toLocaleDateString(locale, { weekday: "narrow" })}
        >
          {date.toLocaleDateString(locale, { weekday: "narrow" })}
        </span>
        <span className={styles["hours-view-weekday-date"]}>{date.toLocaleDateString(locale, { day: "numeric" })}</span>
      </abbr>
    </button>
  );
};

export default HoursView;
