import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Tooltip,
  Switch,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Select,
  MenuItem,
  TablePagination,
  TableFooter,
  Button,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChatIcon from "@mui/icons-material/Chat";
import { Webhook, FrontendUser, LogType } from "../types";
import { RootState, useAppDispatch } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../subcomponents/CustomButton";
import {
  clearWebhooks,
  refreshWebhook,
  addWebhook,
  fetchWebhooks,
  deleteWebhook,
  toggleWebhookActivity,
  toggleWebhookRecording,
} from "../slices/WebhookSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { api } from "../utils/utils";
import { Colors } from "../Colors";
import { DateTime } from "luxon";
import { LogItem } from "../types";
import { Link } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import axios from "axios";

const Logs: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<LogItem[]>([]);

  const [filter, setFilter] = useState<LogType[]>(Object.values(LogType));

  const [filteredLogs, setFilteredLogs] = useState<LogItem[]>([]);
  const [paginationLogs, setPaginationLogs] = useState<LogItem[]>([]);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string>("");

  const [endOfLogs, setEndOfLogs] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const fetchLogs = async () => {
    if (!user || !user.token) {
      return;
    }

    setLoading(true);
    const response = await api.get(`/log-items?last_evaluated_key=${lastEvaluatedKey}`, user.token);
    console.log(response);
    setLogs(prevLogs => {
      const newLogs = [...response.data.items];
      const uniqueLogs = [...prevLogs];
      
      newLogs.forEach(newLog => {
        if (!uniqueLogs.some(existingLog => existingLog.logItemId === newLog.logItemId)) {
          uniqueLogs.push(newLog);
        }
      });
      
      return uniqueLogs;
    });
    setLastEvaluatedKey(JSON.stringify(response.data.lastEvaluatedKey));
    if (!response.data.lastEvaluatedKey) {
      setEndOfLogs(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchLogs();
  }, [user?.token]);

  useEffect(() => {
    const prev_length = filteredLogs.length;
    const filtered = logs.filter((log) => filter.includes(log.logType));
    setFilteredLogs(filtered);
  }, [logs, filter]);

  useEffect(() => {
    setPage(0);
  }, [filter]);


  useEffect(() => {
    setPaginationLogs(filteredLogs.slice(page * rowsPerPage, (page + 1) * rowsPerPage));
  }, [filteredLogs, page, rowsPerPage]);

  const handleCheckboxChange = async (log: LogItem) => {
    if (!user?.token) return;

    setLogs(prevLogs => 
      prevLogs.map(prevLog => 
        prevLog.logItemId === log.logItemId 
          ? { ...prevLog, completed: !prevLog.completed }
          : prevLog
      )
    );

    setFilteredLogs(prevLogs => 
      prevLogs.map(prevLog => 
        prevLog.logItemId === log.logItemId 
          ? { ...prevLog, completed: !prevLog.completed }
          : prevLog
      )
    );

    setPaginationLogs(prevLogs => 
      prevLogs.map(prevLog => 
        prevLog.logItemId === log.logItemId 
          ? { ...prevLog, completed: !prevLog.completed }
          : prevLog
      )
    );

    await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/log-items`, { logItemId: log.logItemId, completed: !log.completed }, { headers: { Authorization: `Bearer ${user?.token}` } })
    .catch((error) => {
      console.log(error);
    });
  };

  return (
    <Box
      sx={{
        marginTop: { xs: "75px", sm: 0, color: "#FFF" },
        "& .MuiOutlinedInput-root": { color: "#FFFFFF" },
        "& .MuiInputLabel-root": { color: Colors.info },
        "& .MuiInputBase-input": { color: "#FFFFFF" },
        "& .MuiSvgIcon-root": { color: "#FFF" },
        "& .MuiAutocomplete-paper": { backgroundColor: Colors.bg3, margin: 0 },
        "& .MuiAutocomplete-listbox": { backgroundColor: Colors.bg3 },
        "& .MuiTypography-body1": { color: "#FFF" },
        "& .MuiTypography-subtitle2": { color: "#FFF" },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <Typography variant="h6">Log Items</Typography>
      </Box>
      {loading && <LoadingWithMessage message="Fetching logs..." size={60} />}
      {logs.length === 0 && !loading && <Typography variant="body1">No logs found.</Typography>}
      {logs.length > 0 && !loading && (
        <>
        <Select
          multiple
          value={filter}
          onChange={(event) => {
            const value = event.target.value;
            setFilter(value as LogType[]);
          }}
          input={<OutlinedInput sx={{ border: "1.5px solid #FFF" }} />}
          renderValue={() => "Filter"}

        >
          {Object.values(LogType).map((logType) => (
            <MenuItem key={logType} value={logType}>
              <Checkbox checked={filter.includes(logType)} />
              <ListItemText primary={logType} />
              </MenuItem>
            ))}
          </Select>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: "150px" }}>
                  <Typography variant="subtitle2" sx={{ fontSize: "1.2rem", fontWeight: 600 }}>Time</Typography>
                </TableCell>
                <TableCell sx={{ width: "100px" }}>
                  <Typography variant="subtitle2" sx={{ fontSize: "1.2rem", fontWeight: 600 }}>Link</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle2" sx={{ fontSize: "1.2rem", fontWeight: 600 }}>Description</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginationLogs.map((log, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Typography>{log.createdAt ? DateTime.fromISO(log.createdAt).toFormat("MM/dd HH:mm") : "UNKNOWN TIME"}</Typography>
                  </TableCell>
                  <TableCell>
                    <Link to={`/dashboard/patients/${log.patientId}`} style={{ textDecoration: "none" }}>
                      <Tooltip title="View Chat">
                        <IconButton>
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                      {log.logType === LogType.actionItem && <Checkbox checked={log.completed} onChange={() => handleCheckboxChange(log)} />}
                      {log.logDescription}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow sx={{ backgroundColor: Colors.bg3 }}>
                <TableCell colSpan={3}>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'space-between',
                    width: '100%'
                  }}>
                    <PrimaryButton 
                      onClick={fetchLogs}
                      sx={{ minWidth: '150px' }}
                      disabled={endOfLogs}
                    >
                      Fetch More Data
                    </PrimaryButton>
                    <TablePagination
                      component="div"
                      sx={{ 
                        color: "#FFF",
                        '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                          color: '#FFF'
                        },
                        '.MuiTablePagination-select': {
                          color: '#FFF'
                        }
                      }}
                      count={filteredLogs.length}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 20]}
                      onRowsPerPageChange={(event) => {
                        setRowsPerPage(parseInt(event.target.value));
                        setPage(0);
                      }}
                      page={page}
                      onPageChange={(event, newPage) => {
                        setPage(newPage);
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </>
      )}
    </Box>
  );
};

export default Logs;
