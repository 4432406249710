import { useState, ReactNode, useMemo, useRef, useEffect } from "react";
import styles from "./Select.module.scss";
import chevronDown from "../../assets/down-arrow.svg";
import chevronDownBlue from "../../assets/down-arrow-blue.svg";
import checkBlue from "../../assets/check-blue.svg";
import { SelectOption, SelectOptionValue } from "../../utils/types";

export interface SelectProps {
  value: SelectOptionValue | SelectOptionValue[];
  onChange: (value: SelectOptionValue | SelectOptionValue[]) => void;
  options: SelectOption[];
  multiple?: boolean;
  placeholder?: string;
  className?: string;
  responsive?: boolean;
  disabled?: boolean;
  shouldSearchOptions?: boolean;
  dropdownOnTop?: boolean;
}

const Select = ({
  options,
  value,
  onChange,
  placeholder,
  multiple,
  className,
  responsive,
  disabled,
  shouldSearchOptions,
  dropdownOnTop,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSet, setSelectedSet] = useState<Set<string | number>>(
    new Set()
  );
  const [searchStr, setSearchStr] = useState("");

  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (shouldSearchOptions && isOpen && searchInputRef.current) {
      searchInputRef.current.focus();
    }
    const handleClick = (event: MouseEvent) => {
      if (
        isOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest(`.${styles.selected}`)
      ) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener("click", handleClick);
    }
    return () => document.removeEventListener("click", handleClick);
  }, [isOpen, shouldSearchOptions]);

  useEffect(() => {
    setSelectedSet(
      new Set(Array.isArray(value) ? value : [value].filter(Boolean))
    );
  }, [value]);

  const filteredOptions = useMemo(() => {
    if (
      !shouldSearchOptions ||
      !searchStr ||
      typeof options[0]?.label !== "string"
    ) {
      return options;
    }
    const regex = new RegExp(searchStr, "i");
    return options.filter(
      (option) =>
        selectedSet.has(option.value) || regex.test(String(option.label))
    );
  }, [options, searchStr, shouldSearchOptions, selectedSet]);

  const displayValue = useMemo(() => {
    if (!selectedSet.size) {
      return placeholder || "";
    }
    if (multiple) {
      return options
        .filter((option) => selectedSet.has(option.value as string | number))
        .map((option) => option.label)
        .join(", ");
    }
    const option = options.find((option) =>
      selectedSet.has(option.value as string | number)
    );
    return option?.label || value || placeholder || "";
  }, [value, multiple, options, placeholder, selectedSet]);

  const handleOptionClick = (option: SelectOption) => {
    if (multiple) {
      const newValues = selectedSet.has(option.value)
        ? (value as SelectOptionValue[]).filter(
            (value) => value !== option.value
          )
        : [...(value as SelectOptionValue[]), option.value];
      onChange(newValues);
    } else {
      setIsOpen(false);
      onChange(option.value);
    }
  };

  const handleSelectedClick = (e: React.MouseEvent) => {
    if (!disabled) {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={[
        styles.select,
        isOpen ? styles.open : "",
        responsive ? styles.responsive : "",
        className,
      ].join(" ")}
      ref={dropdownRef}
    >
      <div
        className={[
          styles.selected,
          isOpen ? styles.open : "",
          !selectedSet.size ? styles.placeholder : "",
          disabled ? styles.disabled : "",
        ].join(" ")}
        onClick={handleSelectedClick}
      >
        {shouldSearchOptions && isOpen ? (
          <input
            type="text"
            value={searchStr}
            onChange={(e) => setSearchStr(e.target.value)}
            placeholder={placeholder}
            className={styles["search-input"]}
            ref={searchInputRef}
            onClick={(e) => e.stopPropagation()}
          />
        ) : (
          <span>{displayValue}</span>
        )}

        <img
          className="app-icon"
          src={isOpen ? chevronDownBlue : chevronDown}
          alt="chevron-down"
        />
      </div>
      <div
        className={[
          styles.dropdown,
          isOpen ? styles.open : "",
          dropdownOnTop ? styles["on-top"] : "",
        ].join(" ")}
      >
        {filteredOptions.map((option) => (
          <button
            key={option.value.toString()}
            className={styles.option}
            onClick={!disabled ? () => handleOptionClick(option) : undefined}
            aria-label={typeof option.label === "string" ? option.label : ""}
          >
            <span>{option.label}</span>
            {selectedSet.has(option.value as string | number) && (
              <img className="app-icon small" src={checkBlue} alt="check" />
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Select;
