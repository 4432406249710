import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Input,
  Menu,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
  Typography,
  Button,
  CircularProgress,
  Paper,
} from "@mui/material";
import styled from "styled-components";
import { Form, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { convertToCallablePhoneNumber, formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import {
  AvailableDate,
  EHR,
  FrontendUser,
  OmniPatient,
  Organization,
  PartialOmniPatient,
  Patient,
  RawHealthiePatient,
  RawWebptPatient,
  WaitlistPatient,
  WebptPatient,
} from "../types";
import { PrimaryButton, SecondaryButton } from "../subcomponents/CustomButton";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import CloseIcon from "@mui/icons-material/Close";
import CircleIcon from "@mui/icons-material/Circle";
import { Colors } from "../Colors";
import { updatePatient } from "../slices/PatientSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import CustomDatePicker from "../components/date-picker/DatePicker";
import Footer from "../subcomponents/Footer";
import { FooterBox, HeaderBox } from "../subcomponents/SidebarComponents";

import { v4 as uuidv4 } from "uuid";
import { dropdownStyles, menuPropsStyles } from "../styles/GeneralStyles";
import { first } from "lodash";
import { updatePatient as updatePatientThunk } from "../slices/PatientSlice";
import CustomAutocomplete from "../components/CustomAutocomplete";
import DatePickerField from "../subcomponents/DatePickerField";
import { RawRaintreePatient } from "../slices/RaintreeIntegrationSlice";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin: 10% auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 70%;
    padding: 5%;
  }
`;

const ModalScrollBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 10% auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  max-height: 70%;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 70%;
    padding: 5%;
  }
`;

const timezones = moment.tz.names();

const timezoneOptions = timezones.map((tz) => {
  const dt = DateTime.now().setZone(tz);
  const offsetString = dt.toFormat("ZZ");
  const text = `${tz} (UTC${offsetString})`;

  return { value: tz, label: text, offset: dt.offset };
});

timezoneOptions.sort((a, b) => a.offset - b.offset);

const contactOptions = [
  { value: "call", label: "Call" },
  { value: "text", label: "Text" },
];

const languageOptions = [
  { label: "English", value: "en" },
  /*
  { label: "Spanish", value: "es" },
  { label: "French", value: "fr" },
  { label: "German", value: "de" },
  { label: "Chinese", value: "zh" },
   */
  // TODO (Hizami): Figure out what languages we want
];

interface SettingsNumberInputProps {
  disabled: boolean;
  value: number | null;
  setter: (value: number | null) => void;
}

const SettingsNumberInput: React.FC<SettingsNumberInputProps> = ({ disabled, value, setter }) => {
  return (
    <TextField
      type="number"
      value={value || ""}
      onChange={(e) => setter(parseInt(e.target.value))}
      variant="outlined"
      size="small"
      disabled={disabled}
      sx={{
        width: "45px",
        margin: "0 8px",
        "& .MuiInputBase-input": {
          color: "#FFF",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          background: Colors.textfield,
        },
        "& .MuiOutlinedInput-input": {
          padding: "2px",
          textAlign: "center",
        },
        "& input[type=number]": {
          "-moz-appearance": "textfield", // Firefox
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none", // Chrome, Safari, Edge, Opera
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none", // Chrome, Safari, Edge, Opera
            margin: 0,
          },
        },
      }}
    />
  );
};

type UpdateHealthiePatientModalProps = {
  patient: OmniPatient;
  open: boolean;
  onClose: () => void;
};

const UpdateHealthiePatientModal: React.FC<UpdateHealthiePatientModalProps> = ({ patient, open, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const healthiePatients = Object.values(useSelector((state: RootState) => state.healthieIntegration.patientMap));
  const { agentId } = useParams<{ agentId: string }>();
  const [timezone, setTimezone] = React.useState<string>(patient.patient.timezone);
  const [language, setLanguage] = React.useState<string>(patient.patient.language);

  const [textEnabled, setTextEnabled] = React.useState<boolean>(patient.patient.textEnabled);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(patient.patient.callEnabled);

  const [availableDates, setAvailableDates] = React.useState<AvailableDate[]>(patient.waitlist.availableDates); // Using Date instead of DateTime for compatibility with CustomDatePicker
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(patient[EHR.healthie]?.providerIds || []);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>(patient[EHR.healthie]?.appointmentTypeIds || []);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>(patient[EHR.healthie]?.contactTypes || []);

  const loadingAppointents = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);
  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const loadingHealthiePatients = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);

  const loadingFetchWaitlistPatients: boolean = useSelector((state: RootState) => state.patients.loadingFetchPatients);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);
  const loadingUpdatePriorities: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePriorities);
  const loadingCreatePatient: boolean = useSelector((state: RootState) => state.patients.loadingCreatePatient);
  const loadingGetPatient: boolean = useSelector((state: RootState) => state.patients.loadingGetPatient);

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [removalWindow, setRemovalWindow] = useState<number | null>(patient[EHR.healthie]?.removalWindow || null);

  const dispatch = useAppDispatch();
  let providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const healthieProviderIds = organization?.healthieProviderIds || [];
  if (healthieProviderIds && healthieProviderIds.length > 0) {
    providers = providers.filter((provider) => healthieProviderIds.includes(provider.id));
  }
  let appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const healthieAppointmentTypeIds = organization?.healthieAppointmentTypeIds || [];
  if (healthieAppointmentTypeIds && healthieAppointmentTypeIds.length > 0) {
    appointments = appointments.filter((appointment) => healthieAppointmentTypeIds.includes(appointment.id));
  }
  const contactTypes = organization?.healthieContactTypes || ["In Person", "Healthie Video Call", "Phone Call"]; // TODO (Hizami): This is hard-coded from healthie

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(patient.waitlist.patientGroupId);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const handleSelectAllProviders = () => {
    setSelectedProviders(providers.map((provider) => provider.id));
  };

  const handleDeselectAllProviders = () => {
    setSelectedProviders([]);
  };

  const handleSelectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes(appointments.map((appointment) => appointment.id));
  };

  const handleDeselectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes([]);
  };

  const handleSelectAllContactTypes = () => {
    setSelectedContactTypes(contactTypes);
  };

  const handleDeselectAllContactTypes = () => {
    setSelectedContactTypes([]);
  };

  useEffect(() => {
    if (patient[EHR.healthie]?.providerIds) {
      setSelectedProviders(patient[EHR.healthie].providerIds);
    }
  }, [patient[EHR.healthie]?.providerIds]);

  useEffect(() => {
    if (patient[EHR.healthie]?.appointmentTypeIds) {
      setSelectedAppointmentTypes(patient[EHR.healthie].appointmentTypeIds);
    }
  }, [patient[EHR.healthie]?.appointmentTypeIds]);

  useEffect(() => {
    if (patient[EHR.healthie]?.contactTypes) {
      setSelectedContactTypes(patient[EHR.healthie].contactTypes);
    }
  }, [patient[EHR.healthie]?.contactTypes]);

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const providerOptions = providers
    .map((provider) => ({
      value: provider.id,
      label: provider.firstName + " " + provider.lastName,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const appointmentOptions = appointments
    .map((appointment) => ({ value: appointment.id, label: appointment.name }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const contactTypeOptions = contactTypes.map((contactType) => ({
    value: contactType,
    label: contactType,
  }));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent Backspace from deselecting the selected options
    if (event.key === "Backspace") {
      event.stopPropagation();
    }
  };

  return (
    // <Modal open={open} onClose={onClose}>
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "40px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title={"Update Patient"} onClose={onClose} />

      <Typography variant="body1" marginTop={"30px"}>
        Patient
      </Typography>
      <Box
        sx={{
          padding: "10px",
          background: Colors.textfield,
          borderRadius: "10px",
          color: "#FFFFFF",
          marginTop: "5px",
          position: "relative",
          "&:hover .close-icon": {
            visibility: "visible",
          },
        }}
      >
        <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
          {`${patient.patient.firstName ?? ""} ${patient.patient.lastName ?? ""}`}
        </Typography>
        <Box color={Colors.info} display="flex" alignItems="center" gap="10px" mt={0.5}>
          <Typography variant="body2">{`${patient.patient.phoneNumber ?? ""}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px" }} />
          <Typography variant="body2">{`ID: ${patient[EHR.healthie]?.healthiePatientId ?? ""}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px" }} />
          <Typography variant="body2">{`${patient.patient.timezone ?? ""}`}</Typography>
        </Box>
      </Box>

      <Typography variant="body1" mt={3} mb={1}>
        AI Agent Mode of Contact
      </Typography>
      <FormControl variant="outlined" fullWidth>
        <Box display="flex" gap="10px">
          <Button
            onClick={() => handleContactModeChange("call")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: callEnabled && !textEnabled ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: callEnabled && !textEnabled ? "#FFFFFF" : Colors.info,
            }}
          >
            Call Only
          </Button>
          <Button
            onClick={() => handleContactModeChange("text")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: textEnabled && !callEnabled ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: textEnabled && !callEnabled ? "#FFFFFF" : Colors.info,
            }}
          >
            Text Only
          </Button>
          <Button
            onClick={() => handleContactModeChange("both")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: textEnabled && callEnabled ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: textEnabled && callEnabled ? "#FFFFFF" : Colors.info,
            }}
          >
            Both
          </Button>
        </Box>
      </FormControl>

      <Typography variant="body1" fontWeight="bold" mt={3}>
        Waitlist Parameters <HelpTooltip title="The patient will automatically be put on waitlists for appointments fitting these criteria." />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select
            value={patientGroupId}
            onChange={(e) => setPatientGroupId(e.target.value)}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {loadingProviders && <LoadingWithMessage message="Loading providers..." size={30} />}
      {!loadingProviders && (
        <CustomAutocomplete label={"Providers"} options={providerOptions} selectedItems={selectedProviders} setSelectedItems={setSelectedProviders} />
      )}
      {!loadingProviders && providers.length === 0 && (
        <Typography variant="body2" color="error" mt={1}>
          No providers found. Please check Healthie.
        </Typography>
      )}

      {loadingAppointents && <LoadingWithMessage message="Loading appointment types..." size={30} />}
      {!loadingAppointents && (
        <CustomAutocomplete
          label={"Appointment Types"}
          options={appointmentOptions}
          selectedItems={selectedAppointmentTypes}
          setSelectedItems={setSelectedAppointmentTypes}
        />
      )}
      {!loadingAppointents && appointments.length === 0 && (
        <Typography variant="body2" color="error" mt={1}>
          No appointment types found. Please check Healthie.
        </Typography>
      )}

      <FormControl fullWidth>
        <Box display="flex" alignItems="center" gap="10px" mt={2}>
          <Typography variant="body1">Contact Types</Typography>
          {selectedContactTypes?.length === contactTypeOptions?.length ? (
            <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllContactTypes}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={handleSelectAllContactTypes}>
              Select All
            </Button>
          )}
        </Box>
        <Select
          multiple
          displayEmpty
          value={selectedContactTypes}
          onChange={(e) => setSelectedContactTypes(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return selected.join(", ");
          }}
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            marginTop: "5px",
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
            "& .MuiSvgIcon-root": {
              color: "#FFF",
            },
          }}
        >
          {contactTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedContactTypes.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <DatePickerField dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />

      <Button sx={{ textTransform: "none", marginTop: "20px" }} onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
        {showAdvancedOptions ? "Hide advanced options" : "Show advanced options"}
      </Button>

      {showAdvancedOptions && (
        <Box ml={2}>
          <Typography variant="body1">
            Remove patient from waitlist
            <SettingsNumberInput disabled={false} value={removalWindow} setter={setRemovalWindow} />
            surrounding days after appointment is booked.
          </Typography>
        </Box>
      )}

      <FooterBox
        onClick={async () => {
          if (!user?.token) {
            return;
          }

          const updatedPatient: PartialOmniPatient = {
            patientId: patient?.patientId,
            patient: {
              timezone,
              language,
              textEnabled,
              callEnabled,
            },
            waitlist: {
              availableDates: availableDates,
              patientGroupId,
            },
            [EHR.healthie]: {
              providerIds: selectedProviders,
              appointmentTypeIds: selectedAppointmentTypes,
              contactTypes: selectedContactTypes,
              removalWindow: removalWindow || undefined,
            },
          };

          if (patient?.patientId) {
            await dispatch(
              updatePatient({
                token: user.token,
                patient: updatedPatient,
              })
            );
            onClose();
          }
        }}
        buttonText={"Update Patient"}
        loading={loadingUpdatePatient}
      />
    </Box>
  );
};

interface WebptUpdatePatientModalProps {
  patient: OmniPatient;
  open: boolean;
  onClose: () => void;
}

export const UpdateWebptPatientModal: React.FC<WebptUpdatePatientModalProps> = ({ patient, open, onClose }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const webptPatients = useSelector((state: RootState) => state.webptIntegration.patients);
  const { agentId } = useParams<{ agentId: string }>();
  const [availableDates, setAvailableDates] = React.useState<AvailableDate[]>(patient.waitlist.availableDates);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(patient[EHR.webpt]?.webptProviderIds || []);
  const [textEnabled, setTextEnabled] = React.useState<boolean>(patient.patient.textEnabled);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(patient.patient.callEnabled);
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState<number>(patient[EHR.webpt]?.webptAppointmentDuration || 30); // Appointment duration in minutes
  const [caseStudies, setCaseStudies] = useState<any[]>([]); // Store case studies for the selected patient
  const [selectedCaseStudy, setSelectedCaseStudy] = React.useState<number | undefined>(patient[EHR.webpt]?.webptCaseStudyId);

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [removalWindow, setRemovalWindow] = useState<number | null>(patient[EHR.webpt]?.removalWindow || null);

  const providers = useSelector((state: RootState) => state.webptIntegration.providers);
  const providerOptions = providers.map((provider) => ({
    value: provider.id,
    label: provider.name,
  }));

  //const loadingHealthiePatients: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingPatients);

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">(textEnabled ? (callEnabled ? "both" : "text") : "call");

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(undefined);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  async function fetchCaseStudies() {
    if (user?.token && patient[EHR.webpt]?.webptPatientId) {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/webpt/patients/${patient[EHR.webpt].webptPatientId}?organizationId=${user.organizationId}`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          params: {
            firstName: patient.patient.firstName,
            lastName: patient.patient.lastName,
          },
        }
      );
      const studies = response.data as any[];
      setCaseStudies(studies);
    }
  }

  useEffect(() => {
    fetchCaseStudies();
  }, [user?.token, patient[EHR.webpt]?.webptPatientId]);

  const dispatch = useAppDispatch();

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
  };

  const updatePatient = async () => {
    if (!user?.token || !organization?.organizationId) {
      return;
    }
    if (!selectedCaseStudy) {
      return;
    }
    setLoading(true);

    try {
      const patientId = patient.patientId;
      const organizationId = organization.organizationId;

      const basePatient: Partial<Patient> = {
        patientId,
        callEnabled: callEnabled,
        textEnabled: textEnabled,
      };

      const waitlistPatient: Partial<WaitlistPatient> = {
        patientId,
        availableDates: availableDates,
        patientGroupId,
      };

      const webptPatient: Partial<WebptPatient> = {
        patientId,
        webptPatientId: patient[EHR.webpt]?.webptPatientId,
        webptProviderIds: selectedProviders,
        webptAppointmentDuration: duration,
        webptCaseStudyId: selectedCaseStudy,
        removalWindow: removalWindow || undefined,
      };

      const omniPatient: PartialOmniPatient = {
        patientId: patientId,
        patient: basePatient,
        waitlist: waitlistPatient,
        [EHR.webpt]: webptPatient,
      };

      await dispatch(
        updatePatientThunk({
          token: user.token,
          patient: omniPatient,
        })
      );
      onClose();
      setAvailableDates([]);
    } catch (error) {
      // Handle any errors from the thunk if needed
      console.error("Error creating patient:", error);
    }
    setLoading(false);
  };

  const webptPatientOptions = webptPatients.map((patient: RawWebptPatient) => ({
    value: patient.webptPatientId,
    label: `${patient.firstName} ${patient.lastName} (ID: ${patient.webptPatientId})`,
  }));

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const caseStudyOptions = caseStudies.map((study) => {
    const value = study.CaseID;
    const title = study.Title;

    const reason = title.split(" - ")[2]; // Title is of the form {Name} - {Birthday} - ({Reason})
    const label = reason.replace(/[()]/g, "").trim();
    return {
      value: value as number,
      label: label as string,
    };
  });

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
        paddingBottom: "60px",
        paddingRight: "20px",
      }}
    >
      <HeaderBox title="Update Patient" onClose={onClose} />

      <Typography variant="body1">Patient</Typography>
      {/*loadingHealthiePatients && <LoadingWithMessage message="Loading patients..." size={10} />*/}
      <Box
        sx={{
          padding: "10px",
          background: Colors.textfield,
          borderRadius: "10px",
          color: "#FFFFFF",
          marginTop: "5px",
          position: "relative",
          "&:hover .close-icon": {
            visibility: "visible",
          },
        }}
      >
        <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
          {`${patient.patient.firstName ?? ""} ${patient.patient.lastName ?? ""}`}
        </Typography>
        <Box
          sx={{
            color: Colors.info,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "left", sm: "center" },
            gap: "10px",
          }}
          mt={0.5}
        >
          <Typography variant="body2">{`${patient.patient.phoneNumber}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
          <Typography variant="body2">{`ID: ${patient[EHR.webpt]?.webptPatientId ?? ""}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px", display: { xs: "none", sm: "block" } }} />
          <Typography variant="body2">{`${patient.patient.timezone}`}</Typography>
        </Box>
      </Box>

      {/*<Typography variant="body1" mt={3} mb={1}>
        Phone Number
      </Typography>
      <TextField
        variant="outlined"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        placeholder="Enter phone number"
        fullWidth
        sx={{
          borderRadius: "10px",
          background: Colors.textfield,
          "& .MuiInputBase-input": {
            padding: "15px",
            fontSize: "0.9rem",
            color: "#FFFFFF",
          },
        }}
      />*/}

      <Typography variant="body1" mt={3} mb={1}>
        AI agent mode of contact
      </Typography>

      <FormControl variant="outlined" fullWidth>
        <Box display="flex" gap="10px">
          <Button
            onClick={() => handleContactModeChange("call")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "call" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "call" ? "#fff" : Colors.info,
            }}
          >
            Call only
          </Button>

          <Button
            onClick={() => handleContactModeChange("text")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "text" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "text" ? "#fff" : Colors.info,
            }}
          >
            Text only
          </Button>

          <Button
            onClick={() => handleContactModeChange("both")}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: contactMode === "both" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: contactMode === "both" ? "#fff" : Colors.info,
            }}
          >
            Both
          </Button>
        </Box>
      </FormControl>

      {caseStudies.length > 0 && (
        <FormControl fullWidth>
          <Typography variant="body1" mt={3} mb={1}>
            Case Study
          </Typography>
          <Select
            value={selectedCaseStudy}
            onChange={(e) => setSelectedCaseStudy(Number(e.target.value))}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {caseStudyOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <Typography variant="body1" fontWeight={"bold"} mt={3}>
        Waitlist Parameters <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select
            value={patientGroupId}
            onChange={(e) => setPatientGroupId(e.target.value)}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px">
          <Typography variant="body1">Providers</Typography>
          {selectedProviders.length === providerOptions.length ? (
            <Button sx={{ textTransform: "none" }} onClick={() => setSelectedProviders([])}>
              Deselect All
            </Button>
          ) : (
            <Button sx={{ textTransform: "none" }} onClick={() => setSelectedProviders(providerOptions.map((option) => option.value))}>
              Select All
            </Button>
          )}
        </Box>

        <Select
          multiple
          displayEmpty
          value={selectedProviders}
          onChange={(e) => setSelectedProviders(e.target.value as string[])}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
            }
            return (selected as string[]).map((value) => providerOptions.find((option) => option.value === value)?.label).join(", ");
          }}
          sx={dropdownStyles}
          MenuProps={menuPropsStyles}
        >
          {providerOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selectedProviders.indexOf(option.value) > -1} sx={{ color: "#FFF" }} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth>
        <Typography variant="body1" mt={3} mb={1}>
          Appointment Duration (minutes)
        </Typography>
        <TextField
          variant="outlined"
          type="number"
          value={duration}
          onChange={(e) => setDuration(Number(e.target.value))}
          placeholder="Enter duration"
          fullWidth
          sx={{
            borderRadius: "10px",
            background: Colors.textfield,
            "& .MuiInputBase-input": {
              padding: "15px",
              fontSize: "0.9rem",
              color: "#FFFFFF",
            },
          }}
        />
      </FormControl>

      <Box
        sx={{
          paddingBottom: "20px", // Increased padding at the bottom to prevent cutoff
          paddingTop: "20px", // Added top padding for balance (optional)
        }}
      >
        <DatePickerField dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />
      </Box>

      <Button sx={{ textTransform: "none", marginTop: "20px" }} onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
        {showAdvancedOptions ? "Hide advanced options" : "Show advanced options"}
      </Button>

      {showAdvancedOptions && (
        <Box ml={2}>
          <Typography variant="body1">
            Remove patient from waitlist
            <SettingsNumberInput disabled={false} value={removalWindow} setter={setRemovalWindow} />
            surrounding days after appointment is booked.
          </Typography>
        </Box>
      )}

      <FooterBox onClick={updatePatient} disabled={false} buttonText="Update Patient" loading={loading} />
    </Box>
  );
};
interface ButtonRowProps {
  selectedValue: string;
  onChange: (value: any) => void;
  options: { label: string; value: string }[];
}

const ButtonRow: React.FC<ButtonRowProps> = ({ selectedValue, onChange, options }) => {
  return (
    <FormControl variant="outlined" fullWidth>
      <Box display="flex" gap="10px">
        {options.map((option) => (
          <Button
            key={option.value}
            onClick={() => onChange(option.value)}
            variant="outlined"
            sx={{
              borderRadius: "10px",
              textTransform: "none",
              borderColor: selectedValue === option.value ? Colors.primary : "rgba(255, 255, 255, 0.2)",
              backgroundColor: "transparent",
              color: selectedValue === option.value ? "#fff" : Colors.info,
            }}
          >
            {option.label}
          </Button>
        ))}
      </Box>
    </FormControl>
  );
};

type UpdatePatientModalProps = {
  patient: OmniPatient;
  open: boolean;
  onClose: () => void;
  integration: EHR.pteverywhere | EHR.raintree | EHR.heno | EHR.ptpracticepro;
};

export const UpdatePatientModal: React.FC<UpdatePatientModalProps> = ({ patient, open, onClose, integration }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const [timezone, setTimezone] = React.useState<string>(patient.patient.timezone);
  const [language, setLanguage] = React.useState<string>(patient.patient.language);

  const [textEnabled, setTextEnabled] = React.useState<boolean>(patient.patient.textEnabled);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(patient.patient.callEnabled);
  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both">(
    patient.patient.textEnabled && patient.patient.callEnabled ? "both" : patient.patient.textEnabled ? "text" : "call"
  );

  const getIntegration = (state: RootState) => {
    if (integration === EHR.pteverywhere) {
      return state.pteverywhereIntegration;
    } else if (integration === EHR.raintree) {
      return state.raintreeIntegration;
    } else if (integration === EHR.heno) {
      return state.henoIntegration;
    } else if (integration === EHR.ptpracticepro) {
      return state.ptpracticeproIntegration;
    } else {
      throw new Error("Invalid integration");
    }
  };

  const [availableDates, setAvailableDates] = React.useState<AvailableDate[]>(patient.waitlist.availableDates); // Using Date instead of DateTime for compatibility with CustomDatePicker
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>(patient[integration]?.ehrProviderIds || []);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>(patient[integration]?.ehrAppointmentTypeIds || []);

  const integrationVariables = (state: RootState) => {
    const integrationState = getIntegration(state);
    return {
      patientMap: integrationState.patientMap,
      providers: integrationState.providers,
      appointments: integrationState.appointments,
      patientSearches: integrationState.patientSearches,
      validPatientSearches: integrationState.validPatientSearches,
      invalidPatientSearches: integrationState.invalidPatientSearches,
      loadingPatients: integrationState.loadingPatients,
      loadingProviders: integrationState.loadingProviders,
      loadingAppointments: integrationState.loadingAppointments,
    };
  };

  let {
    patientMap,
    providers,
    appointments,
    patientSearches,
    validPatientSearches,
    invalidPatientSearches,
    loadingPatients,
    loadingProviders,
    loadingAppointments,
  } = useSelector(integrationVariables);

  const loadingFetchWaitlistPatients: boolean = useSelector((state: RootState) => state.patients.loadingFetchPatients);
  const loadingDeletePatient: boolean = useSelector((state: RootState) => state.patients.loadingDeletePatient);
  const loadingUpdatePatient: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePatient);
  const loadingUpdatePriorities: boolean = useSelector((state: RootState) => state.patients.loadingUpdatePriorities);
  const loadingCreatePatient: boolean = useSelector((state: RootState) => state.patients.loadingCreatePatient);
  const loadingGetPatient: boolean = useSelector((state: RootState) => state.patients.loadingGetPatient);

  useEffect(() => {
    if (patient[integration]?.ehrProviderIds) {
      setSelectedProviders(patient[integration].ehrProviderIds);
    }
  }, [patient[integration]?.ehrProviderIds]);

  useEffect(() => {
    if (patient[integration]?.ehrAppointmentTypeIds) {
      setSelectedAppointmentTypes(patient[integration].ehrAppointmentTypeIds);
    }
  }, [patient[integration]?.ehrAppointmentTypeIds]);

  const dispatch = useAppDispatch();
  const healthieProviderIds = organization?.healthieProviderIds || [];
  if (healthieProviderIds && healthieProviderIds.length > 0) {
    providers = providers.filter((provider) => healthieProviderIds.includes(provider.id));
  }
  const healthieAppointmentTypeIds = organization?.healthieAppointmentTypeIds || [];
  if (healthieAppointmentTypeIds && healthieAppointmentTypeIds.length > 0) {
    appointments = appointments.filter((appointment) => healthieAppointmentTypeIds.includes(appointment.id));
  }
  const contactTypes = organization?.healthieContactTypes || ["In Person", "Healthie Video Call", "Phone Call"]; // TODO (Hizami): This is hard-coded from healthie

  const [patientGroupId, setPatientGroupId] = React.useState<string | undefined>(patient.waitlist.patientGroupId);
  const patientGroupOptions = [
    { value: "", label: "None" },
    ...(organization?.patientGroups?.map((group) => ({
      value: group.patientGroupId,
      label: group.name,
    })) || []),
  ];

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setTextEnabled(mode === "text" || mode === "both");
    setCallEnabled(mode === "call" || mode === "both");
    setContactMode(mode);
  };

  const contactModeOptions = [
    { label: "Call only", value: "call" },
    { label: "Text only", value: "text" },
    { label: "Both", value: "both" },
  ];

  const labelSortAlpha = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label);
  const getPatientLabel = (patient: RawRaintreePatient) => `${patient.firstName} ${patient.lastName} (ID: ${patient.id})`;

  const providerOptions = providers.map((provider) => ({ value: provider.id, label: provider.name })).sort(labelSortAlpha);
  const appointmentOptions = appointments.map((appointment) => ({ value: appointment.id, label: appointment.name })).sort(labelSortAlpha);

  return (
    // <Modal open={open} onClose={onClose}>
    <Box sx={{ overflowY: "auto", height: "92%", paddingBottom: "40px", paddingRight: "20px" }}>
      <HeaderBox title={"Update Patient"} onClose={onClose} />

      <Typography variant="body1" marginTop={"30px"}>
        Patient
      </Typography>
      <Box
        sx={{
          padding: "10px",
          background: Colors.textfield,
          borderRadius: "10px",
          color: "#FFFFFF",
          marginTop: "5px",
          position: "relative",
          "&:hover .close-icon": {
            visibility: "visible",
          },
        }}
      >
        <Typography variant="body1" fontWeight={"bold"} fontSize={"1rem"}>
          {`${patient.patient.firstName ?? ""} ${patient.patient.lastName ?? ""}`}
        </Typography>
        <Box color={Colors.info} display="flex" alignItems="center" gap="10px" mt={0.5}>
          <Typography variant="body2">{`${patient.patient.phoneNumber ?? ""}`}</Typography>
          <CircleIcon sx={{ fontSize: "5px" }} />
          <Typography variant="body2">{`ID: ${patient[EHR.raintree]?.ehrPatientId ?? ""}`}</Typography>
        </Box>
      </Box>

      <Typography variant="body1" mt={3} mb={1}>
        AI Agent Mode of Contact
      </Typography>

      <ButtonRow selectedValue={contactMode} onChange={handleContactModeChange} options={contactModeOptions} />

      <Typography variant="body1" fontWeight="bold" mt={3}>
        Waitlist Parameters <HelpTooltip title="The patient will automatically be put on waitlists for appointments fitting these criteria." />
      </Typography>

      {patientGroupOptions.length > 0 && (
        <FormControl fullWidth>
          <Box display="flex" alignItems="center" gap="10px" mt={2}>
            <Typography variant="body1">Priority Group</Typography>
          </Box>
          <Select
            value={patientGroupId}
            onChange={(e) => setPatientGroupId(e.target.value)}
            sx={{
              borderRadius: "10px",
              background: Colors.textfield,
              marginTop: "5px",
              "& .MuiInputBase-input": {
                padding: "15px",
                fontSize: "0.9rem",
                color: "#FFFFFF",
              },
              "& .MuiSvgIcon-root": {
                color: "#FFF",
              },
            }}
          >
            {patientGroupOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                <ListItemText primary={option.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {loadingProviders && <LoadingWithMessage message="Loading providers..." size={30} />}
      {!loadingProviders && (
        <CustomAutocomplete label={"Providers"} options={providerOptions} selectedItems={selectedProviders} setSelectedItems={setSelectedProviders} />
      )}
      {!loadingProviders && providers.length === 0 && (
        <Typography variant="body2" color="error" mt={1}>
          No providers found.
        </Typography>
      )}

      {loadingAppointments && <LoadingWithMessage message="Loading appointment types..." size={30} />}
      {!loadingAppointments && (
        <CustomAutocomplete
          label={"Appointment Types"}
          options={appointmentOptions}
          selectedItems={selectedAppointmentTypes}
          setSelectedItems={setSelectedAppointmentTypes}
        />
      )}
      {!loadingAppointments && appointments.length === 0 && (
        <Typography variant="body2" color="error" mt={1}>
          No appointment types found.
        </Typography>
      )}

      <DatePickerField dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />

      <FooterBox
        onClick={async () => {
          if (!user?.token) {
            return;
          }

          const updatedPatient: PartialOmniPatient = {
            patientId: patient?.patientId,
            patient: {
              timezone,
              language,
              textEnabled,
              callEnabled,
            },
            waitlist: {
              availableDates: availableDates,
              patientGroupId,
            },
            [integration]: {
              ehrProviderIds: selectedProviders,
              ehrAppointmentTypeIds: selectedAppointmentTypes,
            },
          };

          if (patient?.patientId) {
            await dispatch(
              updatePatient({
                token: user.token,
                patient: updatedPatient,
              })
            );
            onClose();
          }
        }}
        buttonText={"Update Patient"}
        loading={loadingUpdatePatient}
      />
    </Box>
  );
};

export default UpdateHealthiePatientModal;
