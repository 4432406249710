import type { CalendarType } from './types';

export const CALENDAR_TYPES = {
  GREGORY: 'gregory',
  HEBREW: 'hebrew',
  ISLAMIC: 'islamic',
  ISO_8601: 'iso8601',
} as const;

export const CALENDAR_TYPE_LOCALES: Partial<Record<CalendarType, string[]>> = {
  gregory: [
    'en-CA',
    'en-US',
    'es-AR',
    'es-BO',
    'es-CL',
    'es-CO',
    'es-CR',
    'es-DO',
    'es-EC',
    'es-GT',
    'es-HN',
    'es-MX',
    'es-NI',
    'es-PA',
    'es-PE',
    'es-PR',
    'es-SV',
    'es-VE',
    'pt-BR',
  ],
  hebrew: ['he', 'he-IL'],
  islamic: [
    // ar-LB, ar-MA intentionally missing
    'ar',
    'ar-AE',
    'ar-BH',
    'ar-DZ',
    'ar-EG',
    'ar-IQ',
    'ar-JO',
    'ar-KW',
    'ar-LY',
    'ar-OM',
    'ar-QA',
    'ar-SA',
    'ar-SD',
    'ar-SY',
    'ar-YE',
    'dv',
    'dv-MV',
    'ps',
    'ps-AR',
  ],
};

export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6] as const;

export type GridInterval = 15 | 30 | 60;

export const INTERVAL: GridInterval = 30;

export const defaultGridPeriods = Array(24)
  .fill(null)
  .map((_, hourIndex) => {
    const hourPeriods = Array(60 / INTERVAL)
      .fill(null)
      .map((_, subHourIndex) => {
        const period = subHourIndex * INTERVAL;
        const isLastPeriod = subHourIndex === 60 / INTERVAL - 1;
        const hourString = hourIndex.toString().padStart(2, "0");
        const endHourString = isLastPeriod
          ? (hourIndex + 1).toString().padStart(2, "0")
          : hourString;
        const startPeriodString = period.toString().padStart(2, "0");
        const endPeriodString = isLastPeriod
          ? "00"
          : (period + INTERVAL).toString().padStart(2, "0");
        return {
          start: `${hourString}:${startPeriodString}`,
          end: `${endHourString}:${endPeriodString}`,
        };
      });

    return hourPeriods;
  });