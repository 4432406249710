import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { EHR, FrontendUser, HealthieAppointment, RawHealthiePatient, HealthieProvider } from "../types";
import { sessionExpired } from "./SessionSlice";
import { getSession } from "./AuthSlice";
import { RootState } from "../store";
import { api } from "../utils/utils";

export interface HealthieIntegrationState {
  providers: HealthieProvider[];
  appointments: HealthieAppointment[];
  appointmentLocations: string[];
  contactTypes: string[];
  patientMap: { [healthiePatientId: string]: RawHealthiePatient };
  patientSearches: string[];
  validPatientSearches: string[];
  invalidPatientSearches: string[];
  error: string | null;
  loadingUploadingToken: boolean;
  loadingDeletingIntegration: boolean;
  loadingProviders: boolean;
  loadingAppointments: boolean;
  loadingPatients: boolean;
}

const initialState: HealthieIntegrationState = {
  providers: [],
  appointments: [],
  appointmentLocations: [],
  contactTypes: ["In Person", "Healthie Video Call", "Secure Videochat", "Phone Call"],
  patientMap: {},
  patientSearches: [],
  validPatientSearches: [],
  invalidPatientSearches: [],
  error: null,
  loadingUploadingToken: false,
  loadingDeletingIntegration: false,
  loadingProviders: false,
  loadingAppointments: false,
  loadingPatients: false,
};

export const fetchProviders = createAsyncThunk<{ providers: HealthieProvider[] }, { token: string }, { rejectValue: Error }>(
  "integrations/fetchProviders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await api.get(`/integrations/healthie/providers`, token);

      return { providers: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch providers: " + (error as Error).message));
    }
  }
);

export const fetchAppointments = createAsyncThunk<{ appointments: HealthieAppointment[] }, { token: string }, { rejectValue: Error }>(
  "integrations/fetchAppointments",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/appointmentTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { appointments: response.data as HealthieAppointment[] };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch appointments: " + (error as Error).message));
    }
  }
);

export const fetchAppointmentLocations = createAsyncThunk<string[], { token: string }, { rejectValue: Error }>(
  "integrations/fetchAppointmentLocations",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/appointmentLocations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch appointment locations: " + (error as Error).message));
    }
  }
);

export const fetchHealthiePatients = createAsyncThunk<
  { patients: RawHealthiePatient[] },
  { token: string; keywords: string },
  { rejectValue: Error }
>("/integrations/fetchPatients", async ({ token, keywords }, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/healthie/patients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        keywords,
      },
    });

    return { patients: response.data };
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch patients: " + (error as Error).message));
  }
});

export const handleTokenUpload = createAsyncThunk<void, { token: string; ehrToken: string; ehr: EHR }, { rejectValue: Error }>(
  "integrations/handleTokenUpload",
  async ({ token, ehrToken, ehr }, { dispatch, rejectWithValue }) => {
    try {
      if (!ehrToken) {
        console.error("Token is empty");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`,
        { ehrToken: ehrToken },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        dispatch(getSession());
      }
    } catch (error) {
      console.error(`Error updating ${ehr} token:`, error);
      return rejectWithValue(new Error(`Error updating ${ehr} token: ${error}`));
    }
  }
);

export const handleDeleteIntegration = createAsyncThunk<void, { token: string; ehr: EHR }, { rejectValue: Error }>(
  "integrations/handleDeleteIntegration",
  async ({ token, ehr }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(clearIntegration());
        dispatch(getSession());
      }
    } catch (error) {
      console.error(`Error disconnecting from ${ehr}:`, error);
      return rejectWithValue(new Error(`Error disconnecting from ${ehr}: ${error}`));
    }
  }
);

const integrationsSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    clearIntegration: (state) => {
      state.providers = [];
      state.appointments = [];
      state.error = null;
      state.loadingProviders = false;
      state.loadingAppointments = false;
      state.loadingPatients = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      })
      .addCase(fetchAppointments.pending, (state) => {
        state.loadingAppointments = true;
      })
      .addCase(fetchAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
        state.loadingAppointments = false;
      })
      .addCase(fetchAppointments.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointments";
        state.loadingAppointments = false;
      })
      .addCase(fetchAppointmentLocations.pending, (state) => {})
      .addCase(fetchAppointmentLocations.fulfilled, (state, action) => {
        state.appointmentLocations = action.payload;
      })
      .addCase(fetchAppointmentLocations.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointment locations";
      })
      .addCase(fetchHealthiePatients.pending, (state, action) => {
        state.patientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = true;
      })
      .addCase(fetchHealthiePatients.fulfilled, (state, action) => {
        action.payload.patients.forEach((patient) => {
          state.patientMap[patient.healthiePatientId] = patient;
        });
        state.validPatientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = false;
      })
      .addCase(fetchHealthiePatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
        state.invalidPatientSearches.push(action.meta.arg.keywords);
      })
      .addCase(handleTokenUpload.pending, (state) => {
        state.loadingUploadingToken = true;
      })
      .addCase(handleTokenUpload.fulfilled, (state) => {
        state.loadingUploadingToken = false;
      })
      .addCase(handleTokenUpload.rejected, (state, action) => {
        state.error = action.error.message || "Failed to upload token";
        state.loadingUploadingToken = false;
      })
      .addCase(handleDeleteIntegration.pending, (state) => {
        state.loadingDeletingIntegration = true;
      })
      .addCase(handleDeleteIntegration.fulfilled, (state) => {
        state.loadingDeletingIntegration = false;
      })
      .addCase(handleDeleteIntegration.rejected, (state, action) => {
        state.error = action.error.message || "Failed to delete integration";
        state.loadingDeletingIntegration = false;
      });
  },
});

export const { clearIntegration } = integrationsSlice.actions;

export default integrationsSlice.reducer;
