import { Period } from "../components/calendar/utils/helpers/dates";
import { Organization } from "../types";
import { periodIntersectsWithPeriod } from "./dates";
import { getNewId } from "./faker";
import { PatientSlot } from "./types";

export const systemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const fillAllOpenSlots = ({
  currentSelectionMap,
  startDate,
  organization,
}: {
  currentSelectionMap: Map<string, PatientSlot[]>;
  startDate: Date;
  organization: Organization | null;
}): PatientSlot[] => {
  // Remaining days in week including today
  const daysLeft = 7 - startDate.getDay();
  const startHour = organization?.workingHoursStart
    ? new Date(organization.workingHoursStart.replace(/\..*/, "")).getHours()
    : 0;
  const endHour = organization?.workingHoursEnd
    ? new Date(organization.workingHoursEnd.replace(/\..*/, "")).getHours()
    : 23;
  return Array(daysLeft)
    .fill(null)
    .map((_, index) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + index);
      const existingPeriods: Period[] =
        currentSelectionMap.get(date.toDateString())?.slice() || [];
      console.log({ existingPeriods, startHour, endHour, organization });
      const freePeriods = Array(24)
        .fill(null)
        .map((_, index) => {
          if (index < startHour || index >= endHour) {
            return null;
          }
          const testPeriod = {
            start: `${index}:00`,
            end: `${index + 1}:00`,
          };
          if (
            existingPeriods.some((period) =>
              periodIntersectsWithPeriod(testPeriod, period)
            )
          ) {
            return null;
          }
          return testPeriod;
        })
        .filter(Boolean) as Period[];

      return freePeriods.map((period) => {
        return {
          id: getNewId(),
          start: period.start,
          end: period.end,
          date: date.toDateString(),
          patientName: "",
          appointmentType: null,
          status: "proposed",
          proposedAppointmentType: {
            id: "*",
            name: "Any",
            duration: 60,
          },
          proposedAppointmentMode: "in-person",
          agent: {
            name: "Rando Agent",
            avatarUrl: "/admin.svg",
          },
          source: "ai",
        } as PatientSlot;
      });
    })
    .flat();
};
