import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Checkbox, ListItemText, TextField, FormControl, Autocomplete, Paper } from "@mui/material";
import { Colors } from "../Colors";

interface CustomAutocompleteProps {
  options: { value: string; label: string }[];
  selectedItems: string[];
  setSelectedItems: (items: string[]) => void;
  label: string;
  sx?: React.CSSProperties;
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({ options, selectedItems, setSelectedItems, label, sx }) => {
  const selectAllItems = () => {
    setSelectedItems(options.map((option) => option.value));
  };

  const deselectAllItems = () => {
    setSelectedItems([]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent Backspace from deselecting the selected options
    if (event.key === "Backspace") {
      event.stopPropagation();
    }

    // Prevent Enter key from submitting the form or refreshing the page
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const CustomListboxComponent = React.useCallback((props: any) => {
    return (
      <ul
        {...props}
        style={{
          padding: 0,
          margin: 0,
          background: Colors.bg3,
        }}
      />
    );
  }, []);

  return (
    <FormControl fullWidth>
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" mt={2}>
        <Typography variant="body1">{label}</Typography>
        {selectedItems.length === options.length ? (
          <Button sx={{ textTransform: "none" }} onClick={deselectAllItems}>
            Deselect All
          </Button>
        ) : (
          <Button sx={{ textTransform: "none" }} onClick={selectAllItems}>
            Select All
          </Button>
        )}
      </Box>

      <Autocomplete
        multiple
        options={options}
        getOptionLabel={(option) => option.label}
        value={selectedItems.map((value) => options.find((option) => option.value === value)).filter((value) => value !== undefined)}
        onChange={(event, newValue) => {
          setSelectedItems(newValue.map((option) => option.value));
        }}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Select options" onKeyDown={handleKeyDown} />}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.value}>
            <Checkbox sx={{ mr: 1, color: "white" }} checked={selected} />
            <ListItemText primary={option.label} />
          </li>
        )}
        renderTags={(value) => {
          if (value.length === 0) {
            return <Typography sx={{ color: Colors.info }}>Select options</Typography>;
          }
          return value.map((option) => option.label).join(", ");
        }}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        ListboxComponent={CustomListboxComponent}
      />
    </FormControl>
  );
};
export default CustomAutocomplete;
