import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { HealthieAppointment, RawHealthiePatient, HealthieProvider } from "../types";
import { sessionExpired } from "./SessionSlice";

export type PteverywhereProvider = {
  id: string;
  name: string;
};

export interface PteverywhereIntegrationState {
  providers: PteverywhereProvider[];
  appointments: HealthieAppointment[];
  contactTypes: string[];
  patientMap: { [pteverywherePatientId: string]: RawPteverywherePatient };
  patientSearches: string[];
  validPatientSearches: string[];
  invalidPatientSearches: string[];
  error: string | null;
  loadingUploadingToken: boolean;
  loadingDeletingIntegration: boolean;
  loadingProviders: boolean;
  loadingAppointments: boolean;
  loadingPatients: boolean;
}

const initialState: PteverywhereIntegrationState = {
  providers: [],
  appointments: [],
  contactTypes: ["In Person", "Healthie Video Call", "Secure Videochat", "Phone Call"],
  patientMap: {},
  patientSearches: [],
  validPatientSearches: [],
  invalidPatientSearches: [],
  error: null,
  loadingUploadingToken: false,
  loadingDeletingIntegration: false,
  loadingProviders: false,
  loadingAppointments: false,
  loadingPatients: false,
};

export const fetchPteverywhereProviders = createAsyncThunk<{ providers: PteverywhereProvider[] }, { token: string }, { rejectValue: Error }>(
  "/integrations/fetchProviders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pteverywhere/providers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.data) {
        throw new Error("No data received");
      }

      return { providers: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch providers: " + (error as Error).message));
    }
  }
);

export const fetchPteverywhereAppointments = createAsyncThunk<{ appointments: HealthieAppointment[] }, { token: string }, { rejectValue: Error }>(
  "integrations/fetchAppointments",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pteverywhere/appointmentTypes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return { appointments: response.data as HealthieAppointment[] };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch appointments: " + (error as Error).message));
    }
  }
);

export type RawPteverywherePatient = {
  id: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  dob: string;
};

export const fetchPteverywherePatients = createAsyncThunk<
  { patients: RawPteverywherePatient[] },
  { token: string; keywords: string },
  { rejectValue: Error }
>("/integrations/fetchPatients", async ({ token, keywords }, { getState, rejectWithValue }) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/pteverywhere/patients`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        keywords,
      },
    });

    if (!response.data) {
      throw new Error("No data received");
    }

    return { patients: response.data };
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch patients: " + (error as Error).message));
  }
});

const pteverywhereIntegrationSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    clearIntegration: (state) => {
      state.providers = [];
      state.appointments = [];
      state.error = null;
      state.loadingProviders = false;
      state.loadingAppointments = false;
      state.loadingPatients = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPteverywhereProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchPteverywhereProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchPteverywhereProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      })
      .addCase(fetchPteverywhereAppointments.pending, (state) => {
        state.loadingAppointments = true;
      })
      .addCase(fetchPteverywhereAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
        state.loadingAppointments = false;
      })
      .addCase(fetchPteverywhereAppointments.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointments";
        state.loadingAppointments = false;
      })
      .addCase(fetchPteverywherePatients.pending, (state, action) => {
        state.patientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = true;
      })
      .addCase(fetchPteverywherePatients.fulfilled, (state, action) => {
        action.payload.patients.forEach((patient) => {
          state.patientMap[patient.id] = patient;
        });
        state.validPatientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = false;
      })
      .addCase(fetchPteverywherePatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
        state.invalidPatientSearches.push(action.meta.arg.keywords);
      });
  },
});

export default pteverywhereIntegrationSlice.reducer;
