import styles from "./Button.module.scss";
import loadingWhiteIcon from "../../assets/loading-white.svg";

const Button = ({
  children,
  onClick,
  className,
  type = "primary",
  htmlType = "button",
  title,
  danger,
  responsive,
  disabled,
  loading,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
  type?: "primary" | "accent" | "plain";
  htmlType?: "button" | "submit" | "reset";
  title?: string;
  danger?: boolean;
  responsive?: boolean;
  disabled?: boolean;
  loading?: boolean;
}) => {
  return (
    <button
      className={[
        styles.button,
        className,
        styles[type],
        danger && styles.danger,
        responsive && styles.responsive,
        disabled && styles.disabled,
        loading && styles.loading,
      ].join(" ")}
      onClick={onClick}
      title={title}
      type={htmlType}
      disabled={disabled}
    >
      {children}
      {loading && <img src={loadingWhiteIcon} alt="loading" />}
    </button>
  );
};

export default Button;
