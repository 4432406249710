import { FC, useEffect, useState } from "react";
import { useAppDispatch } from "../../store";
import { RootState } from "../../store";
import { EHR, WaitlistState } from "../../types";
import { executeRun, deleteRun } from "../../slices/WaitlistRunsSlice";
import { useSelector } from "react-redux";
import {
  Button,
  Box,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  Modal,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { Colors } from "../../Colors";
import LoadingWithMessage from "../../subcomponents/LoadingWithMessage";
import { WaitlistRunRow } from "../AgentInfoPage";
import IosShareIcon from "@mui/icons-material/IosShare";
import ExportWaitlistOutcomesModal from "../../forms/ExportWaitlistOutcomesModal";
import AddHealthieRunModal from "../../components/modals/AddHealthieRunModal";
import AddWebptRunModal from "../../components/modals/AddWebptRunModal";
import AddPteverywhereRunModal from "../../components/modals/AddPteverywhereRunModal";
import { fetchWaitlistRunsForOrganization } from "../../slices/SlotsSlice";

const tabBoxStyles = {
  backgroundColor: Colors.bg2,
  borderRadius: "15px",
  padding: "20px",
  width: "100%",
  height: "100%",
};

export const FillRuns: FC = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector(
    (state: RootState) => state.auth.organization
  );
  const { organizationWaitlistRuns: waitlistRuns, isLoadingOrganizationWaitlistRuns: loadingRuns } = useSelector(
    (state: RootState) => state.slots
  );
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [selectedRunsTab, setSelectedRunsTab] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [runModalOpen, setRunModalOpen] = useState(false);

  useEffect(() => {
    if (!user?.token) {
      return;
    }
    dispatch(fetchWaitlistRunsForOrganization({ token: user?.token }));
  }, []);

  const onRunClose = () => {
    setRunModalOpen(false);
  };

  const successfulRuns = waitlistRuns.filter(
    (run) => run.state === WaitlistState.accepted
  );
  const failedRuns = waitlistRuns.filter((run) =>
    [
      WaitlistState.declined,
      WaitlistState.bookingFailed,
      WaitlistState.expired,
    ].includes(run.state)
  );
  const emptyRuns = waitlistRuns.filter(
    (run) => run.state === WaitlistState.noValidPatients
  );
  const ongoingRuns = waitlistRuns.filter(
    (run) => run.state === WaitlistState.ongoing
  );
  const notStartedRuns = waitlistRuns.filter(
    (run) => run.state === WaitlistState.notStarted
  );

  const endedRuns =
    selectedRunsTab === 0
      ? successfulRuns
      : selectedRunsTab === 1
      ? failedRuns
      : selectedRunsTab === 2
      ? emptyRuns
      : waitlistRuns.filter(
          (run) =>
            run.state !== WaitlistState.ongoing &&
            run.state !== WaitlistState.notStarted
        );

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const renderTabContent = (type: "outcomes" | "queue") => {
    if (!user?.token) {
      return;
    }
    if (type === "outcomes") {
      return (
        <Box sx={tabBoxStyles}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "15px" }}
          >
            <Box display="flex" alignItems="center">
              <Typography variant="h6">Waitlist Run Outcomes</Typography>
              <Tabs
                value={selectedRunsTab}
                onChange={(e, newValue) => setSelectedRunsTab(newValue)}
              >
                <Tab
                  label="Successful"
                  style={{ textTransform: "none", color: "#FFF" }}
                />
                <Tab
                  label="Unsuccessful"
                  style={{ textTransform: "none", color: "#FFF" }}
                />
                <Tab
                  label="Empty"
                  style={{ textTransform: "none", color: "#FFF" }}
                />
                <Tab
                  label="All"
                  style={{ textTransform: "none", color: "#FFF" }}
                />
              </Tabs>
            </Box>
            <Tooltip title="Export Waitlist Runs">
              <Button
                variant="text"
                onClick={() => setExportModalOpen(true)}
                sx={{
                  color: Colors.primary,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IosShareIcon />
              </Button>
            </Tooltip>
          </Box>

          {loadingRuns && (
            <LoadingWithMessage
              message="Loading runs..."
              size={40}
              customStyles={{ marginTop: "20px" }}
            />
          )}

          {endedRuns.length > 0 ? (
            endedRuns.map((run: any) => (
              <WaitlistRunRow
                key={run.waitlistRunId}
                run={run}
                agentId={run.agentId}
                executeRun={() =>
                  dispatch(
                    executeRun({ token: user.token!, runId: run.waitlistRunId })
                  )
                }
                deleteRun={() =>
                  dispatch(
                    deleteRun({ token: user.token!, runId: run.waitlistRunId })
                  )
                }
              />
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: Colors.info }}
            >
              No waitlist runs completed yet
            </Typography>
          )}

          {/* Render ExportWaitlistOutcomesModal when exportModalOpen is true */}
          {exportModalOpen && (
            <Modal
              open={exportModalOpen}
              onClose={() => setExportModalOpen(false)}
              aria-labelledby="export-modal-title"
              aria-describedby="export-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  maxWidth: 600,
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: 2,
                  outline: "none",
                }}
              >
                <ExportWaitlistOutcomesModal
                  agentsSelector={false}
                  mainAgentsFilter={undefined}
                  onClose={() => setExportModalOpen(false)}
                />
              </Box>
            </Modal>
          )}
        </Box>
      );
    } else if (type === "queue") {
      if (!user?.token) {
        return;
      }
      return (
        <Box sx={tabBoxStyles}>
          <Box display="flex" alignItems="center" sx={{ marginBottom: "15px" }}>
            <Typography variant="h6">Waitlist Run Queue</Typography>
          </Box>

          {loadingRuns && (
            <LoadingWithMessage
              message="Loading runs..."
              size={40}
              customStyles={{ marginTop: "20px" }}
            />
          )}
          {ongoingRuns.concat(notStartedRuns).length > 0 ? (
            ongoingRuns.concat(notStartedRuns).map((run: any) => (
              <WaitlistRunRow
                key={run.waitlistRunId}
                run={run}
                agentId={run.agentId}
                executeRun={() =>
                  dispatch(
                    executeRun({
                      token: user.token!,
                      runId: run.waitlistRunId,
                    })
                  )
                }
                deleteRun={() =>
                  dispatch(
                    deleteRun({
                      token: user.token!,
                      runId: run.waitlistRunId,
                    })
                  )
                }
              />
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: Colors.info }}
            >
              No waitlist runs yet
            </Typography>
          )}
        </Box>
      );
    }
    return null;
  };

  return (
    <Box sx={{ width: "100%", color: "#FFF", marginTop: "20px" }}>
      {isSmallScreen ? (
        <>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            style={{ marginBottom: "10px" }}
            centered
          >
            <Tab
              label="Outcomes"
              style={{ textTransform: "none", color: "#FFF" }}
            />
            <Tab
              label="Queue"
              style={{ textTransform: "none", color: "#FFF" }}
            />
          </Tabs>
          {renderTabContent(selectedTab === 0 ? "outcomes" : "queue")}
        </>
      ) : (
        <Box sx={{ display: "flex", gap: "20px" }}>
          {renderTabContent("outcomes")}
          {renderTabContent("queue")}
        </Box>
      )}
      <>
        <AddHealthieRunModal
          open={
            !!organization?.integrations.includes(EHR.healthie) && runModalOpen
          }
          onClose={onRunClose}
        />
        <AddWebptRunModal
          open={
            !!organization?.integrations.includes(EHR.webpt) && runModalOpen
          }
          onClose={onRunClose}
        />
        <AddPteverywhereRunModal
          open={
            !!organization?.integrations.includes(EHR.pteverywhere) &&
            runModalOpen
          }
          onClose={onRunClose}
        />
      </>
    </Box>
  );
};

export default FillRuns;
