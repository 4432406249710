import { MouseEvent as ReactMouseEvent, useEffect, useRef } from "react";
import styles from "./Modal.module.scss";
import timesIcon from "../../assets/times.svg";

interface ModalProps {
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
}

const Modal = ({ children, visible, onClose }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);

  const visibleRef = useRef(visible);
  visibleRef.current = visible;

  const handleClose = (event: MouseEvent) => {
    if (
      visibleRef.current &&
      modalRef.current &&
      !modalRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (visible) {
      window.addEventListener("mousedown", handleClose);
    }
    return () => {
      window.removeEventListener("mousedown", handleClose);
    };
  }, [visible]);

  const onCloseClick = (e: ReactMouseEvent) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <div className={[styles.backdrop, visible ? styles.visible : ""].join(" ")}>
      <div
        ref={modalRef}
        className={[styles.modal, visible ? styles.visible : ""].join(" ")}
      >
        {children}
        <button className={styles.close} onClick={onCloseClick}>
          <img src={timesIcon} alt="close" className="app-icon" />
        </button>
      </div>
    </div>
  );
};

export default Modal;
