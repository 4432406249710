import { MouseEvent, TouchEvent, useCallback, useMemo, useState } from "react";
import styles from "./FillOpenSlots.module.scss";
import { DragProgress, TileDisabledFunc } from "../../components/calendar/utils/types";
import {
  calculatePeriodDifferenceInHours,
  convert24HourMinutesTo12HourMinutes,
  convertHourMinutesToDecimal,
  Period,
  periodIntersectsWithPeriod,
} from "../../utils/dates";
import { PatientSlot, Weekday } from "../../utils/types";
import SlotViewModal from "./SlotViewModal";
import SlotAddEditModal from "./SlotAddEditModal";
import { getNewId } from "../../utils/faker";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { useToast } from "../../utils/hooks/useToast";
import {
  createFillOpenSlotsRun,
  createWaitlistRunBatch,
  setAutoFillInProgress,
  setCurrentView,
  setSelectedDatesMap,
} from "../../slices/SlotsSlice";
import { useNavigate } from "react-router-dom";
import { mergeDragProgress } from "../../components/calendar/utils/helpers/dates";

const defaultGridPeriods = Array(24)
  .fill(null)
  .map((_, hourIndex) => {
    const hourPeriods = Array(2)
      .fill(null)
      .map((_, halfHourIndex) => {
        const period = halfHourIndex * 30;
        const isLastPeriod = halfHourIndex === 1;
        const hourString = hourIndex.toString().padStart(2, "0");
        const endHourString = isLastPeriod ? (hourIndex + 1).toString().padStart(2, "0") : hourString;
        const startPeriodString = period.toString().padStart(2, "0");
        const endPeriodString = isLastPeriod ? "00" : (period + 30).toString().padStart(2, "0");
        return {
          start: `${hourString}:${startPeriodString}`,
          end: `${endHourString}:${endPeriodString}`,
        };
      });

    return hourPeriods;
  });

type GridViewProps = {
  timeMode?: "12" | "24";
  shouldDisableDate: TileDisabledFunc;
};

interface GestureConfig {
  day: string;
  period: Period;
}

let gestureStartConfig: GestureConfig | null;

const GridView = ({ timeMode = "12", shouldDisableDate }: GridViewProps) => {
  const {
    activeStartDate,
    selectedDatesMap,
    autoFillInProgress,
    currentProvider,
    isCreatingWaitlistRunBatch,
    gridStart,
  } = useSelector((state: RootState) => state.slots);
  const dispatch = useAppDispatch();
  const showToast = useToast();
  const user = useSelector((state: RootState) => state.auth.user);
  const { agents } = useSelector((state: RootState) => state.agents);

  const [slotOnView, setSlotOnView] = useState<PatientSlot | null>(null);
  const [slotOnEdit, setSlotOnEdit] = useState<PatientSlot | null>(null);
  const [dragProgress, setDragProgress] = useState<DragProgress | null>(null);

  const navigate = useNavigate();

  const gridPeriods = useMemo(() => {
    return [...defaultGridPeriods.slice(gridStart), ...defaultGridPeriods.slice(0, gridStart)];
  }, [gridStart]);

  const handleMouseUp = () => {
    if (!dragProgress) {
      return;
    }

    const sortedPeriods = dragProgress.periods.slice().sort((a, b) => {
      const aDecimal = convertHourMinutesToDecimal(a.start);
      const bDecimal = convertHourMinutesToDecimal(b.start);
      const adjustedADecimal = (aDecimal - gridStart + 24) % 24;
      const adjustedBDecimal = (bDecimal - gridStart + 24) % 24;
      return adjustedADecimal - adjustedBDecimal;
    });

    const period = {
      start: sortedPeriods[0].start,
      end: sortedPeriods[sortedPeriods.length - 1].end,
    };

    setDragProgress(null);

    const intersectingSlot = selectedDatesMap
      .get(dragProgress.day)
      ?.find((slot) => periodIntersectsWithPeriod(slot, period));

    if (intersectingSlot) {
      showToast({
        message: `There's already an appointment scheduled from ${convert24HourMinutesTo12HourMinutes(
          intersectingSlot.start
        )} to ${convert24HourMinutesTo12HourMinutes(intersectingSlot.end)}`,
        type: "error",
      });
      return;
    }

    const newSlot: PatientSlot = {
      ...period,
      patientName: "",
      appointmentType: null,
      status: "proposed",
      date: dragProgress.day,
      id: getNewId(),
      agent: agents.find((agent) => agent.ehr === "healthie"),
      proposedAppointmentMode: "in-person",
      proposedAppointmentType: {
        id: "*",
        name: "Any",
        duration: Infinity,
      },
      source: "ai",
    };
    const newSelectedMap = new Map(selectedDatesMap);
    newSelectedMap.set(
      dragProgress.day,
      [...(newSelectedMap.get(dragProgress.day) || []), newSlot].sort(
        (a, b) => a.start.localeCompare(b.start) || a.end.localeCompare(b.end)
      )
    );
    dispatch(setSelectedDatesMap(newSelectedMap));
    setSlotOnEdit(newSlot);
  };
  //   e: MouseEvent | TouchEvent,
  //   gestureConfig?: GestureConfig
  // ) => {
  //   let gestureEndConfig: GestureConfig | null = gestureConfig || null;

  //   if ((e as TouchEvent).changedTouches) {
  //     // Handle for mobile devices
  //     const touch = (e as TouchEvent).changedTouches?.[0];
  //     const targetElement = document.elementFromPoint(
  //       touch.clientX,
  //       touch.clientY
  //     );
  //     gestureEndConfig = targetElement?.ariaLabel
  //       ? {
  //           day: targetElement.ariaLabel.split("-")[0],
  //           period: {
  //             start: targetElement.ariaLabel.split("-")[1],
  //             end: targetElement.ariaLabel.split("-")[2],
  //           },
  //         }
  //       : null;
  //   }

  //   if (
  //     !gestureStartConfig ||
  //     !gestureEndConfig ||
  //     gestureStartConfig.day !== gestureEndConfig.day
  //   ) {
  //     // Gesture spanned multiple days, so we bail
  //     return;
  //   }
  //   const startDecimal = convertHourMinutesToDecimal(
  //     gestureStartConfig.period.start
  //   );
  //   const endDecimal = convertHourMinutesToDecimal(gestureEndConfig.period.end);

  //   const adjustedStartDecimal = (startDecimal - gridStart + 24) % 24;
  //   const adjustedEndDecimal = (endDecimal - gridStart + 24) % 24;
  //   const shouldReversePeriod =
  //     adjustedStartDecimal > adjustedEndDecimal &&
  //     adjustedStartDecimal - adjustedEndDecimal < 12;

  //   const period = {
  //     start: shouldReversePeriod
  //       ? gestureEndConfig.period.start
  //       : gestureStartConfig.period.start,
  //     end: shouldReversePeriod
  //       ? gestureStartConfig.period.end
  //       : gestureEndConfig.period.end,
  //   };

  // };

  const displayAddEditModal = (slot: PatientSlot) => {
    setSlotOnEdit(slot);
    setSlotOnView(null);
  };

  const handleMouseDragOver = (e: MouseEvent | TouchEvent, gestureConfig?: GestureConfig) => {
    let gestureEndConfig: GestureConfig | null = gestureConfig || null;

    if ((e as TouchEvent).changedTouches) {
      // Handle for mobile devices
      const touch = (e as TouchEvent).changedTouches?.[0];
      const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
      gestureEndConfig = targetElement?.ariaLabel
        ? {
            day: targetElement.ariaLabel.split("-")[0],
            period: {
              start: targetElement.ariaLabel.split("-")[1],
              end: targetElement.ariaLabel.split("-")[2],
            },
          }
        : null;
    }

    if (!dragProgress || !gestureEndConfig || dragProgress.day !== gestureConfig?.day) {
      // Gesture spanned multiple days or the same period, so we bail
      setDragProgress(null);
      return;
    }

    const newDragProgress = mergeDragProgress({ dragProgress, newPeriod: gestureEndConfig.period, gridStart });

    setDragProgress(newDragProgress);
  };

  const handleAddEditModalClose = () => {
    // If multiple slots are "proposed", do nothing. Else find and remove the proposed slot
    if (slotOnEdit?.status === "proposed" && !autoFillInProgress) {
      const newSelectedMap = new Map(selectedDatesMap);
      newSelectedMap.set(
        slotOnEdit.date,
        newSelectedMap.get(slotOnEdit.date)?.filter((slot) => slot.id !== slotOnEdit.id) || []
      );
      dispatch(setSelectedDatesMap(newSelectedMap));
    }
    setSlotOnEdit(null);
    setSlotOnView(null);
  };

  const handleAddEditModalSubmit = (formData: PatientSlot) => {
    if (!slotOnEdit) {
      return;
    }

    const startDate = new Date(slotOnEdit.date);
    startDate.setHours(convertHourMinutesToDecimal(formData.start));

    dispatch(
      createFillOpenSlotsRun({
        token: user?.token || "",
        params: {
          appointmentDate: startDate.toISOString(),
          ehrParams: {
            appointmentTypeId:
              formData.proposedAppointmentType?.id === "*" ? undefined : formData.proposedAppointmentType?.id,
            appointmentTypeName:
              formData.proposedAppointmentType?.id === "*" ? undefined : formData.proposedAppointmentType?.name,
            duration: calculatePeriodDifferenceInHours(formData) * 60,
            contactType: formData.proposedAppointmentMode || "",
            providerId: currentProvider?.id || "",
            pcpName: `${currentProvider?.firstName} ${currentProvider?.lastName}` || "",
            ehr: "healthie",
          },
        },
        onComplete: () => {
          // Optimistic update
          const newSelectedMap = new Map(selectedDatesMap);
          newSelectedMap.set(
            slotOnEdit.date,
            newSelectedMap.get(slotOnEdit.date)?.map((slot) =>
              slot.id === slotOnEdit.id
                ? {
                    ...slot,
                    ...formData,
                    status: autoFillInProgress ? slot.status : "ongoing",
                  }
                : slot
            ) || []
          );
          dispatch(setSelectedDatesMap(newSelectedMap));
          showToast({
            message: "Fill run initiated successfully",
            type: "success",
            followUpConfig: {
              buttonText: "View Details",
              onButtonClick: () => {
                navigate(`/dashboard/fill-open-slots?view=fill-run`);
              },
            },
          });
          setSlotOnEdit(null);
          setSlotOnView(null);
        },
      })
    );
  };

  const handleFillRunRemove = () => {
    const newSelectedMap = new Map(selectedDatesMap);
    newSelectedMap.set(
      slotOnEdit?.date || "",
      newSelectedMap.get(slotOnEdit?.date || "")?.filter((slot) => slot.id !== slotOnEdit?.id) || []
    );
    dispatch(setSelectedDatesMap(newSelectedMap));
    setSlotOnEdit(null);
    setSlotOnView(null);
  };

  const statusHandlerMap = {
    proposed: displayAddEditModal,
    ongoing: (slot: PatientSlot) => setSlotOnView(slot),
    filled: undefined,
  };

  const proposedCount = useMemo(() => {
    // Count proposed slots for current week
    return Array(7)
      .fill(null)
      .map((_, index) => {
        const date = new Date(activeStartDate);
        date.setDate(date.getDate() + index);
        return selectedDatesMap.get(date.toDateString())?.filter((slot) => slot.status === "proposed").length || 0;
      })
      .reduce((acc, count) => acc + count, 0);
  }, [selectedDatesMap, activeStartDate]);

  const handleAutoFillCancel = () => {
    const newSelectedMap = new Map(selectedDatesMap);
    // Clear proposed slots for current week
    Array(7)
      .fill(null)
      .forEach((_, index) => {
        const date = new Date(activeStartDate);
        date.setDate(date.getDate() + index);
        newSelectedMap.set(
          date.toDateString(),
          newSelectedMap.get(date.toDateString())?.filter((slot) => slot.status !== "proposed") || []
        );
      });
    dispatch(setSelectedDatesMap(newSelectedMap));
    dispatch(setAutoFillInProgress(false));
  };

  const handleAutoFillSubmit = () => {
    const filledSlots: PatientSlot[] = [];
    Array(7)
      .fill(null)
      .forEach((_, index) => {
        const date = new Date(activeStartDate);
        date.setDate(date.getDate() + index);
        filledSlots.push(
          ...(selectedDatesMap.get(date.toDateString())?.filter((slot) => slot.status === "proposed") || [])
        );
      });
    dispatch(
      createWaitlistRunBatch({
        token: user?.token || "",
        params: {
          batchWaitlistRunParams: filledSlots.map((slot) => {
            const startDate = new Date(slot.date);
            startDate.setHours(convertHourMinutesToDecimal(slot.start));

            return {
              appointmentDate: startDate.toISOString(),
              ehrParams: {
                appointmentTypeId:
                  slot.proposedAppointmentType?.id === "*" ? undefined : slot.proposedAppointmentType?.id,
                appointmentTypeName:
                  slot.proposedAppointmentType?.id === "*" ? undefined : slot.proposedAppointmentType?.name,
                duration: calculatePeriodDifferenceInHours(slot) * 60,
                contactType: slot.proposedAppointmentMode || "",
                providerId: currentProvider?.id || "",
                pcpName: `${currentProvider?.firstName || ""} ${currentProvider?.lastName || ""}`.trim(),
                ehr: "healthie",
              },
            };
          }),
        },
        onComplete: () => {
          const newSelectedMap = new Map(selectedDatesMap);
          // Update proposed slots for current week to ongoing
          Array(7)
            .fill(null)
            .forEach((_, index) => {
              const date = new Date(activeStartDate);
              date.setDate(date.getDate() + index);
              newSelectedMap.set(
                date.toDateString(),
                newSelectedMap
                  .get(date.toDateString())
                  ?.map((slot) => (slot.status === "proposed" ? { ...slot, status: "ongoing" } : slot)) || []
              );
            });
          dispatch(setSelectedDatesMap(newSelectedMap));
          dispatch(setAutoFillInProgress(false));
        },
      })
    );
  };

  return (
    <div className={styles["hours-view-grid-wrapper"]}>
      <div className={styles["hours-view-grid"]}>
        <div className={styles["hours-view-grid-header"]}>
          <div className={styles["hours-view-grid-header-label"]}>Time/Date</div>
          {Array(7)
            .fill(null)
            .map((_, index) => {
              const date = new Date(activeStartDate);
              date.setDate(date.getDate() + index);
              return (
                <div
                  className={[
                    styles["hours-view-grid-header-item"],
                    date.toDateString() === new Date().toDateString() ? styles.today : "",
                  ].join(" ")}
                  key={index}
                >
                  <span className={styles["hours-view-grid-header-item-weekday"]}>
                    {date.toLocaleDateString("en-US", { weekday: "short" })}
                  </span>
                  <span className={styles["hours-view-grid-header-item-date"]}>
                    {date.getMonth() + 1}/{date.getDate()}
                  </span>
                </div>
              );
            })}
        </div>
        <div className={styles["hours-view-grid-body"]}>
          <div className={styles["hours-view-grid-body-selected-slots-container"]}>
            {Array(7)
              .fill(null)
              .map((_, index) => {
                const date = new Date(activeStartDate);
                date.setDate(date.getDate() + index);
                const selectedSlots = selectedDatesMap.get(date.toDateString());
                return (
                  <div key={index} className={styles["hours-view-grid-body-selected-slots"]}>
                    {selectedSlots?.map((slot) => {
                      const is30minSlot = calculatePeriodDifferenceInHours(slot) === 0.5;
                      const isMoreThanHourSlot = !is30minSlot && calculatePeriodDifferenceInHours(slot) > 1;
                      return (
                        <button
                          key={slot.start}
                          className={[
                            styles["hours-view-grid-body-selected-slot"],
                            styles[slot.status],
                            is30minSlot ? styles.half : "",
                            slot.source === "ai" && slot.status === "filled" ? styles["slot-filled-ai"] : "",
                          ].join(" ")}
                          style={{
                            top: `${((24 + convertHourMinutesToDecimal(slot.start) - gridStart) % 24) * 4}rem`,
                            height: `${calculatePeriodDifferenceInHours(slot) * 4}rem`,
                          }}
                          onClick={() => statusHandlerMap[slot.status]?.(slot)}
                        >
                          <span className={styles["slot-patient-name"]}>
                            {slot.status === "filled" ? (
                              <span className={styles["slot-patient-name-text"]}>{slot.patientName}</span>
                            ) : (
                              <span className={styles["slot-patient-name-text"]}>
                                {slot.proposedAppointmentType?.id === "*"
                                  ? "Any appointment"
                                  : slot.proposedAppointmentType?.name}
                              </span>
                            )}
                            {is30minSlot && slot.status !== "filled" && (
                              <img
                                src={slot.agent?.avatarUrl || "/admin.svg"}
                                className={styles["slot-requester-icon"]}
                                alt="requester"
                              />
                            )}
                          </span>
                          {!is30minSlot && (
                            <>
                              <span className={styles["slot-time"]}>
                                {convert24HourMinutesTo12HourMinutes(slot.start)} -{" "}
                                {convert24HourMinutesTo12HourMinutes(slot.end)}
                              </span>
                              {slot.status === "filled" ? (
                                <span className={styles["slot-appointment-type"]}>{slot.appointmentType?.name}</span>
                              ) : (
                                <span className="flex align-center gap-sm">
                                  <img
                                    src={slot.agent?.avatarUrl || "/admin.svg"}
                                    className="app-icon x-small"
                                    alt="requester"
                                  />
                                  <span className={styles.dot} />
                                  <span className={styles["slot-status"]}>{slot.status}</span>
                                </span>
                              )}
                            </>
                          )}
                          {slot.status === "ongoing" && slot.agent && (
                            <div className={styles["selected-slot-tooltip"]}>
                              <span>{slot.agent?.name} is accessing and contacting waitlist patients</span>
                            </div>
                          )}
                          {isMoreThanHourSlot && slot.source === "ai" && slot.status === "filled" && (
                            <span className={styles["slot-filled-label"]}>Filled</span>
                          )}
                        </button>
                      );
                    })}
                  </div>
                );
              })}
          </div>
          {gridPeriods.map((periods, index) => {
            return (
              <div key={index}>
                {periods.map((hourPeriod, hourPeriodIndex) => {
                  let hourRender = "";
                  const date = new Date();
                  if (hourPeriodIndex === 0) {
                    date.setHours(gridStart + index);
                    date.setMinutes(0);
                    hourRender = date.toLocaleTimeString("en-US", {
                      hour: timeMode === "12" ? "numeric" : "2-digit",
                      hour12: timeMode === "12",
                    });
                  }
                  return (
                    <div className={styles["hours-view-grid-row"]} key={hourPeriodIndex}>
                      <div
                        className={[
                          styles["hours-view-grid-period-label-container"],
                          hourPeriodIndex === 0 ? styles.full : "",
                          index === gridPeriods.length - 1 ? styles.last : "",
                        ].join(" ")}
                      >
                        <span className={styles["hours-view-grid-period-label"]}>{hourRender}</span>
                      </div>
                      <div className={styles["hours-view-grid-row-right"]}>
                        <div className={styles["hours-view-grid-period-divider"]} />
                        <div className={styles["hours-view-grid-period-buttons"]}>
                          {Array(7)
                            .fill(null)
                            .map((_, buttonIndex) => {
                              const date = new Date(activeStartDate);
                              date.setDate(date.getDate() + buttonIndex);
                              date.setHours(gridStart + index);
                              date.setMinutes(hourPeriodIndex === 0 ? 0 : 30);
                              const dateString = date.toDateString();
                              const isDragging =
                                dragProgress?.day === dateString &&
                                dragProgress?.periods.some(
                                  (period) => period.start === hourPeriod.start && period.end === hourPeriod.end
                                );

                              return (
                                <button
                                  className={[
                                    styles["hours-view-grid-period-button"],
                                    shouldDisableDate({
                                      date,
                                      activeStartDate,
                                    })
                                      ? styles.disabled
                                      : "",
                                    isDragging ? styles.dragging : "",
                                  ].join(" ")}
                                  disabled={shouldDisableDate({
                                    date,
                                    activeStartDate,
                                  })}
                                  key={buttonIndex}
                                  aria-label={`${dateString}-${hourPeriod.start}-${hourPeriod.end}`}
                                  onTouchStart={(e) => {
                                    if (e.touches.length === 1) {
                                      setDragProgress({
                                        day: dateString,
                                        span: 0,
                                        periods: [hourPeriod],
                                      });
                                    }
                                  }}
                                  onMouseDown={(e) => {
                                    if (e.buttons === 1) {
                                      setDragProgress({
                                        day: dateString,
                                        span: 0,
                                        periods: [hourPeriod],
                                      });
                                    }
                                  }}
                                  onMouseEnter={(e) => {
                                    if (e.buttons === 1) {
                                      handleMouseDragOver(e, {
                                        day: dateString,
                                        period: hourPeriod,
                                      });
                                    }
                                  }}
                                  onTouchMove={(e) => {
                                    if (e.touches.length === 1) {
                                      handleMouseDragOver(e, {
                                        day: dateString,
                                        period: hourPeriod,
                                      });
                                    }
                                  }}
                                  onTouchEnd={handleMouseUp}
                                  onMouseUp={handleMouseUp}
                                ></button>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <SlotViewModal
          slot={slotOnView}
          visible={Boolean(slotOnView)}
          onClose={() => setSlotOnView(null)}
          onMoreDetails={() => navigate(`/dashboard/fill-open-slots?view=fill-run`)}
        />
        <SlotAddEditModal
          onCancel={handleAddEditModalClose}
          onSubmit={handleAddEditModalSubmit}
          onFillRunRemove={handleFillRunRemove}
          slotOnEdit={slotOnEdit}
        />

        {autoFillInProgress && (
          <div className={styles["auto-fill-cta-buttons"]}>
            <Button danger type="accent" onClick={handleAutoFillCancel} className={styles["auto-fill-cta-button"]}>
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={handleAutoFillSubmit}
              className={styles["auto-fill-cta-button"]}
              loading={isCreatingWaitlistRunBatch}
            >
              Create Fill Runs ({proposedCount})
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GridView;
