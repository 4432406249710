import { useCallback, useEffect, useMemo } from "react";
import styles from "./FillOpenSlots.module.scss";
import GridView from "./GridView";
import WeekPicker from "./WeekPicker";
import ViewPicker from "./ViewPicker";
import Button from "../../components/button/Button";
import generateIcon from "../../assets/generate.svg";
import Select from "../../components/select/Select";
import GlobalLoader from "./GlobalLoader";
import { waitOut } from "../../utils/faker";
import { PatientSlot, Weekday } from "../../utils/types";
import Toaster from "./Toaster";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import {
  setSelectedDatesMap,
  setIsLoadingGlobal,
  setAutoFillInProgress,
  setCurrentProvider,
  fetchProviderAppointments,
  fetchProviderAppointmentTypes,
} from "../../slices/SlotsSlice";
import { HealthieProvider } from "../../types";
import { fillAllOpenSlots } from "../../utils/timeUtils";
import FillRuns from "./FillRuns";
import FillRunCampaigns from "./FillRunCampaigns";
import { convertHourMinutesToDecimal } from "../../utils/dates";
import { TileDisabledFunc } from "../../components/calendar/utils/types";

const FillOpenSlots = () => {
  const dispatch = useAppDispatch();
  const {
    currentView,
    isLoadingGlobal,
    selectedDatesMap,
    autoFillInProgress,
    currentProvider,
    activeStartDate,
    activeDays,
    workingHours,
  } = useSelector((state: RootState) => state.slots);
  const organization = useSelector(
    (state: RootState) => state.auth.organization
  );

  const providers = useSelector(
    (state: RootState) => state.healthieIntegration.providers
  );
  const user = useSelector((state: RootState) => state.auth.user);

  const today = new Date();

  const onAutoGenerate = async () => {
    dispatch(setIsLoadingGlobal(true));
    await waitOut(1000);
    
    // Check if activeStartDate is in the same week as today
    const activeStartDateIsInCurrentWeek = (() => {
      // Get the start of the week for both dates (Sunday = 0)
      const todayWeekStart = new Date(today);
      todayWeekStart.setDate(today.getDate() - today.getDay());
      todayWeekStart.setHours(0, 0, 0, 0);
      
      const activeWeekStart = new Date(activeStartDate);
      activeWeekStart.setDate(activeStartDate.getDate() - activeStartDate.getDay());
      activeWeekStart.setHours(0, 0, 0, 0);
      
      // Compare the week start dates
      return todayWeekStart.getTime() === activeWeekStart.getTime();
    })();

    const filledSlots: PatientSlot[] = fillAllOpenSlots({
      currentSelectionMap: selectedDatesMap,
      startDate: activeStartDateIsInCurrentWeek ? today : activeStartDate,
      organization,
    });
    const updatedDatesMap = new Map(selectedDatesMap);
    filledSlots.forEach((slot) => {
      updatedDatesMap.set(slot.date, [
        ...(updatedDatesMap.get(slot.date) || []),
        slot,
      ]);
    });
    dispatch(setSelectedDatesMap(updatedDatesMap));
    dispatch(setIsLoadingGlobal(false));
    dispatch(setAutoFillInProgress(true));
  };

  const providerOptions = useMemo(() => {
    return providers.map((provider) => ({
      label: `${provider.firstName} ${provider.lastName}`,
      value: provider.id,
    }));
  }, [providers]);

  useEffect(() => {
    if (providers[0] && !currentProvider) {
      dispatch(setCurrentProvider(providers[0]));
    }
  }, [providerOptions]);

  useEffect(() => {
    if (currentProvider && user && user.token) {
      const endDate = new Date(activeStartDate);
      endDate.setDate(endDate.getDate() + 7);

      dispatch(
        fetchProviderAppointments({
          token: user.token,
          providerId: currentProvider.id,
          startDate: activeStartDate.toISOString(),
          endDate: endDate.toISOString(),
        })
      );
      dispatch(
        fetchProviderAppointmentTypes({
          token: user.token,
          providerId: currentProvider.id,
        })
      );
    }
  }, [currentProvider, user, activeStartDate]);

  const shouldDisableDate: TileDisabledFunc = useCallback(({ date }) => {
    const currentHour = date.getHours();
    const isActiveDay = activeDays.includes(date.toLocaleDateString("en-US", { weekday: "long" }).toLowerCase() as Weekday);
    if (!isActiveDay) {
      return true;
    }
    const start = convertHourMinutesToDecimal(workingHours.start);
    const end = convertHourMinutesToDecimal(workingHours.end);
    const isWorkingHour = start <= currentHour && end >= currentHour;
    return !isWorkingHour;
  }, [workingHours, activeDays]);

  const canAutoFill = useMemo(() => {
    let canAutoFill = false;
    for (let i = 0; i < 7; i++) {
      const date = new Date(activeStartDate);
      date.setDate(date.getDate() + i);
      canAutoFill = !shouldDisableDate({ date, activeStartDate });
      if (canAutoFill) {
        break;
      }
    }
    return canAutoFill;
  }, [currentProvider, user, selectedDatesMap, activeStartDate]);

  return (
    <main className={styles.main}>
      <ViewPicker />
      {currentView === "calendar" && (
        <div className={styles["calendar-header"]}>
          <WeekPicker />
          <Select
            options={providerOptions}
            value={currentProvider?.id || ""}
            placeholder="Select Provider"
            onChange={(value) =>
              dispatch(
                setCurrentProvider(
                  providers.find(
                    (provider) => provider.id === (value as string)
                  ) as HealthieProvider
                )
              )
            }
          />
          <Button
            className={[
              styles["auto-fill-button"],
              autoFillInProgress || !canAutoFill ? styles.disabled : "",
            ].join(" ")}
            type="accent"
            onClick={onAutoGenerate}
            disabled={autoFillInProgress || !canAutoFill}
          >
            <img
              className="app-icon small"
              src={generateIcon}
              alt="auto fill"
            />
            Create Fill Run Campaign
          </Button>
        </div>
      )}
      {currentView === "calendar" && (
        <GridView shouldDisableDate={shouldDisableDate} />
      )}
      {currentView === "fill-run" && <FillRuns />}
      {currentView === "fill-run-campaign" && <FillRunCampaigns />}
      <GlobalLoader visible={isLoadingGlobal} />
      <Toaster />
    </main>
  );
};

export default FillOpenSlots;
