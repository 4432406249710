import { Box, Tooltip, Typography } from "@mui/material";
import { Colors } from "../../Colors";
import { useEffect, useMemo } from "react";
import { useAppDispatch } from "../../store";
import {
  fetchWaitlistRunBatchesForOrganization,
  fetchWaitlistRunsForWaitlistRunBatch,
} from "../../slices/SlotsSlice";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { WaitlistRunBatch } from "../../types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import LoadingWithMessage from "../../subcomponents/LoadingWithMessage";
import { WaitlistRunRow } from "../AgentInfoPage";

const tabBoxStyles = {
  backgroundColor: Colors.bg2,
  borderRadius: "15px",
  padding: "20px",
  width: "100%",
  height: "100%",
  marginTop: "20px",
};

const FillRunCampaigns = () => {
  const dispatch = useAppDispatch();
  const user = useSelector((state: RootState) => state.auth.user);
  const {
    organizationWaitlistRunBatches: waitlistRunBatches,
    isLoadingOrganizationWaitlistRunBatches: loadingBatches,
    batchIdWaitlistRunMap,
    isLoadingBatchIdWaitlistRunMap
  } = useSelector((state: RootState) => state.slots);
  useEffect(() => {
    if (user?.token) {
      dispatch(fetchWaitlistRunBatchesForOrganization({ token: user.token }));
    }
  }, []);

  const { search } = useLocation();
  const runBatchOnView = useMemo(() => {
    const params = new URLSearchParams(search);
    const view = params.get("view");
    if (view === "fill-run-campaign") {
      const batchId = params.get("batchId");
      return waitlistRunBatches.find(
        (waitlistRunBatch) => waitlistRunBatch.waitlistRunBatchId === batchId
      );
    }
    return null;
  }, [search]);

  useEffect(() => {
    if (
      runBatchOnView &&
      !batchIdWaitlistRunMap[runBatchOnView.waitlistRunBatchId]
    ) {
      dispatch(
        fetchWaitlistRunsForWaitlistRunBatch({
          token: user?.token as string,
          waitlistRunBatchId: runBatchOnView.waitlistRunBatchId,
        })
      );
    }
  }, [runBatchOnView]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
      <Box sx={tabBoxStyles}>
        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection="column"
          sx={{ marginBottom: "15px" }}
        >
          <Typography marginBottom={2} variant="h6">
            Fill Run Campaigns
          </Typography>
          {loadingBatches && (
            <LoadingWithMessage
              message="Loading run campaigns..."
              size={40}
              customStyles={{ marginTop: "20px" }}
            />
          )}
          {waitlistRunBatches.map((waitlistRunBatch) => (
            <WaitlistBatchRenderer
              key={waitlistRunBatch.waitlistRunBatchId}
              waitlistRunBatch={waitlistRunBatch}
              isActive={waitlistRunBatch.waitlistRunBatchId === runBatchOnView?.waitlistRunBatchId}
            />
          ))}
          {waitlistRunBatches.length === 0 && !loadingBatches && (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: Colors.info }}
            >
              No waitlist run campaigns created yet
            </Typography>
          )}
        </Box>
      </Box>
      {runBatchOnView &&  (
        <Box sx={tabBoxStyles}>
          <Typography variant="h6" marginBottom={1}>
            {runBatchOnView.waitlistRunIds.length} runs for campaign
          </Typography>
          {isLoadingBatchIdWaitlistRunMap && (
            <LoadingWithMessage
              message="Loading runs for campaign..."
              size={40}
              customStyles={{ marginTop: "20px" }}
            />
          )}
          {batchIdWaitlistRunMap[runBatchOnView.waitlistRunBatchId]?.map(
            (waitlistRun) => <WaitlistRunRow run={waitlistRun} agentId={waitlistRun.agentId} />
          )}
        </Box>
      )}
    </Box>
  );
};

export const WaitlistBatchRenderer: React.FC<{
  waitlistRunBatch: WaitlistRunBatch;
  isActive: boolean;
}> = ({ waitlistRunBatch, isActive }) => {
  const createdAt: DateTime = DateTime.fromISO(waitlistRunBatch.createdAt, {
    setZone: true,
  });
  const createdAtDay = createdAt.toFormat("EEE");
  const createdAtDate = createdAt.toFormat("MMM d");
  const createdAtTime = createdAt.toFormat(
    createdAt.minute === 0 ? "h a" : "h:mm a"
  );

  return (
    <Link
      to={`/dashboard/fill-open-slots?view=fill-run-campaign&batchId=${waitlistRunBatch.waitlistRunBatchId}`}
      style={{
        display: "block",
        marginBottom: "20px",
        backgroundColor: isActive ? Colors.blue1 : Colors.textfield,
        borderRadius: "15px",
        cursor: "pointer",
        color: "inherit",
        textDecoration: "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          borderRadius: "15px",
          padding: "10px 15px",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
          transition: "background-color 0.2s ease-in-out",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" fontSize={"0.9rem"}>
            {createdAtDate}, {createdAtTime} ({createdAtDay})
          </Typography>
          <Typography variant="body1" fontSize={"0.9rem"}>
            BATCH
          </Typography>
          <Typography variant="body1" fontSize={"0.9rem"}>
            with {waitlistRunBatch.ehr}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
          }}
        >
          <Box
            sx={{
              borderRadius: "15px",
              padding: "4px 10px",
              background: `rgba(0, 0, 0, 0.1)`,
              width: "fit-content",
            }}
          >
            <Typography
              variant="body2"
              style={{ display: "flex", alignItems: "center" }}
            >
              {waitlistRunBatch.waitlistRunIds.length} runs
            </Typography>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default FillRunCampaigns;
