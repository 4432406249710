import styles from "./FillOpenSlots.module.scss";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import checkCircle from "../../assets/check-circle.svg";
import infoCircle from "../../assets/info-circle.svg";
import timesRed from "../../assets/times-circle-red.svg";
import { useEffect } from "react";
import { clearToast } from "../../slices/SlotsSlice";

const typeIconMap = {
  error: timesRed,
  info: infoCircle,
  success: checkCircle,
};

const MAX_TOAST_DURATION = 8000;

const Toaster = () => {
  const { toastMessageConfig } = useSelector((state: RootState) => state.slots);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Set the toast message config to null after maximum duration passed
    if (toastMessageConfig) {
      setTimeout(() => {
        dispatch(clearToast());
      }, MAX_TOAST_DURATION);
    }
  }, [toastMessageConfig]);

  return (
    <div
      className={[
        styles.toaster,
        toastMessageConfig ? styles.visible : "",
        styles[toastMessageConfig?.type || "info"],
      ].join(" ")}
    >
      <div className="flex align-center gap-sm">
        <img
          src={typeIconMap[toastMessageConfig?.type || "info"]}
          alt="info"
          className="app-icon small"
        />
        <span>{toastMessageConfig?.message}</span>
      </div>
      {toastMessageConfig?.followUpConfig && (
        <button
          className={styles["follow-up-button"]}
          onClick={toastMessageConfig.followUpConfig.onButtonClick}
        >
          {toastMessageConfig.followUpConfig.buttonText}
        </button>
      )}
    </div>
  );
};

export default Toaster;
