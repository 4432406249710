// src/hooks/useToast.ts
import { useDispatch } from 'react-redux';
import { clearToast, showToast, ToastMessageConfig } from '../../slices/SlotsSlice';

export const useToast = () => {
  const dispatch = useDispatch();

  const displayToast = (config: ToastMessageConfig & { duration?: number }) => {
    dispatch(showToast(config));
    setTimeout(() => {
      dispatch(clearToast());
    }, config.duration || 4000);
  };

  return displayToast;
};