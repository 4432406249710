import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CalendarType } from "../components/calendar/utils/types";
import { AppointmentType, PatientSlot, Weekday } from "../utils/types";
import { FillOpenSlotsView } from "../utils/types";
import { HealthieProvider, WaitlistRun, WaitlistRunBatch } from "../types";
import { AxiosError } from "axios";
import axios from "axios";
import { sessionExpired } from "./SessionSlice";
import { api } from "../utils/utils";
import { Period } from "../components/calendar/utils/helpers/dates";

const DEFAULT_CALENDAR_TYPE: CalendarType = "gregory";
const DEFAULT_ACTIVE_DAYS: Weekday[] = ["monday", "tuesday", "wednesday", "thursday", "friday"];
const DEFAULT_GRID_START: number = 6;
const DEFAULT_WORKING_HOURS: Period = {
  start: "09:00",
  end: "17:00",
};

interface CalendarSettings {
  gridStart: number;
  activeDays: Weekday[];
  workingHours: {
    start: string;
    end: string;
  };
}

const savedCalendarSettingsStr = localStorage.getItem("calendarSettings");
const savedCalendarSettings: CalendarSettings | null = savedCalendarSettingsStr
  ? JSON.parse(savedCalendarSettingsStr)
  : null;

const syncCalendarSettingsToLocalStorage = (calendarSettings: CalendarSettings) => {
  localStorage.setItem("calendarSettings", JSON.stringify(calendarSettings));
};

const getStartOfWeek = (calendarType: CalendarType) => {
  if (calendarType === "islamic") {
    return new Date().getDate() - new Date().getDay() - 1;
  }
  if (calendarType === "iso8601") {
    return new Date().getDate() - new Date().getDay() + 1;
  }

  return new Date().getDate() - new Date().getDay();
};

const defaultActiveStartDate = new Date();
defaultActiveStartDate.setFullYear(new Date().getFullYear());
defaultActiveStartDate.setMonth(new Date().getMonth());
defaultActiveStartDate.setDate(getStartOfWeek(DEFAULT_CALENDAR_TYPE));

export interface ToastMessageConfig {
  message: string;
  type: "error" | "success" | "info";
  followUpConfig?: {
    buttonText: string;
    onButtonClick: () => void;
  };
}

export interface SlotsState {
  currentView: FillOpenSlotsView;
  activeStartDate: Date;
  locale: string;
  selectedDatesMap: Map<string, PatientSlot[]>;
  autoFillInProgress: boolean;
  isLoadingGlobal: boolean;
  toastMessageConfig: ToastMessageConfig | null;
  currentProvider: HealthieProvider | null;
  isLoadingAppointmentTypes: boolean;
  appointmentTypes: AppointmentType[];
  isCreatingWaitlistRun: boolean;
  isCreatingWaitlistRunBatch: boolean;
  organizationWaitlistRuns: WaitlistRun[];
  isLoadingOrganizationWaitlistRuns: boolean;
  organizationWaitlistRunBatches: WaitlistRunBatch[];
  isLoadingOrganizationWaitlistRunBatches: boolean;
  batchIdWaitlistRunMap: Record<string, WaitlistRun[]>;
  isLoadingBatchIdWaitlistRunMap: boolean;

  gridStart: number;
  activeDays: Array<Weekday>;
  workingHours: {
    start: string;
    end: string;
  };
}

const initialState: SlotsState = {
  currentView: "calendar",
  activeStartDate: defaultActiveStartDate,
  locale: "en-US",
  selectedDatesMap: new Map(),
  autoFillInProgress: false,
  isLoadingGlobal: false,
  toastMessageConfig: null,
  currentProvider: null,
  appointmentTypes: [],
  isLoadingAppointmentTypes: false,
  isCreatingWaitlistRun: false,
  isCreatingWaitlistRunBatch: false,
  organizationWaitlistRuns: [],
  isLoadingOrganizationWaitlistRuns: false,
  organizationWaitlistRunBatches: [],
  isLoadingOrganizationWaitlistRunBatches: false,
  batchIdWaitlistRunMap: {},
  isLoadingBatchIdWaitlistRunMap: false,
  gridStart: savedCalendarSettings?.gridStart || DEFAULT_GRID_START,
  activeDays: savedCalendarSettings?.activeDays || DEFAULT_ACTIVE_DAYS,
  workingHours: savedCalendarSettings?.workingHours || DEFAULT_WORKING_HOURS,
};

export const fetchProviderAppointments = createAsyncThunk<
  { appointments: PatientSlot[] },
  { token: string; providerId: string; startDate: string; endDate: string },
  { rejectValue: Error }
>("slots/fetchProviderAppointments", async ({ token, ...params }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    const { agents } = state.agents;
    const queryParams = new URLSearchParams(params);
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments?${queryParams.toString()}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const appointments = response.data.appointments.map(
      (appointment: any) =>
        ({
          ...appointment,
          agent: agents.find((agent) => agent.agentId === appointment.agentId),
        } as PatientSlot)
    );
    return { appointments };
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch provider appointments: " + (error as Error).message));
  }
});

export const fetchWaitlistRunBatchesForOrganization = createAsyncThunk<
  { waitlistRunBatches: WaitlistRunBatch[] },
  { token: string },
  { rejectValue: Error }
>("waitlist/fetchWaitlistRunBatchesForOrganization", async ({ token }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    const organizationId = state.auth.organization?.organizationId;
    const response = await api.get(`/waitlist/organization/${organizationId}/runs-batch`, token);
    return { waitlistRunBatches: response.data };
  } catch (error) {
    return rejectWithValue(new Error("Failed to fetch waitlist run batches."));
  }
});

export const fetchWaitlistRunsForWaitlistRunBatch = createAsyncThunk<
  { waitlistRuns: WaitlistRun[]; waitlistRunBatchId: string },
  { token: string; waitlistRunBatchId: string },
  { rejectValue: Error }
>("waitlist/fetchWaitlistRunsForWaitlistRunBatch", async ({ token, waitlistRunBatchId }, { rejectWithValue }) => {
  try {
    const response = await api.get(`/waitlist/waitlist-run-batch/${waitlistRunBatchId}/runs`, token);
    return { waitlistRuns: response.data, waitlistRunBatchId };
  } catch (error) {
    return rejectWithValue(new Error("Failed to fetch waitlist runs for waitlist run batch."));
  }
});

export const fetchWaitlistRunsForOrganization = createAsyncThunk<
  { waitlistRuns: WaitlistRun[] },
  { token: string },
  { rejectValue: Error }
>("waitlist/fetchWaitlistRunsForOrganization", async ({ token }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    const organizationId = state.auth.organization?.organizationId;
    const response = await api.get(`/waitlist/organization/${organizationId}/runs`, token);

    if (response.data) {
      return { waitlistRuns: response.data };
    }
    throw new Error("Invalid response data");
  } catch (error: any) {
    if (error.response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch waitlist runs."));
  }
});

export const fetchProviderAppointmentTypes = createAsyncThunk<
  { appointmentTypes: AppointmentType[] },
  { token: string; providerId: string },
  { rejectValue: Error }
>("slots/fetchProviderAppointmentTypes", async ({ token, providerId }, { rejectWithValue }) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/appointments/types?providerId=${providerId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return { appointmentTypes: response.data.appointmentTypes };
  } catch (error) {
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch provider appointment types: " + (error as Error).message));
  }
});

type HealthieWaitlistParams = {
  canceledAppointmentId?: string;
  providerId: string;
  pcpName: string;
  appointmentTypeId?: string;
  duration?: number;
  appointmentTypeName?: string;
  contactType: string;
  location?: string;
  ehr: "healthie";
};

export interface FillOpenSlotsParams {
  appointmentDate: string;
  ehrParams: HealthieWaitlistParams;
}

export const createWaitlistRunBatch = createAsyncThunk<
  void,
  { token: string; params: { batchWaitlistRunParams: FillOpenSlotsParams[] }; onComplete: () => void },
  { rejectValue: Error }
>("waitlist/createWaitlistRunBatch", async ({ token, params, onComplete }, { rejectWithValue, getState }) => {
  try {
    const state = getState() as RootState;
    const agentId = state.agents.agents.find((agent) => agent.ehr === "healthie")?.agentId;
    const response = await api.post(`/waitlist/${agentId}/runs-batch`, token, params);
    if (response.status === 200) {
      onComplete();
    } else {
      throw new Error(response.data.error);
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error(error.response?.data?.error || "Unknown error"));
  }
});

export const createFillOpenSlotsRun = createAsyncThunk<
  void,
  { token: string; params: FillOpenSlotsParams; onComplete: () => void },
  { rejectValue: Error }
>("slots/createFillOpenSlotsRun", async ({ token, params, onComplete }, { getState, rejectWithValue }) => {
  try {
    const state = getState() as RootState;
    const agentId = state.agents.agents.find((agent) => agent.ehr === "healthie")?.agentId;

    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/${agentId}/runs`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status !== 200) {
      throw new Error(response.data.error);
    }
    onComplete();
  } catch (error: any) {
    if (error.response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error(error.response?.data?.error || "Unknown error"));
  }
});

const slotsSlice = createSlice({
  name: "slots",
  initialState,
  reducers: {
    setCurrentView: (state, action: PayloadAction<FillOpenSlotsView>) => {
      state.currentView = action.payload;
    },
    setActiveStartDate: (state, action: PayloadAction<Date>) => {
      state.activeStartDate = action.payload;
    },
    setGridStart: (state, action: PayloadAction<number>) => {
      state.gridStart = action.payload;
      syncCalendarSettingsToLocalStorage({
        gridStart: action.payload,
        activeDays: state.activeDays,
        workingHours: state.workingHours,
      });
    },
    setActiveDays: (state, action: PayloadAction<Weekday[]>) => {
      state.activeDays = action.payload;
      syncCalendarSettingsToLocalStorage({
        gridStart: state.gridStart,
        activeDays: action.payload,
        workingHours: state.workingHours,
      });
    },
    setWorkingHours: (state, action: PayloadAction<{ start: string; end: string }>) => {
      state.workingHours = action.payload;
      syncCalendarSettingsToLocalStorage({
        gridStart: state.gridStart,
        activeDays: state.activeDays,
        workingHours: action.payload,
      });
    },
    setLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    setSelectedDatesMap: (state, action: PayloadAction<Map<string, PatientSlot[]>>) => {
      state.selectedDatesMap = action.payload;
    },
    setAutoFillInProgress: (state, action: PayloadAction<boolean>) => {
      state.autoFillInProgress = action.payload;
    },
    setIsLoadingGlobal: (state, action: PayloadAction<boolean>) => {
      state.isLoadingGlobal = action.payload;
    },
    showToast: (state, action: PayloadAction<ToastMessageConfig & { duration?: number }>) => {
      const { duration, ...toastConfig } = action.payload;
      state.toastMessageConfig = toastConfig;

      // Note: The setTimeout functionality should be handled in the component or a middleware
      // since Redux reducers should be pure functions
    },
    clearToast: (state) => {
      state.toastMessageConfig = null;
    },
    setCurrentProvider: (state, action: PayloadAction<HealthieProvider>) => {
      state.currentProvider = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProviderAppointments.pending, (state) => {
      state.isLoadingGlobal = true;
    });
    builder.addCase(fetchProviderAppointments.fulfilled, (state, action) => {
      state.isLoadingGlobal = false;
      const newSelectedDatesMap = new Map();
      action.payload.appointments.forEach((appointment) => {
        newSelectedDatesMap.set(appointment.date, [...(newSelectedDatesMap.get(appointment.date) || []), appointment]);
      });
      state.selectedDatesMap = newSelectedDatesMap;
    });
    builder.addCase(fetchProviderAppointments.rejected, (state, action) => {
      state.isLoadingGlobal = false;
      state.toastMessageConfig = {
        message: `Failed to fetch provider appointments: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
    builder.addCase(fetchProviderAppointmentTypes.fulfilled, (state, action) => {
      state.isLoadingAppointmentTypes = false;
      state.appointmentTypes = action.payload.appointmentTypes;
    });
    builder.addCase(fetchProviderAppointmentTypes.rejected, (state, action) => {
      state.isLoadingAppointmentTypes = false;
      state.toastMessageConfig = {
        message: `Failed to fetch provider appointment types: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
    builder.addCase(fetchProviderAppointmentTypes.pending, (state) => {
      state.isLoadingAppointmentTypes = true;
    });
    builder.addCase(createFillOpenSlotsRun.pending, (state) => {
      state.isCreatingWaitlistRun = true;
    });
    builder.addCase(createFillOpenSlotsRun.fulfilled, (state) => {
      state.isCreatingWaitlistRun = false;
    });
    builder.addCase(createFillOpenSlotsRun.rejected, (state, action) => {
      state.isCreatingWaitlistRun = false;
      console.log({ action });
      state.toastMessageConfig = {
        message: `Failed to create waitlist run: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
    builder.addCase(fetchWaitlistRunsForOrganization.pending, (state) => {
      state.isLoadingOrganizationWaitlistRuns = true;
      state.organizationWaitlistRuns = [];
    });
    builder.addCase(fetchWaitlistRunsForOrganization.fulfilled, (state, action) => {
      state.isLoadingOrganizationWaitlistRuns = false;
      state.organizationWaitlistRuns = action.payload.waitlistRuns;
    });
    builder.addCase(fetchWaitlistRunsForOrganization.rejected, (state, action) => {
      state.isLoadingOrganizationWaitlistRuns = false;
      state.toastMessageConfig = {
        message: `Failed to fetch waitlist runs: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
    builder.addCase(fetchWaitlistRunBatchesForOrganization.pending, (state) => {
      state.isLoadingOrganizationWaitlistRunBatches = true;
      state.organizationWaitlistRunBatches = [];
    });
    builder.addCase(fetchWaitlistRunBatchesForOrganization.fulfilled, (state, action) => {
      state.isLoadingOrganizationWaitlistRunBatches = false;
      state.organizationWaitlistRunBatches = action.payload.waitlistRunBatches;
    });
    builder.addCase(fetchWaitlistRunBatchesForOrganization.rejected, (state, action) => {
      state.isLoadingOrganizationWaitlistRunBatches = false;
      state.toastMessageConfig = {
        message: `Failed to fetch waitlist run batches: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
    builder.addCase(fetchWaitlistRunsForWaitlistRunBatch.pending, (state) => {
      state.isLoadingBatchIdWaitlistRunMap = true;
      state.batchIdWaitlistRunMap = {};
    });
    builder.addCase(fetchWaitlistRunsForWaitlistRunBatch.fulfilled, (state, action) => {
      state.isLoadingBatchIdWaitlistRunMap = false;
      state.batchIdWaitlistRunMap = {
        ...state.batchIdWaitlistRunMap,
        [action.payload.waitlistRunBatchId]: action.payload.waitlistRuns,
      };
    });
    builder.addCase(fetchWaitlistRunsForWaitlistRunBatch.rejected, (state, action) => {
      state.isLoadingBatchIdWaitlistRunMap = false;
      state.toastMessageConfig = {
        message: `Failed to fetch waitlist runs for waitlist run batch: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
    builder.addCase(createWaitlistRunBatch.pending, (state) => {
      state.isCreatingWaitlistRunBatch = true;
    });
    builder.addCase(createWaitlistRunBatch.fulfilled, (state) => {
      state.isCreatingWaitlistRunBatch = false;
    });
    builder.addCase(createWaitlistRunBatch.rejected, (state, action) => {
      state.isCreatingWaitlistRunBatch = false;
      state.toastMessageConfig = {
        message: `Failed to create waitlist run batch: ${action.payload?.message || "Unknown error"}`,
        type: "error",
      };
    });
  },
});

export const {
  setCurrentView,
  setActiveStartDate,
  setLocale,
  setGridStart,
  setActiveDays,
  setWorkingHours,
  setSelectedDatesMap,
  setAutoFillInProgress,
  setIsLoadingGlobal,
  showToast,
  clearToast,
  setCurrentProvider,
} = slotsSlice.actions;

export const selectSlots = (state: RootState) => state.slots;

export default slotsSlice.reducer;
