import { FunctionComponent } from "react";
import Modal from "../../components/modal/Modal";
import styles from "./FillOpenSlots.module.scss";
import Select from "../../components/select/Select";
import { activeDaysOptions, gridStartOptions, timeOptions } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { setActiveDays, setWorkingHours, setGridStart } from "../../slices/SlotsSlice";
import { Weekday } from "../../utils/types";

const SettingsModal: FunctionComponent<{ visible: boolean; onClose: () => void }> = ({
  visible,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { activeDays, workingHours, gridStart } = useSelector((state: RootState) => state.slots);

  return (
    <Modal visible={visible} onClose={onClose}>
      <div className={styles["settings-modal"]}>
        <h2>Configure Calendar</h2>
        <div className={styles["form-item-group"]}>
          <div className={styles["form-item"]}>
            <span className={styles["form-item-label"]}>Active hours start</span>
            <Select
              options={timeOptions}
              onChange={(value) => {
                dispatch(setWorkingHours({ ...workingHours, start: value as string }));
              }}
              value={workingHours.start}
              placeholder="Start Time"
              responsive
            />
          </div>
          <div className={styles["form-item"]}>
            <span className={styles["form-item-label"]}>Active hours end</span>
            <Select
              options={timeOptions}
              onChange={(value) => {
                dispatch(setWorkingHours({ ...workingHours, end: value as string }));
              }}
              value={workingHours.end}
              placeholder="End Time"
              responsive
            />
          </div>
        </div>
        <div className={styles["form-item"]}>
          <span className={styles["form-item-label"]}>Active days ({activeDays.length})</span>
          <div className={styles["days-included-options"]}>
            {activeDaysOptions.map((option) => (
              <span
                key={option.value}
                className={[styles["day-option"], activeDays.includes(option.value as Weekday) ? styles.selected : ""].join(" ")}
                onClick={() =>
                  dispatch(
                    setActiveDays(
                      activeDays.includes(option.value as Weekday)
                        ? activeDays.filter((day) => day !== option.value)
                        : [...activeDays, option.value as Weekday]
                    )
                  )
                }
              >
                {option.label[0]}
              </span>
            ))}
          </div>
        </div>

        <div className={styles["form-item"]}>
          <span className={styles["form-item-label"]}>Calendar start time</span>
          <Select
            options={gridStartOptions}
            onChange={(value) => {
              dispatch(setGridStart(value as number));
            }}
            value={gridStart}
            placeholder="Start Time"
            dropdownOnTop
          />
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;
