import { useEffect, useMemo } from "react";
import styles from "./FillOpenSlots.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import { setCurrentView } from "../../slices/SlotsSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fillOpenSlotsViewOptions } from "../../utils/constants";
import { FillOpenSlotsView } from "../../utils/types";
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useState } from "react";
import SettingsModal from "./SettingsModal";

const ViewPicker = () => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const dispatch = useDispatch();
  const { currentView, selectedDatesMap } = useSelector(
    (state: RootState) => state.slots
  );

  const { search } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const view = params.get("view");
    const viewQueryIsValid = fillOpenSlotsViewOptions.some(
      (option) => option.value === view
    );
    if (viewQueryIsValid) {
      dispatch(setCurrentView(view as FillOpenSlotsView));
    } else {
      navigate(
        `/dashboard/fill-open-slots?view=${fillOpenSlotsViewOptions[0].value}`
      );
    }
  }, [search]);

  const ongoingCount = useMemo(() => {
    return Array.from(selectedDatesMap.entries()).reduce((acc, [, slots]) => {
      return acc + slots.filter((slot) => slot.status === "ongoing").length;
    }, 0);
  }, [selectedDatesMap]);

  return (
    <div className="flex center-align gap">
      <div className={styles["view-selector"]}>
        {fillOpenSlotsViewOptions.map((option) => (
          <Link
            key={option.value}
            to={`/dashboard/fill-open-slots?view=${option.value}`}
            className={[
              styles["view-selector-button"],
              currentView === option.value ? styles.active : "",
            ].join(" ")}
          >
            {option.label}
            {option.value === "fill-run" && ongoingCount > 0 && (
              <span className={styles["ongoing-count"]}>{ongoingCount}</span>
            )}
          </Link>
        ))}
      </div>
      <IconButton className={styles["settings-button"]} onClick={() => setIsSettingsOpen(true)}>
        <SettingsIcon sx={{ color: "lightgray" }} />
      </IconButton>
      <SettingsModal visible={isSettingsOpen} onClose={() => setIsSettingsOpen(false)} />
    </div>
  );
};

export default ViewPicker;
