import { FunctionComponent, useState } from "react";
import { CALENDAR_TYPE_LOCALES, CALENDAR_TYPES } from "./constants";
import { CalendarType } from "./types";
import { CalendarProps } from "../Calendar";
import { useCalendarContext } from "./hooks/useCalendarContext";
import styles from "../Calendar.module.scss";
import leftArrowIcon from "../assets/left-arrow.svg";
import rightArrowIcon from "../assets/right-arrow.svg";
import Weekdays from "./Weekdays";
import Days from "./Days";

function getCalendarTypeFromLocale(locale: string | undefined): CalendarType {
  if (locale) {
    for (const [calendarType, locales] of Object.entries(CALENDAR_TYPE_LOCALES)) {
      if (locales.includes(locale)) {
        return calendarType as CalendarType;
      }
    }
  }

  return CALENDAR_TYPES.GREGORY;
}

const DaysView: FunctionComponent<CalendarProps & { updateExternalDates: () => void }> = (props) => {
  const {
    onDateTimesChange,
    onCancel,
    onSubmit,
    calendarType = getCalendarTypeFromLocale(props.locale),
    formatShortWeekday,
    formatWeekday,
    locale,
    activeStartDate: _activeStartDate,
    showNeighboringMonth,
    shouldDisableDate,
    disableGestureSelection,
    disableWeekdaySelection,
    inline,
    updateExternalDates,
  } = props;

  const { selectedDatesMap, setSelectedDatesMap, timezone } = useCalendarContext();

  const [activeStartDate, setActiveStartDate] = useState<Date>(
    _activeStartDate || new Date(new Date().getFullYear(), new Date().getMonth())
  );

  return (
    <div data-testid="calendar">
      <div className={styles.navigation}>
        <button
          onClick={() => setActiveStartDate(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() - 1))}
          className={styles["navigation-button"]}
        >
          <img src={leftArrowIcon} alt="Previous" />
        </button>
        {activeStartDate?.toLocaleDateString(locale, {
          month: "short",
          year: "numeric",
        })}
        <button
          onClick={() => setActiveStartDate(new Date(activeStartDate.getFullYear(), activeStartDate.getMonth() + 1))}
          className={styles["navigation-button"]}
        >
          <img src={rightArrowIcon} alt="Next" />
        </button>
      </div>
      <div className={styles["calendar-body"]}>
        <Weekdays
          calendarType={calendarType}
          formatShortWeekday={formatShortWeekday}
          formatWeekday={formatWeekday}
          locale={locale}
          activeStartDate={activeStartDate}
          shouldDisableDate={shouldDisableDate}
          disableWeekdaySelection={disableWeekdaySelection}
        />
        <Days
          calendarType={calendarType}
          activeStartDate={activeStartDate}
          showNeighboringMonth={showNeighboringMonth}
          shouldDisableDate={shouldDisableDate}
          disableGestureSelection={disableGestureSelection}
        />
      </div>
      <div className={[styles.buttons, inline ? styles.inline : ""].join(" ")}>
        <button
          className={[styles.clear, selectedDatesMap.size && styles.active].join(" ")}
          onClick={() => {
            setSelectedDatesMap(new Map());
            onDateTimesChange([]);
          }}
          type="button"
        >
          {inline ? "Clear selection" : "Clear"}
        </button>
        {!inline && (
          <div className={styles["ok-cancel"]}>
            <button className={styles.cancel} type="button" onClick={onCancel}>
              Cancel
            </button>
            <button
              className={[styles.ok, !selectedDatesMap.size && styles.disabled].join(" ")}
              disabled={!selectedDatesMap.size}
              type="button"
              onClick={updateExternalDates}
            >
              Update Dates
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DaysView;
