import styles from "./FillOpenSlots.module.scss";
import loaderIcon from "../../assets/loading.svg";

const GlobalLoader = ({
  visible,
  loadingText,
}: {
  visible: boolean;
  loadingText?: string;
}) => {
  return (
    <div
      className={[styles["loader-backdrop"], visible && styles.visible].join(
        " "
      )}
    >
      <div className={styles.loader}>
        <img src={loaderIcon} alt="loader" />
        <span>{loadingText || "One moment ..."}</span>
      </div>
    </div>
  );
};

export default GlobalLoader;
