import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { CalendarProps } from "../Calendar";
import styles from "../Calendar.module.scss";
import Select from "../../select/Select";
import { defaultGridPeriods } from "./constants";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { Period, render12HourPeriod } from "./helpers/dates";
import { useCalendarContext } from "./hooks/useCalendarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { DateTime } from "luxon";
import { daysIncludedOptions } from "../../../utils/constants";

type Interval = "days" | "weeks" | "months" | "years";

const intervalOptions: { label: string; value: Interval }[] = [
  { label: "Day", value: "days" },
  { label: "Week", value: "weeks" },
  { label: "Month", value: "months" },
  { label: "Year", value: "years" },
];

const timeOptions = defaultGridPeriods.map((periods) =>
  periods.map((period) => {
    return {
      label: render12HourPeriod(period.start),
      value: period.start,
    };
  })
);

const weeksIncludedOptions = Array.from({ length: 6 }, (_, i) => ({
  label: `Week ${i + 1}`,
  value: i + 1,
}));

const CustomRecurrence: FunctionComponent<CalendarProps> = ({ gridStart }) => {
  const [periods, setPeriods] = useState<Period[]>([{ start: "", end: "" }]);
  const [repeatEvery, setRepeatEvery] = useState<Interval>("weeks");
  const [repeatUntil, setRepeatUntil] = useState<Interval>("months");
  const [repeatUntilValue, setRepeatUntilValue] = useState(1);
  const [daysIncluded, setDaysIncluded] = useState<string[]>([]);
  const [weeksIncluded, setWeeksIncluded] = useState<number[]>([]);

  const { organization } = useSelector((state: RootState) => state.auth);

  const {
    setShouldShowCustomRecurrence,
    showToast,
    selectedDatesMap,
    setSelectedDatesMap,
    addNewPeriodAndMerge,
    customRecurrencePeriod,
  } = useCalendarContext();

  const adjustedTimeOptions = useMemo(() => {
    const workingHoursStart =
      gridStart || (organization?.workingHoursStart ? new Date(organization.workingHoursStart).getHours() : null) || 6;

    return [...timeOptions.slice(workingHoursStart), ...timeOptions.slice(0, workingHoursStart)].flat();
  }, [organization?.workingHoursStart, gridStart]);

  useEffect(() => {
    if (customRecurrencePeriod) {
      setPeriods([customRecurrencePeriod]);
    }
  }, [customRecurrencePeriod]);

  const handleAddToCalendar = () => {
    if (!daysIncluded.length) {
      showToast("Please select at least one day");
      return;
    }
    if (!repeatUntilValue || repeatUntilValue < 1) {
      showToast("Please enter a positive repeat until value");
      return;
    }

    if (repeatEvery === "months" && !weeksIncluded.length) {
      showToast("Please select at least one week");
      return;
    }

    let periodValidationMessages = "";
    for (let i = 0; i < periods.length; i++) {
      const period = periods[i];
      if (!period.start) {
        periodValidationMessages = `Please enter a start time for period ${i + 1}`;
        break;
      }
      if (!period.end) {
        periodValidationMessages = `Please enter an end time for period ${i + 1}`;
        break;
      }
      if (period.start === period.end) {
        periodValidationMessages = `Start time and end time cannot be the same for period ${i + 1}`;
        break;
      }
      const startAfterEnd = Number(period.start.replace(/\D/g, "")) > Number(period.end.replace(/\D/g, ""));
      if (startAfterEnd) {
        periodValidationMessages = `Start time must come before end time for period ${i + 1}`;
        break;
      }
    }

    if (periodValidationMessages) {
      showToast(periodValidationMessages);
      return;
    }

    const recurrenceRecords: { date: string; start: string; end: string }[] = [];

    const startDate = DateTime.now();
    const endDate = startDate.plus({ [repeatUntil]: repeatUntilValue });

    let currentDate = startDate;

    while (currentDate <= endDate) {
      daysIncluded.forEach((day) => {
        const includedInWeek = repeatEvery === "weeks" || weeksIncluded.includes(currentDate.weekNumber);
        if (includedInWeek && currentDate.weekdayLong.toLowerCase() === day) {
          periods.forEach((period) => {
            recurrenceRecords.push({
              date: currentDate.toJSDate().toDateString(),
              start: period.start,
              end: period.end,
            });
          });
        }
      });

      currentDate = currentDate.plus({ day: 1 });
    }

    const newSelectedDatesMap = new Map(selectedDatesMap);
    recurrenceRecords.forEach((record) => {
      const existingPeriods = newSelectedDatesMap.get(record.date) || [];
      const newPeriods = addNewPeriodAndMerge({
        oldPeriods: existingPeriods,
        newPeriod: record,
        gridStart: gridStart || 0,
      });
      newSelectedDatesMap.set(record.date, newPeriods);
    });

    setSelectedDatesMap(newSelectedDatesMap);
    setShouldShowCustomRecurrence(false);
  };

  return (
    <div className={styles["custom-recurrence"]}>
      <h2 className={styles["custom-recurrence-title"]}>Custom Recurrence</h2>

      <div className={styles["form-body"]}>
        {periods.map((period, index) => {
          return (
            <div className="flex between gap-sm" key={index}>
              <div className={styles["form-group"]}>
                <span className={styles.label}>Start time</span>
                <Select
                  options={adjustedTimeOptions}
                  value={period.start}
                  onChange={(value) =>
                    setPeriods(
                      periods.map((_period) => (_period === period ? { ..._period, start: value as string } : _period))
                    )
                  }
                  className={styles["form-select"]}
                  placeholder="Select start time"
                  shouldSearchOptions
                />
              </div>

              <div className={styles["form-group"]}>
                <span className={styles.label}>End time</span>
                <Select
                  options={adjustedTimeOptions}
                  value={period.end}
                  onChange={(value) =>
                    setPeriods(
                      periods.map((_period) => (_period === period ? { ..._period, end: value as string } : _period))
                    )
                  }
                  className={styles["form-select"]}
                  placeholder="Select end time"
                  shouldSearchOptions
                />
              </div>

              {periods.length > 1 && (
                <IconButton
                  onClick={() => setPeriods(periods.filter((_period) => _period !== period))}
                  sx={{ padding: 0, margin: 0 }}
                >
                  <DeleteIcon style={{ color: "darkred" }} fontSize="small" />
                </IconButton>
              )}
            </div>
          );
        })}

        <button
          className={styles["add-period-button"]}
          onClick={() => setPeriods([...periods, { start: "", end: "" }])}
        >
          Add period
        </button>

        <div className={styles["form-group"]}>
          <span className={styles.label}>Days included ({daysIncluded.length})</span>
          <div className={styles["days-included-options"]}>
            {daysIncludedOptions.map((option) => (
              <span
                key={option.value}
                className={[styles["day-option"], daysIncluded.includes(option.value) ? styles.selected : ""].join(" ")}
                onClick={() =>
                  setDaysIncluded((prev) =>
                    daysIncluded.includes(option.value)
                      ? prev.filter((day) => day !== option.value)
                      : [...prev, option.value]
                  )
                }
              >
                {option.label[0]}
              </span>
            ))}
          </div>
        </div>

        <div className="flex between gap-sm">
          <div className={styles["form-group"]}>
            <span className={styles.label}>Repeat every</span>
            <Select
              options={intervalOptions.filter((option) => !["days", "years"].includes(option.value))}
              value={repeatEvery}
              onChange={(value) => setRepeatEvery(value as Interval)}
              className={styles["form-select"]}
            />
          </div>
          {repeatEvery === "months" && (
            <div className={styles["form-group"]}>
              <span className={styles.label}>Weeks included</span>
              <Select
                options={weeksIncludedOptions}
                value={weeksIncluded}
                onChange={(value) => setWeeksIncluded(value as number[])}
                className={styles["form-select"]}
                placeholder="Weeks"
                multiple
              />
            </div>
          )}
        </div>

        <div className={styles["form-group"]}>
          <span className={styles.label}>Repeat until</span>
          <div className={styles["input-group"]}>
            <input
              type="number"
              className={styles["small-form-input"]}
              value={repeatUntilValue}
              onChange={(e) => setRepeatUntilValue(Number(e.target.value))}
            />
            <Select
              options={intervalOptions}
              value={repeatUntil}
              onChange={(value) => setRepeatUntil(value as Interval)}
              className={styles["small-form-select"]}
            />
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        <button className={styles.cancel} onClick={() => setShouldShowCustomRecurrence(false)}>
          Cancel
        </button>
        <button className={styles.ok} onClick={handleAddToCalendar}>
          Add to Calendar
        </button>
      </div>
    </div>
  );
};

export default CustomRecurrence;
