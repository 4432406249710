import { convert24HourMinutesTo12HourMinutes } from "./dates";
import { getNewId } from "./faker";
import { AppointmentType, FillOpenSlotsView, PatientSlot, SelectOption } from "./types";

export const DEFAULT_DEVELOPMENT_PHONE_NUMBER = "+19167758046";

export const providerOptions = [
  {
    label: (
      <span className="flex align-center gap-xs">
        <span className="initials" style={{ backgroundColor: "#4c3396" }}>
          H
        </span>
        <span>Dr Hizami Anuar</span>
      </span>
    ),
    value: "hizami-anuar",
  },
  {
    label: (
      <span className="flex align-center gap-xs">
        <span className="initials" style={{ backgroundColor: "#085008" }}>
          S
        </span>
        <span>Dr Shawn</span>
      </span>
    ),
    value: "shawn",
  },
  {
    label: (
      <span className="flex align-center gap-xs">
        <span className="initials" style={{ backgroundColor: "#a85f06" }}>
          M
        </span>
        <span>Dr Manuel</span>
      </span>
    ),
    value: "manuel",
  },
];

export const sampleSlotsMap = new Map<string, PatientSlot[]>([]);

export const appointmentTypes: AppointmentType[] = [
  {
    id: "*",
    name: "Any",
    duration: Infinity,
  },
  {
    id: "virtual-pelvic-pt",
    name: "Virtual Pelvic PT",
    duration: 60,
  },
  {
    id: "orthopedic-exam",
    name: "Orthopedic Exam",
    duration: 60,
  },
  {
    id: "post-surgery-follow-up",
    name: "Post-Surgery Follow-Up",
    duration: 60,
  },
  {
    id: "physical-therapy",
    name: "Physical Therapy",
    duration: 120,
  },
  {
    id: "cognitive-behavioral-therapy",
    name: "Cognitive Behavioral Therapy",
    duration: 60,
  },
  {
    id: "strength-training",
    name: "Strength Training",
    duration: 60,
  },
  {
    id: "ct-scan",
    name: "CT Scan",
    duration: 90,
  },
  {
    id: "ultrasound",
    name: "Ultrasound",
    duration: 90,
  },
  {
    id: "mri",
    name: "MRI",
    duration: 120,
  },
  {
    id: "ultrasound-pelvic",
    name: "Ultrasound Pelvic",
    duration: 90,
  },
  {
    id: "pelvic-ultrasound",
    name: "Pelvic Ultrasound",
    duration: 90,
  },
  {
    id: "pelvic-mri",
    name: "Pelvic MRI",
    duration: 120,
  },
  {
    id: "gait-analysis",
    name: "Gait Analysis",
    duration: 60,
  },
  {
    id: "lymphedema-therapy",
    name: "Lymphedema Therapy",
    duration: 60,
  },
  {
    id: "respiratory-therapy",
    name: "Respiratory Therapy",
    duration: 60,
  },
  {
    id: "nutrition-consultation",
    name: "Nutrition Consultation",
    duration: 90,
  },
  {
    id: "pain-management",
    name: "Pain Management",
    duration: 60,
  },
  {
    id: "balance-therapy",
    name: "Balance Therapy",
    duration: 60,
  },
  {
    id: "sports-rehabilitation",
    name: "Sports Rehabilitation",
    duration: 60,
  },
];

const GRID_START_HOUR = 6;

const initialTimeOptions: SelectOption[] = Array(24)
  .fill(null)
  .map((_, hourIndex) => {
    const hourPeriods = Array(2)
      .fill(null)
      .map((_, halfHourIndex) => {
        const period = halfHourIndex * 30;
        const hourString = hourIndex.toString().padStart(2, "0");
        const periodString = period.toString().padStart(2, "0");
        const value = `${hourString}:${periodString}`;
        return {
          label: convert24HourMinutesTo12HourMinutes(value).toLowerCase(),
          value,
        };
      });

    return hourPeriods;
  })
  .flat();

export const timeOptions = [
  ...initialTimeOptions.slice(GRID_START_HOUR * 2),
  ...initialTimeOptions.slice(0, GRID_START_HOUR * 2),
];

export const activeDaysOptions = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const getDurationOptions = (
  maxDurationInHours: number
): SelectOption[] =>
  Array(maxDurationInHours * 4)
    .fill(null)
    .map((_, index) => {
      const value = ((index + 1) * 15) / 60;
      return {
        label:
          value < 1
            ? `${value * 60} minutes`
            : `${Math.floor(value)} hr${Math.floor(value) === 1 ? "" : "s"} ${
                value % 1 === 0 ? "" : `${(value % 1) * 60} minutes`
              }`,
        value,
      };
    });

export const appointmentModeOptions: SelectOption[] = [
  {
    label: "In-Person",
    value: "in-person",
  },
  {
    label: "Virtual",
    value: "virtual",
  },
];

export const fillOpenSlotsViewOptions: {
  label: string;
  value: FillOpenSlotsView;
}[] = [
  {
    label: "Calendar",
    value: "calendar",
  },
  {
    label: "Fill Runs",
    value: "fill-run",
  },
  {
    label: "Fill Run Campaigns",
    value: "fill-run-campaign",
  },
];

export const startTimeOptions: SelectOption[] = timeOptions.map((option) => ({
  label: option.label,
  value: option.value,
}));

export const daysIncludedOptions = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const gridStartOptions = Array(24)
  .fill(null)
  .map((_, index) => {
    const value = `${String(index).padStart(2, "0")}:00`;
    return {
      label: convert24HourMinutesTo12HourMinutes(value).toLowerCase(),
      value: index
    };
  });

