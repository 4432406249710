import styles from "./FillOpenSlots.module.scss";
import arrowLeft from "../../assets/left-arrow.svg";
import arrowRight from "../../assets/right-arrow.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDispatch } from "react-redux";
import { setActiveStartDate } from "../../slices/SlotsSlice";

const renderWeekTitle = (startDay: Date, locale: string) => {
  const endDay = new Date(startDay);
  endDay.setDate(endDay.getDate() + 6);
  return `${startDay.toLocaleDateString(locale, {
    month: "short",
  })} ${startDay.toLocaleDateString(locale, {
    day: "numeric",
  })} - ${endDay.toLocaleDateString(locale, {
    month: "short",
  })} ${endDay.toLocaleDateString(locale, {
    day: "numeric",
  })}, ${startDay.toLocaleDateString(locale, {
    year: "numeric",
  })}`;
};

const WeekPicker = () => {
  const dispatch = useDispatch();
  const { activeStartDate } = useSelector((state: RootState) => state.slots);
  const locale = navigator.language;

  return (
    <div className={styles.navigation}>
      <button
        className={styles["navigation-button"]}
        onClick={() =>
          dispatch(
            setActiveStartDate(
              new Date(
                activeStartDate.getFullYear(),
                activeStartDate.getMonth(),
                activeStartDate.getDate() - 7
              )
            )
          )
        }
      >
        <img className="app-icon" src={arrowLeft} alt="previous week" />
      </button>
      <span className={styles["navigation-title"]}>
        {renderWeekTitle(activeStartDate, locale)}
      </span>
      <button
        className={styles["navigation-button"]}
        onClick={() =>
          dispatch(
            setActiveStartDate(
              new Date(
                activeStartDate.getFullYear(),
                activeStartDate.getMonth(),
                activeStartDate.getDate() + 7
              )
            )
          )
        }
      >
        <img className="app-icon" src={arrowRight} alt="next week" />
      </button>
    </div>
  );
};

export default WeekPicker;
