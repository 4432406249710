import { MouseEvent, TouchEvent, useMemo, useState } from "react";

import { useCalendarContext } from "../utils/hooks/useCalendarContext";
import styles from "../Calendar.module.scss";
import { mergeDragProgress, Period, periodIncludesPeriod } from "./helpers/dates";
import { ContextMenuConfig } from "./ContextMenu";
import useDeviceType from "./hooks/useDeviceType";
import { DragProgress, TileDisabledFunc } from "./types";
import { formatOutputPeriod } from "./helpers/dateFormatters";
import { defaultGridPeriods } from "./constants";
import { convertHourMinutesToDecimal } from "../../../utils/dates";

interface GestureConfig {
  day: string;
  period: Period;
}

type HoursViewGridProps = {
  timeMode?: "12" | "24";
  gridStart?: number;
  activeStartDate: Date;
  disableGestureSelection?: boolean;
  shouldDisableDate?: TileDisabledFunc;
};

const HoursViewGrid = ({
  timeMode = "12",
  gridStart = 0,
  activeStartDate,
  disableGestureSelection = false,
  shouldDisableDate,
}: HoursViewGridProps) => {
  const deviceType = useDeviceType();
  const [dragProgress, setDragProgress] = useState<DragProgress | null>(null);

  const {
    selectedDatesMap,
    handlePeriodToggle,
    setSelectedDatesMap,
    timeClipboardConfig,
    setTimeClipboardConfig,
    showToast,
    setContextMenuConfig,
    setShouldShowCustomRecurrence,
    setCustomRecurrencePeriod,
  } = useCalendarContext();

  const handleContextMenu = ({
    event,
    date,
    hourPeriod,
  }: {
    event: MouseEvent | TouchEvent;
    date: Date;
    hourPeriod: Period;
  }) => {
    event.preventDefault();

    let location = {
      x: (event as MouseEvent).pageX,
      y: (event as MouseEvent).pageY,
    };

    if (deviceType === "mobile") {
      location = {
        x: (event as any).pageX - 10,
        y: (event as any).pageY - 120,
      };
    }

    const newSelectedPeriods = selectedDatesMap.get(date.toDateString()) || [];
    const selectedParentPeriod = newSelectedPeriods.find((period) => {
      return periodIncludesPeriod({ period1: period, period2: hourPeriod, gridStart });
    });

    if (!selectedParentPeriod && !timeClipboardConfig) {
      // Nothing to copy, paste or delete
      return;
    }

    setContextMenuConfig({
      options: [
        selectedParentPeriod
          ? {
              label: "Copy period",
              action: () => {
                setTimeClipboardConfig(selectedParentPeriod);
                const displayedPeriod = formatOutputPeriod(selectedParentPeriod);
                showToast(`Period copied for ${displayedPeriod.start} - ${displayedPeriod.end}`);
              },
            }
          : null,
        timeClipboardConfig
          ? {
              label: "Paste",
              action: () => {
                handlePeriodToggle({
                  date,
                  hourPeriod: timeClipboardConfig,
                  gridStart,
                });
              },
            }
          : null,
        selectedParentPeriod
          ? {
              label: "Make recurring",
              action: () => {
                setShouldShowCustomRecurrence(true);
                setCustomRecurrencePeriod(selectedParentPeriod);
              },
            }
          : null,
        selectedParentPeriod
          ? {
              label: "Delete",
              action: () => {
                const newSelectedDatesMap = new Map(selectedDatesMap);
                newSelectedDatesMap.set(
                  date.toDateString(),
                  newSelectedPeriods.filter((period) => period !== selectedParentPeriod)
                );
                if (!newSelectedDatesMap.get(date.toDateString())?.length) {
                  newSelectedDatesMap.delete(date.toDateString());
                }
                setSelectedDatesMap(newSelectedDatesMap);
              },
              color: "#ff6955",
            }
          : null,
      ].filter(Boolean) as ContextMenuConfig["options"],
      location,
    });
  };

  const gridPeriods = useMemo(() => {
    return [...defaultGridPeriods.slice(gridStart), ...defaultGridPeriods.slice(0, gridStart)];
  }, [gridStart]);

  const handleMouseDragOver = (e: MouseEvent | TouchEvent, gestureConfig?: GestureConfig) => {
    if (disableGestureSelection) {
      return;
    }
    let gestureEndConfig: GestureConfig | null = gestureConfig || null;

    if ((e as TouchEvent).changedTouches) {
      // Handle for mobile devices
      const touch = (e as TouchEvent).changedTouches?.[0];
      const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
      gestureEndConfig = targetElement?.ariaLabel
        ? {
            day: targetElement.ariaLabel.split("-")[0],
            period: {
              start: targetElement.ariaLabel.split("-")[1],
              end: targetElement.ariaLabel.split("-")[2],
            },
          }
        : null;
    }

    if (!dragProgress || !gestureEndConfig || dragProgress.day !== gestureConfig?.day) {
      // Gesture spanned multiple days or the same period, so we bail
      setDragProgress(null);
      return;
    }

    const newDragProgress = mergeDragProgress({ dragProgress, newPeriod: gestureEndConfig.period, gridStart });

    setDragProgress(newDragProgress);
  };

  const handleMouseUp = () => {
    if (!dragProgress) {
      return;
    }

    const sortedPeriods = dragProgress.periods.slice().sort((a, b) => {
      const aDecimal = convertHourMinutesToDecimal(a.start);
      const bDecimal = convertHourMinutesToDecimal(b.start);
      const adjustedADecimal = (aDecimal - gridStart + 24) % 24;
      const adjustedBDecimal = (bDecimal - gridStart + 24) % 24;
      return adjustedADecimal - adjustedBDecimal;
    });

    handlePeriodToggle({
      date: new Date(dragProgress.day),
      hourPeriod: {
        start: sortedPeriods[0].start,
        end: sortedPeriods[sortedPeriods.length - 1].end,
      },
      gridStart,
    });

    setDragProgress(null);
  };

  return (
    <div className={styles["hours-view-grid"]}>
      {gridPeriods.map((periods, index) => {
        return (
          <div key={index}>
            {periods.map((hourPeriod, hourPeriodIndex) => {
              let hourRender = "";
              if (hourPeriodIndex === 0) {
                const date = new Date();
                date.setHours(gridStart + index);
                date.setMinutes(0);
                hourRender = date.toLocaleTimeString("en-US", {
                  hour: timeMode === "12" ? "numeric" : "2-digit",
                  hour12: timeMode === "12",
                });
              }
              return (
                <div className={styles["hours-view-grid-row"]} key={hourPeriodIndex}>
                  <div
                    className={[styles["hours-view-grid-period-label"], hourPeriodIndex === 0 ? styles.full : ""].join(
                      " "
                    )}
                  >
                    {hourRender.toLowerCase().replace(" ", "")}
                    {hourPeriodIndex !== 0 && <div className={styles.dot} />}
                  </div>
                  <div className={styles["hours-view-grid-row-right"]}>
                    <div className={styles["hours-view-grid-period-divider"]} />
                    <div className={styles["hours-view-grid-period-buttons"]}>
                      {Array(7)
                        .fill(null)
                        .map((_, buttonIndex) => {
                          const date = new Date(activeStartDate);
                          date.setDate(date.getDate() + buttonIndex);
                          const dateString = date.toDateString();
                          const selectedPeriods = selectedDatesMap.get(dateString);
                          const isSelected = selectedPeriods?.some((period) => {
                            return periodIncludesPeriod({ period1: period, period2: hourPeriod, gridStart });
                          });

                          const isDragging =
                            dragProgress?.day === dateString &&
                            dragProgress?.periods.some(
                              (period) => period.start === hourPeriod.start && period.end === hourPeriod.end
                            );

                          return (
                            <div
                              className={[
                                styles["hours-view-grid-period-button-wrapper"],
                                isSelected ? styles.selected : "",
                                isDragging ? styles.dragging : "",
                                shouldDisableDate?.({
                                  date,
                                  activeStartDate,
                                })
                                  ? styles.disabled
                                  : "",
                              ].join(" ")}
                              key={buttonIndex}
                              onTouchEnd={handleMouseUp}
                              onMouseUp={handleMouseUp}
                            >
                            <button
                              className={styles["hours-view-grid-period-button"]}
                              disabled={shouldDisableDate?.({
                                date,
                                activeStartDate,
                              })}
                              aria-label={`${dateString}-${hourPeriod.start}-${hourPeriod.end}`}
                              onTouchStart={(e) => {
                                if (e.touches.length === 1) {
                                  setDragProgress({
                                    day: dateString,
                                    span: 0,
                                    periods: [hourPeriod],
                                  });
                                }
                              }}
                              onMouseDown={(e) => {
                                if (e.buttons === 1) {
                                  setDragProgress({
                                    day: dateString,
                                    span: 0,
                                    periods: [hourPeriod],
                                  });
                                }
                              }}
                              onMouseEnter={(e) => {
                                if (e.buttons === 1) {
                                  handleMouseDragOver(e, {
                                    day: dateString,
                                    period: hourPeriod,
                                  });
                                }
                              }}
                              onTouchMove={(e) => {
                                if (e.touches.length === 1) {
                                  handleMouseDragOver(e, {
                                    day: dateString,
                                    period: hourPeriod,
                                  });
                                }
                              }}
                              onTouchEnd={handleMouseUp}
                              onMouseUp={handleMouseUp}
                              onContextMenu={(event) =>
                                handleContextMenu({
                                  event,
                                  date,
                                  hourPeriod,
                                })
                              }
                            ></button>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default HoursViewGrid;
