import { useEffect, useMemo, useState } from "react";
import Modal from "../../components/modal/Modal";
import { PatientSlot } from "../../utils/types";
import {
  getBlankPatientSlot,
  getNumberOrdinal,
  renderFriendlyDuration,
} from "../../utils/formatters";
import styles from "./FillOpenSlots.module.scss";
import {
  addDurationTo24HourTime,
  calculatePeriodDifferenceInHours,
  convert24HourMinutesTo12HourMinutes,
  periodIntersectsWithPeriod,
} from "../../utils/dates";
import {
  appointmentModeOptions,
  getDurationOptions,
  timeOptions,
} from "../../utils/constants";
import Select from "../../components/select/Select";
import Button from "../../components/button/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useToast } from "../../utils/hooks/useToast";

interface SlotAddEditModalProps {
  slotOnEdit: PatientSlot | null;
  onCancel: () => void;
  onSubmit: (formData: PatientSlot) => void;
  onFillRunRemove: () => void;
}

const SlotAddEditModal = ({
  slotOnEdit,
  onCancel,
  onSubmit,
  onFillRunRemove,
}: SlotAddEditModalProps) => {
  const [formData, setFormData] = useState<PatientSlot>(
    getBlankPatientSlot(
      { start: slotOnEdit?.start || "", end: slotOnEdit?.end || "" },
      slotOnEdit?.date || new Date().toDateString()
    )
  );

  const showToast = useToast();

  const { autoFillInProgress, selectedDatesMap, appointmentTypes, isCreatingWaitlistRun } =
    useSelector((state: RootState) => state.slots);

  const { appointmentTypeOptions, durationOptions } = useMemo(() => {
    const appointmentTypesWithAny = [
      {
        id: "*",
        name: "Any appointment",
        duration: Infinity,
      },
      ...appointmentTypes,
    ];

    const longestAppointmentDuration = appointmentTypes.length
      ? Math.max(...appointmentTypes.map((type) => type.duration / 60))
      : 1;
    const durationOptions = getDurationOptions(
      Math.max(
        longestAppointmentDuration,
        slotOnEdit ? calculatePeriodDifferenceInHours(slotOnEdit) : 1
      )
    );

    const appointmentTypeOptions = appointmentTypesWithAny.map((type) => ({
      label:
        type.id === "*" ? type.name : `${type.name} (${type.duration} min)`,
      value: type.id,
    }));
    return {
      appointmentTypeOptions,
      durationOptions,
    };
  }, [appointmentTypes, slotOnEdit]);

  const date = new Date(formData.date);
  const duration =
    formData.end && formData.start
      ? calculatePeriodDifferenceInHours(formData)
      : 0;

  const handleChange = (key: keyof PatientSlot, value: unknown) => {
    const extraProps: Partial<PatientSlot> = {};

    if (key === "start" || key === "end") {
      const newEnd =
        key === "end"
          ? addDurationTo24HourTime(formData.start, value as number)
          : addDurationTo24HourTime(value as string, duration);
      const newStart = key === "start" ? (value as string) : formData.start;
      // If the new period intersects with existing periods, show toast and reject the change
      const newPeriod = {
        start: newStart,
        end: newEnd,
      };
      extraProps.start = newStart;
      extraProps.end = newEnd;

      const daySlots = selectedDatesMap.get(formData.date) || [];
      const intersectingSlot = daySlots.find(
        (slot) =>
          slot.id !== formData.id && periodIntersectsWithPeriod(slot, newPeriod)
      );
      if (intersectingSlot) {
        showToast({
          message: `There's already an appointment scheduled from ${convert24HourMinutesTo12HourMinutes(
            intersectingSlot.start
          )} to ${convert24HourMinutesTo12HourMinutes(intersectingSlot.end)}`,
          type: "error",
        });
        return;
      }
    }

    if (key === "proposedAppointmentType") {
      extraProps.proposedAppointmentType = appointmentTypes.find(
        (type) => type.id === value
      );
      if (value !== "*") {
        extraProps.end = addDurationTo24HourTime(
          formData.start,
          (appointmentTypes.find((type) => type.id === value)?.duration || 0) /
            60
        );
      }
      // If the selected mode is not available for the selected appointment type, select the first available mode
      const contactModeAvailableOnNewAppointmentType =
        extraProps.proposedAppointmentType?.availableContactTypes?.some(
          (contactType) => contactType === formData.proposedAppointmentMode
        );
      extraProps.proposedAppointmentMode =
        contactModeAvailableOnNewAppointmentType
          ? formData.proposedAppointmentMode
          : extraProps.proposedAppointmentType?.availableContactTypes?.[0];
    }

    if (key === "end") {
      extraProps.end = addDurationTo24HourTime(formData.start, value as number);
    }

    setFormData({ ...formData, [key]: value, ...extraProps });
  };

  useEffect(() => {
    if (slotOnEdit) {
      setFormData({
        ...getBlankPatientSlot(slotOnEdit, slotOnEdit.date),
        ...slotOnEdit,
      });
    }
  }, [slotOnEdit]);

  const statusTitleMap = {
    proposed: "Create Fill Run",
    ongoing: "View Fill Run",
    filled: "View Fill Run",
  };

  const formIsValid =
    formData.status === "proposed" &&
    formData.proposedAppointmentType &&
    formData.start &&
    formData.end &&
    formData.proposedAppointmentMode;

  return (
    <Modal visible={Boolean(slotOnEdit)} onClose={onCancel}>
      <div className={styles["slot-add-edit-modal"]}>
        <h2>
          {autoFillInProgress
            ? "Edit Fill Run"
            : statusTitleMap[formData.status]}
        </h2>
        <div className={styles["slot-summary"]}>
          <span className={styles["slot-summary-left"]}>
            <span className={styles["slot-date"]}>
              <span className={styles["slot-date-month"]}>
                {date.toLocaleDateString("en-US", { month: "short" })}
              </span>
              <span className={styles["slot-date-day"]}>
                {getNumberOrdinal(date.getDate())}
              </span>
            </span>
            <span className={styles["slot-summary-mid"]}>
              <span className={styles["slot-summary-types"]}>
                {formData.proposedAppointmentType?.id === "*"
                  ? "Any appointment"
                  : formData.proposedAppointmentType?.name}
              </span>
              <span className={styles["slot-summary-time"]}>
                <span className={styles["slot-time"]}>
                  {convert24HourMinutesTo12HourMinutes(formData.start)} -{" "}
                  {convert24HourMinutesTo12HourMinutes(formData.end)}
                </span>
                <span className={styles.dot} />
                <span className={styles["slot-summary-duration"]}>
                  {renderFriendlyDuration(duration)}
                </span>
              </span>
            </span>
          </span>
          <span
            className={[
              styles["slot-summary-appointment-mode"],
              styles[formData.proposedAppointmentMode || ""],
            ].join(" ")}
          >
            {formData.proposedAppointmentMode}
          </span>
        </div>

        <div className={styles["form-item"]}>
          <span className={styles["form-item-label"]}>Appointment Types</span>
          <Select
            options={appointmentTypeOptions}
            onChange={(value) => handleChange("proposedAppointmentType", value)}
            value={formData.proposedAppointmentType?.id || ""}
            placeholder="Select Appointment Type"
            responsive
            disabled={formData.status !== "proposed"}
            shouldSearchOptions
          />
        </div>
        <div className={styles["form-item-group"]}>
          <div className={styles["form-item"]}>
            <span className={styles["form-item-label"]}>Start Time</span>
            <Select
              options={timeOptions}
              onChange={(value) => handleChange("start", value)}
              value={formData.start}
              placeholder="Start Time"
              responsive
              disabled={formData.status !== "proposed"}
            />
          </div>

          <div className={styles["form-item"]}>
            <span className={styles["form-item-label"]}>Duration</span>
            <Select
              options={durationOptions}
              onChange={(value) => handleChange("end", value)}
              value={duration}
              placeholder="Duration"
              responsive
              disabled={
                formData.status !== "proposed" ||
                formData.proposedAppointmentType?.id !== "*"
              }
            />
          </div>

          <div className={styles["form-item"]}>
            <span className={styles["form-item-label"]}>Appointment Mode</span>
            <Select
              options={
                formData.proposedAppointmentType?.availableContactTypes?.length
                  ? formData.proposedAppointmentType?.availableContactTypes?.map(
                      (contactType) => ({
                        label: contactType,
                        value: contactType,
                      })
                    ) || []
                  : appointmentModeOptions
              }
              onChange={(value) =>
                handleChange("proposedAppointmentMode", value)
              }
              value={formData.proposedAppointmentMode || ""}
              placeholder="Select Mode"
              responsive
              disabled={formData.status !== "proposed"}
            />
          </div>
        </div>

        {formData.status === "proposed" && (
          <>
            <Button
              className={styles["form-submit"]}
              onClick={() => onSubmit(formData)}
              type="primary"
              responsive
              disabled={!formIsValid}
              loading={isCreatingWaitlistRun}
            >
              {autoFillInProgress ? "Update Fill Run" : "Create Fill Run"}
            </Button>
            {autoFillInProgress && (
              <Button
                className={styles["form-submit"]}
                type="accent"
                danger
                responsive
                disabled={!formIsValid}
                onClick={onFillRunRemove}
              >
                Remove Fill Run
              </Button>
            )}
          </>
        )}
      </div>
    </Modal>
  );
};

export default SlotAddEditModal;
